import { TeamsHttp } from '@Services/http/teams.http';
import css from './team-page-stats.component.scss';
import { Subject } from 'rxjs';
export class TeamPageStatsComp extends HTMLElement {
    indicators = new Subject();
    teamsHttp = TeamsHttp.instance;
    subscriptions = [];
    get teamIdParam() {
        return location.pathname.replace('team', '').split('/').filter(path => !!path)[0];
    }
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
        this.reqTeamIndicators();
    }
    disconnectedCallback() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkPaddingContent(), this.mkFooter());
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkPaddingContent() {
        const paddingContent = document.createElement('div');
        paddingContent.classList.add(css.paddingContent);
        paddingContent.append(this.mkLoader());
        const indicatorsSubscription = this.indicators.subscribe(indicators => {
            paddingContent.replaceChildren(this.mkIndicators(indicators), this.mkTeamPlayersStatsSection());
        });
        this.subscriptions.push(indicatorsSubscription);
        return paddingContent;
    }
    mkLoader() {
        const cont = document.createElement('div');
        cont.classList.add(css.loaderCont);
        const loader = document.createElement('app-loader');
        cont.append(loader);
        return cont;
    }
    mkIndicators(indicators) {
        const cont = document.createElement('div');
        cont.classList.add(css.indicators);
        for (const key in indicators) {
            cont.append(this.mkIndicator(key, indicators[key]));
        }
        return cont;
    }
    mkIndicator(name, value) {
        const indicator = document.createElement('app-team-indicator');
        indicator.classList.add(css.indicator);
        indicator.nameSetter = name;
        indicator.valueSetter = value;
        return indicator;
    }
    mkTeamPlayersStatsSection() {
        const section = document.createElement('app-team-players-stats-section');
        section.teamIdSetter = this.teamIdParam;
        return section;
    }
    reqTeamIndicators() {
        this.teamsHttp.getTeamIndicatorsById(this.teamIdParam)
            .then(indicators => this.indicators.next(indicators));
    }
    mkFooter() {
        const footer = document.createElement('app-footer');
        return footer;
    }
}
