import css from './tabs.component.scss';
export class TournamentEditorTabsComp extends HTMLElement {
    activeTab;
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkSettingsTab(), this.mkMatchesTab());
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkSettingsTab() {
        const tab = document.createElement('button');
        tab.classList.add(css.tab, css.activeTab);
        tab.textContent = 'Настройки';
        tab.dataset.value = 'settings';
        tab.addEventListener('click', () => {
            this.updateActiveTab(tab);
            this.dispatchChange();
        });
        this.activeTab = tab;
        return tab;
    }
    mkMatchesTab() {
        const tab = document.createElement('button');
        tab.classList.add(css.tab);
        tab.textContent = 'Матчи';
        tab.dataset.value = 'matches';
        tab.addEventListener('click', () => {
            this.updateActiveTab(tab);
            this.dispatchChange();
        });
        return tab;
    }
    updateActiveTab(tab) {
        for (const child of this.children) {
            child.classList.remove(css.activeTab);
        }
        tab.classList.add(css.activeTab);
        this.activeTab = tab;
    }
    dispatchChange() {
        this.dispatchEvent(new CustomEvent('tabChange', {
            detail: this.activeTab.dataset.value
        }));
    }
}
