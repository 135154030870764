import { Subject } from 'rxjs';
import css from './tournaments-page.component.scss';
import { TournamentsHttp } from '@Services/http/tournaments.http';
import { Layout } from '@Services/layout.service';
export class TournamentsComp extends HTMLElement {
    tournamentsBySeasons = new Subject();
    tournamentsHttp = TournamentsHttp.instance;
    subscriptions = [];
    layout = Layout.instance;
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
        this.reqTournamentsBySeasons();
    }
    disconnectedCallback() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkPaddingContent());
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkPaddingContent() {
        const paddingContent = document.createElement('div');
        paddingContent.classList.add(css.paddingContent);
        paddingContent.append(this.mkSeasons());
        return paddingContent;
    }
    mkSeasons() {
        const cont = document.createElement('div');
        cont.classList.add(css.seasonsCont);
        const tournamentsSubscription = this.tournamentsBySeasons.subscribe(tournamentsBySeasons => {
            const seasons = [];
            tournamentsBySeasons.forEach(tournamentsBySeason => {
                seasons.push(this.mkSeason(tournamentsBySeason));
            });
            cont.replaceChildren(...seasons);
        });
        this.subscriptions.push(tournamentsSubscription);
        cont.append(this.mkLoader());
        return cont;
    }
    mkSeason(seasonTournaments) {
        const season = document.createElement('section');
        season.append(this.mkSeasonHeading(seasonTournaments.seasonName), this.mkTournaments(seasonTournaments.tournaments));
        return season;
    }
    mkTournaments(tournaments) {
        const cont = document.createElement('div');
        cont.classList.add(css.tournamentsCont);
        cont.style.gridTemplateColumns = `repeat(${Math.round(this.layout.currentWidth / 450)}, 1fr)`;
        tournaments.forEach(tournament => {
            cont.append(this.mkTournamentCard(tournament));
        });
        return cont;
    }
    mkSeasonHeading(seasonName) {
        const heading = document.createElement('h3');
        heading.classList.add(css.seasonHeading);
        heading.textContent = seasonName;
        return heading;
    }
    mkTournamentCard(tournament) {
        const tournamentCard = document.createElement('app-tournament-card');
        tournamentCard.classList.add(css.tournamentCard);
        tournamentCard.tournamentSetter = tournament;
        tournamentCard.addEventListener('cardClicked', ((e) => {
            this.openTournamentOverlay(e.detail.activeTab === 'regular' ? tournament.regular : tournament.strongestBattle, tournament.tournamentId);
        }));
        return tournamentCard;
    }
    openTournamentOverlay(tournamentStats, tournamentId) {
        const overlay = this.mkTournamentOverlay(tournamentStats, tournamentId);
        this.append(overlay);
        setTimeout(() => overlay.classList.add('isOpened'), 0);
    }
    mkTournamentOverlay(tournamentStats, tournamentId) {
        const overlay = document.createElement('app-tournament-overlay');
        overlay.tournamentStatsSetter = tournamentStats;
        overlay.tournamentIdSetter = tournamentId;
        return overlay;
    }
    reqTournamentsBySeasons() {
        this.tournamentsHttp.getAllBySeasons()
            .then(tournamentsBySeasons => {
            this.tournamentsBySeasons.next(tournamentsBySeasons);
        })
            .catch();
    }
    mkLoader() {
        const loaderCont = document.createElement('div');
        loaderCont.classList.add(css.loaderCont);
        const loader = document.createElement('app-loader');
        loaderCont.append(loader);
        return loaderCont;
    }
}
