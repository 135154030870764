// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.documents-section-component__documentsList{display:flex;flex-direction:column;gap:1.25rem;list-style-type:none}`, "",{"version":3,"sources":["webpack://./shared/components/documents-section/documents-section.component.scss"],"names":[],"mappings":"AAAA,4CACI,YAAA,CACA,qBAAA,CACA,WAAA,CACA,oBAAA","sourcesContent":[".documentsList {\n    display: flex;\n    flex-direction: column;\n    gap: 1.25rem;\n    list-style-type: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"documentsList": `documents-section-component__documentsList`
};
module.exports = ___CSS_LOADER_EXPORT___;
