import { isPlayoffTournamentStatsGuard, isRoundTournamentStatsGuard } from '@Types/tournament.interface';
import css from './tournament-strongest-battle-settings.component.scss';
import { TOURNAMENT_TYPES, TOURNAMENT_TYPES_LOCALS } from '../../tournament-editor.conf';
export class TournamentStrongestBattleSettingsComp extends HTMLElement {
    tournamentStats;
    typeSettingsRef;
    roundsCountEditorRef;
    set tournamentStatsSetter(tournamentStats) {
        this.tournamentStats = tournamentStats;
    }
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkTypeSettings());
        if (isRoundTournamentStatsGuard(this.tournamentStats)) {
            this.append(this.mkRoundSettings(this.tournamentStats));
        }
        if (isRoundTournamentStatsGuard(this.tournamentStats)) {
            this.append(this.mkRoundSettings(this.tournamentStats));
        }
        else if (isPlayoffTournamentStatsGuard(this.tournamentStats)) {
            this.append(this.mkPlayoffSettings(this.tournamentStats));
        }
        else {
            this.append(this.mkGroupsSettings(this.tournamentStats));
        }
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkRoundSettings(tournamentStats) {
        const settings = document.createElement('app-tournament-round-settings');
        settings.tournamentSettingsSetter = tournamentStats.settings;
        settings.tournamentTypeSetter = TOURNAMENT_TYPES[0];
        return settings;
    }
    mkPlayoffSettings(tournamentStats) {
        const settings = document.createElement('app-tournament-playoff-settings');
        settings.tournamentSettingsSetter = tournamentStats.settings;
        settings.tournamentTypeSetter = TOURNAMENT_TYPES[1];
        return settings;
    }
    mkGroupsSettings(tournamentStats) {
        const cont = document.createElement('div');
        cont.classList.add(css.groupsSettings);
        tournamentStats.groups.forEach(group => {
            cont.append(this.mkGroupSettingsCont(group.name, group.type, group.settings));
        });
        return cont;
    }
    mkGroupSettingsCont(heading, type, settings) {
        const cont = document.createElement('div');
        cont.classList.add(css.groupSettingsCont);
        cont.append(this.mkGroupHeading(`группа: ${heading}`), this.mkGroupSettings(heading, type, settings));
        return cont;
    }
    mkGroupSettings(name, type, groupSettings) {
        const settings = document.createElement('app-tournament-group-settings');
        settings.tournamentSettingsSetter = groupSettings;
        settings.tournamentTypeSetter = type;
        settings.groupNameSetter = name;
        return settings;
    }
    mkGroupHeading(text) {
        const heading = document.createElement('h4');
        heading.classList.add(css.groupHeading);
        heading.textContent = text;
        return heading;
    }
    mkTypeSettings() {
        const cont = document.createElement('div');
        this.typeSettingsRef = cont;
        cont.classList.add(css.typeSettingsCont);
        cont.append(this.mkTypeEditor());
        if (this.tournamentStats.type === TOURNAMENT_TYPES[1])
            cont.append(this.mkRoundsCountEditor());
        return cont;
    }
    mkTypeEditor() {
        const editor = this.mkEditor('*Тип', this.mkTypeSelector());
        return editor;
    }
    // disable selector if a tournament already has a match
    mkTypeSelector() {
        const selector = document.createElement('select');
        selector.classList.add(css.selector);
        selector.required = true;
        selector.name = 'type';
        TOURNAMENT_TYPES.forEach(type => selector.append(this.mkSelectorOption(TOURNAMENT_TYPES_LOCALS[type], type, this.tournamentStats.type === type)));
        selector.addEventListener('change', () => {
            if (selector.value === TOURNAMENT_TYPES[1]) {
                this.typeSettingsRef.append(this.mkRoundsCountEditor());
                return;
            }
            if (this.roundsCountEditorRef)
                this.roundsCountEditorRef.remove();
        });
        return selector;
    }
    mkRoundsCountEditor() {
        const editor = this.mkEditor('*Кол-во туров', this.mkRoundsCountInput());
        this.roundsCountEditorRef = editor;
        return editor;
    }
    mkRoundsCountInput() {
        const input = document.createElement('input');
        input.classList.add(css.input);
        input.type = 'number';
        input.min = '1';
        input.required = true;
        input.placeholder = 'Число от 1';
        input.name = 'roundsCount';
        const settings = this.tournamentStats.settings;
        if (settings.roundsCount)
            input.value = String(settings.roundsCount);
        return input;
    }
    mkEditor(name, formControl) {
        const label = document.createElement('label');
        label.classList.add(css.editor);
        label.textContent = name;
        label.append(formControl);
        return label;
    }
    mkSelectorOption(name, value, selected = false) {
        const option = document.createElement('option');
        option.textContent = name;
        option.value = value;
        if (selected)
            option.selected = true;
        return option;
    }
}
