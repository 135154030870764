import css from './documents-section.component.scss';
import { DefaultSection } from '@Components/section/section.component';
export class DocumentsSectionComp extends DefaultSection {
    documents;
    set documentsSetter(documents) {
        this.documents = documents;
    }
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkTitle(this.documents.title));
        this.append(this.mkHeaderUnderline());
        this.append(this.mkDocumentsList());
    }
    mkDocumentsList() {
        const documentsList = document.createElement('ul');
        documentsList.classList.add(css.documentsList);
        this.documents.list.forEach(doc => documentsList.append(this.mkDocumentsListItem(doc)));
        return documentsList;
    }
    mkDocumentsListItem(doc) {
        const documentListItem = document.createElement('li');
        documentListItem.classList.add(css.documentItem);
        documentListItem.append(this.mkDocument(doc));
        return documentListItem;
    }
    mkDocument(doc) {
        const documentItem = document.createElement('app-document');
        documentItem.documentSetter = doc;
        return documentItem;
    }
}
