import css from './tournament-overlay.component.scss';
import { MatchesHttp } from '@Services/http/matches.http';
import { DayTranslates, MonthTranslates } from '../../shared/enums/date.enum';
import { ReplaySubject } from 'rxjs';
export class TournamentOverlayComp extends HTMLElement {
    tournamentStats;
    tournamentId;
    matchesHttp = MatchesHttp.instance;
    tournamentMatches = new ReplaySubject();
    subscriptions = [];
    headerRef;
    contentRef;
    set tournamentStatsSetter(tournamentStats) {
        this.tournamentStats = tournamentStats;
    }
    set tournamentIdSetter(tournamentId) {
        this.tournamentId = tournamentId;
    }
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
        this.setHostFocus();
        this.escBtnListener();
        this.watchScroll();
        this.reqTournamentMatches();
    }
    disconnectedCallback() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkHeader(), this.mkContent());
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkHeader() {
        const header = document.createElement('header');
        this.headerRef = header;
        header.classList.add(css.header);
        header.append(this.mkTabs(), this.mkCloseBtn());
        return header;
    }
    mkTabs() {
        const tabs = document.createElement('app-tournament-overlay-tabs');
        tabs.addEventListener('tabChange', ((e) => {
            this.updateView(e.detail);
        }));
        return tabs;
    }
    updateView(activeTab) {
        switch (activeTab) {
            case 'stats':
                this.contentRef.replaceChildren(this.mkStats());
                break;
            case 'calendar':
                this.contentRef.replaceChildren(this.mkCalendar());
                break;
        }
    }
    mkContent() {
        const content = document.createElement('div');
        this.contentRef = content;
        content.classList.add(css.content);
        content.append(this.mkStats());
        return content;
    }
    mkStats() {
        const stats = document.createElement('div');
        stats.classList.add(css.stats);
        if (this.tournamentStats.type === 'group') {
            this.tournamentStats.groups.forEach(group => {
                stats.append(group.type === 'round'
                    ? this.mkOverallStanding(group)
                    : this.mkPlayoffSection(group));
            });
        }
        else if (this.tournamentStats.type === 'playoff') {
            stats.append(this.mkPlayoffSection({ ...this.tournamentStats, name: 'Плей-офф' }));
        }
        else {
            stats.append(this.mkOverallStanding({ ...this.tournamentStats, name: 'Общий зачет' }));
        }
        return stats;
    }
    mkCalendar() {
        const cont = document.createElement('div');
        cont.classList.add(css.calendar);
        cont.append(this.mkLoader());
        const frag = document.createDocumentFragment();
        const matchesSubscription = this.tournamentMatches.subscribe(matches => {
            if (!matches.length) {
                frag.append(this.mkEmptyPlaceholder('Нет матчей'));
                return;
            }
            const matchesByDays = this.sortMatchesByDays(matches);
            for (const [stringDate, matchesPerDay] of matchesByDays) {
                const [month, date, day] = stringDate.split('-');
                const sectionTitle = `${DayTranslates[Number(day)]}, ${date} ${MonthTranslates[Number(month)]}`;
                frag.append(this.mkDayMatchesSection(sectionTitle, matchesPerDay));
            }
            setTimeout(() => cont.replaceChildren(frag));
        });
        this.subscriptions.push(matchesSubscription);
        return cont;
    }
    mkLoader() {
        const cont = document.createElement('div');
        cont.classList.add(css.loaderCont);
        const loader = document.createElement('app-loader');
        cont.append(loader);
        return cont;
    }
    mkOverallStanding(group) {
        const overallStanding = document.createElement('app-overall-standing');
        overallStanding.sectionTitleSetter = group.name;
        overallStanding.overallStandingSetter = group.overallStanding;
        return overallStanding;
    }
    mkPlayoffSection(group) {
        const section = document.createElement('app-playoff-section');
        section.sectionTitleSetter = group.name;
        section.stagesSetter = group.stages;
        return section;
    }
    mkCloseBtn() {
        const btn = document.createElement('button');
        btn.classList.add(css.closeBtn);
        btn.append(this.mkCloseIcon());
        btn.addEventListener('click', () => this.closeOverlay());
        return btn;
    }
    mkCloseIcon() {
        const icon = document.createElement('img');
        icon.classList.add(css.closeIcon);
        return icon;
    }
    closeOverlay() {
        this.remove();
    }
    setHostFocus() {
        this.tabIndex = 1;
        this.focus();
    }
    escBtnListener() {
        this.addEventListener('keyup', (e) => {
            if (e.code === 'Escape')
                this.closeOverlay();
        });
    }
    watchScroll() {
        this.addEventListener('scroll', (e) => {
            if (e.target.scrollTop) {
                this.headerRef.classList.add(css.shadow);
                return;
            }
            this.headerRef.classList.remove(css.shadow);
        });
    }
    reqTournamentMatches() {
        this.matchesHttp.getMatchesByTournamentId(this.tournamentId)
            .then(matches => this.tournamentMatches.next(matches))
            .catch();
    }
    sortMatchesByDays(matches) {
        const matchesByDays = new Map();
        matches.forEach(match => {
            const tmpDate = new Date(match.datetime);
            const matchMonth = tmpDate.getMonth();
            const matchDate = tmpDate.getDate();
            const matchDay = tmpDate.getDay();
            const matchKey = `${matchMonth}-${matchDate}-${matchDay}`;
            if (matchesByDays.has(matchKey)) {
                matchesByDays.set(matchKey, [...matchesByDays.get(matchKey), match]);
                return;
            }
            matchesByDays.set(matchKey, [match]);
        });
        return matchesByDays;
    }
    mkDayMatchesSection(title, matches) {
        const section = document.createElement('app-tournament-overlay-day-matches');
        section.sectionTitleSetter = title;
        section.matchesSetter = matches;
        return section;
    }
    mkEmptyPlaceholder(text) {
        const placeholder = document.createElement('div');
        placeholder.classList.add(css.emptyPlaceholder);
        placeholder.textContent = text;
        return placeholder;
    }
}
