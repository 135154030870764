export const getAltFromPath = (imagePath) => {
    return imagePath.split('.')[0];
};
export const getMiniAvatarPath = (avatarPath) => {
    if (!avatarPath)
        return null;
    const pathChunks = avatarPath.split('.');
    const ext = pathChunks.pop();
    return `/players-assets-mini/${pathChunks.join('.')}_32x32.${ext}`;
};
