import css from './avatar-editor.component.scss';
export class AvatarEditor {
    editor;
    previewRef;
    init(params) {
        const cont = document.createElement('div');
        cont.classList.add(css.editor);
        cont.append(this.mkAvatarInput(), this.mkAvatarPreview(params.value), this.mkUploadBtn());
        this.editor = cont;
    }
    getGui() {
        return this.editor;
    }
    getValue() {
        return 'TEST'; // TODO: change to image url from db
    }
    isPopup() {
        return true;
    }
    getPopupPosition() {
        return 'under';
    }
    mkAvatarPreview(avatarPath) {
        const preview = document.createElement('img');
        preview.classList.add(css.preview);
        preview.src = `/players-assets/${avatarPath}`;
        this.previewRef = preview;
        return preview;
    }
    mkAvatarInput() {
        const input = document.createElement('input');
        input.classList.add(css.avatarInput);
        input.type = 'file';
        input.accept = 'image/*';
        input.addEventListener('change', (e) => this.previewRef.src = URL.createObjectURL(e.target.files[0]));
        return input;
    }
    mkUploadBtn() {
        const btn = document.createElement('button');
        btn.classList.add(css.submitBtn);
        btn.textContent = 'Сохранить';
        return btn;
    }
}
