import { MonthAbbrTranslates } from '../enums/date.enum';
export const birthdayDateFromPlayerValueGetter = (params) => {
    if (!params.data?.birthday)
        return null;
    return new Date(params.data.birthday);
};
export const matchDateFromMatchValueGetter = (params) => {
    if (!params.data?.datetime)
        return null;
    return new Date(params.data.datetime);
};
export const playerCustomFieldValueGetter = (params) => {
    if (!params?.data)
        return null;
    const customField = params.data.customFields.find(field => field.name === params.colDef.headerName);
    if (customField.type === 'date') {
        return customField.value ? new Date(customField.value) : customField.value;
    }
    return customField.value;
};
export const arenaNameValueGetter = (params) => {
    if (!params.data)
        return null;
    return `${params.data.arena.title}`;
};
export const tournamentNameValueGetter = (params) => {
    if (!params.data)
        return null;
    return `${params.data.tournament.name}`;
};
export const teamConfrontationValueGetter = (params) => {
    if (!params.data)
        return null;
    return {
        team1: params.data.team1,
        team2: params.data.team2
    };
};
export const playerValueGetter = (params) => {
    if (!params.data)
        return null;
    return params.data.player;
};
export const timeFromISOValueFormatter = (params) => {
    if (!params.value)
        return '';
    const date = new Date(params.value);
    return `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;
};
export const dateValueFormatter = (params) => {
    if (!params.value)
        return '';
    const month = String(params.value.getMonth() + 1);
    const day = String(params.value.getDate());
    return `${day.padStart(2, '0')}.${month.padStart(2, '0')}.${params.value.getFullYear()}`;
};
export const monthDDFromDateValueFormatter = (params) => {
    if (!params.value)
        return '';
    const month = MonthAbbrTranslates[params.value.getMonth()];
    const day = String(params.value.getDate());
    return `${month}, ${day}`;
};
export const monthDDYYYYfromDateValueFormatter = (params) => {
    if (!params.value)
        return '';
    const month = MonthAbbrTranslates[params.value.getMonth()];
    const day = String(params.value.getDate());
    return `${day} ${month}, ${params.value.getFullYear()}`;
};
export const gripValueFormatter = (params) => {
    if (!params.value)
        return '';
    return params.value === 'left' ? 'левый' : 'правый';
};
export const sexValueFormatter = (params) => {
    if (!params.value)
        return '';
    return params.value === 'male' ? 'муж' : 'жен';
};
export const positionValueFormatter = (params) => {
    if (!params.value)
        return '';
    return params.value === 'defender'
        ? 'защитник'
        : params.value === 'goalkeeper'
            ? 'вратарь'
            : 'нападающий';
};
export const isoFromDatePlayerValueSetter = (params) => {
    if (!params.newValue) {
        params.data.birthday = null;
        return true;
    }
    const newDate = new Date(params.newValue);
    newDate.setUTCDate(params.newValue.getDate());
    newDate.setUTCHours(0, 0, 0, 0);
    params.data.birthday = newDate.toISOString();
    return true;
};
export const customDateFieldValueSetter = (params) => {
    const editableField = params.data.customFields
        .find(field => field.name === params.colDef.headerName);
    if (!params.newValue) {
        editableField.value = null;
        return true;
    }
    const newDate = new Date(params.newValue);
    newDate.setUTCDate(params.newValue.getDate());
    newDate.setUTCHours(0, 0, 0, 0);
    editableField.value = newDate.toISOString();
    return true;
};
export const customTextFieldValueSetter = (params) => {
    const editableField = params.data.customFields
        .find(field => field.name === params.colDef.headerName);
    editableField.value = params.newValue || null;
    return true;
};
export const customNumFieldValueSetter = (params) => {
    const editableField = params.data.customFields
        .find(field => field.name === params.colDef.headerName);
    editableField.value = params.newValue || null;
    return true;
};
export const dateEqualityCompator = (valueA, valueB) => {
    if (!valueA && valueB)
        return false;
    if (valueA && !valueB)
        return false;
    if (!valueA && !valueB)
        return true;
    return valueA.getTime() === valueB.getTime();
};
export const filterDateComparator = (specifiedDate, cellValue) => {
    const specifiedDateYear = specifiedDate.getFullYear();
    const cellValueYear = cellValue.getFullYear();
    if (specifiedDateYear > cellValueYear) {
        return -1;
    }
    else if (specifiedDateYear < cellValueYear) {
        return 1;
    }
    else {
        const specifiedDateMonth = specifiedDate.getMonth();
        const cellValueMonth = cellValue.getMonth();
        if (specifiedDateMonth > cellValueMonth) {
            return -1;
        }
        else if (specifiedDateMonth < cellValueMonth) {
            return 1;
        }
        else {
            const specifiedDateDate = specifiedDate.getDate();
            const cellValueDate = cellValue.getDate();
            if (specifiedDateDate > cellValueDate) {
                return -1;
            }
            else if (specifiedDateDate < cellValueDate) {
                return 1;
            }
            else {
                return 0;
            }
        }
    }
};
