import { CUSTOM_ELEMENTS_CONF } from './about-page.conf';
const defineCustomElements = () => {
    CUSTOM_ELEMENTS_CONF.forEach(el => {
        try {
            el?.options
                ? customElements.define(el.tag, el.comp, el.options)
                : customElements.define(el.tag, el.comp);
        }
        catch {
            return;
        }
    });
};
export default () => {
    defineCustomElements();
};
