import { MatchesHttp } from '@Services/http/matches.http';
import css from './match-protocol.component.scss';
import { paths } from '../match-page-nav.conf';
import { Subject } from 'rxjs';
import { removeChildren } from '@Helpers/dom-manipulation.helper';
export class MatchProtocolComp extends HTMLElement {
    matchesHttp = MatchesHttp.instance;
    protocol = new Subject;
    subscriptions = [];
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
        this.reqMatch();
    }
    disconnectedCallback() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkPaddingContent());
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkPaddingContent() {
        const paddingContent = document.createElement('div');
        paddingContent.classList.add(css.paddingContent);
        const protocolSubscription = this.protocol.subscribe(protocol => {
            removeChildren(paddingContent);
            if (!protocol) {
                paddingContent.append(this.mkEmptyPlaceholder());
                return;
            }
            paddingContent.append(this.mkFirstTeamStatsSection(protocol.team1), this.mkSecondTeamStatsSection(protocol.team2));
        });
        this.subscriptions.push(protocolSubscription);
        paddingContent.append(this.mkLoader());
        return paddingContent;
    }
    mkFirstTeamStatsSection(team) {
        const section = document.createElement('app-match-team-stats-section');
        section.matchTeamSetter = team;
        return section;
    }
    mkSecondTeamStatsSection(team) {
        const section = document.createElement('app-match-team-stats-section');
        section.matchTeamSetter = team;
        return section;
    }
    reqMatch() {
        this.matchesHttp.getMatchProtocolById(paths()[1])
            .then(protocol => this.protocol.next(protocol))
            .catch(() => this.protocol.next(null));
    }
    mkEmptyPlaceholder() {
        const placeholder = document.createElement('p');
        placeholder.classList.add(css.emptyPlaceholder);
        placeholder.textContent = 'Пусто';
        return placeholder;
    }
    mkLoader() {
        const loaderCont = document.createElement('div');
        loaderCont.classList.add(css.loaderCont);
        const loader = document.createElement('app-loader');
        loaderCont.append(loader);
        return loaderCont;
    }
}
