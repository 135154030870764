import css from './photo-previewer-component.scss';
export class PhotoPreviewerComp extends HTMLElement {
    photos = [];
    initialPhoto;
    currentPhotoIndex;
    imgRef;
    set photoSetter(data) {
        this.photos = data.photos;
        this.currentPhotoIndex = data.selectedIndex;
        this.initialPhoto = this.photos[this.currentPhotoIndex];
    }
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
        this.disableScroll();
        this.focusHost();
        this.addEventListener('keydown', (e) => this.handleKeyDown(e));
        this.addEventListener('click', (event) => {
            if (event.target === this) {
                this.remove();
            }
        });
    }
    disconnectedCallback() {
        this.enableScroll();
    }
    focusHost() {
        this.tabIndex = -1;
        this.focus();
    }
    disableScroll() {
        document.body.style.overflow = 'hidden';
    }
    enableScroll() {
        document.body.style.overflow = '';
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkPreviousButton(), this.mkImageElement(), this.mkNextButton(), this.mkCloseButton());
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkImageElement() {
        const img = document.createElement('img');
        img.src = this.initialPhoto;
        this.imgRef = img;
        img.classList.add(css.photo);
        return img;
    }
    mkCloseButton() {
        const button = document.createElement('button');
        button.classList.add(css.closeButton);
        button.append(this.mkCloseButtonMask());
        button.addEventListener('click', () => this.remove());
        return button;
    }
    mkCloseButtonMask() {
        const closeIconMask = document.createElement('div');
        closeIconMask.classList.add(css.closeButtonMask);
        return closeIconMask;
    }
    mkArrowLeftIcon() {
        const arrow = document.createElement('span');
        arrow.classList.add(css.arrow, css.arrowLeft);
        return arrow;
    }
    mkArrowRightIcon() {
        const arrow = document.createElement('span');
        arrow.classList.add(css.arrow, css.arrowRight);
        return arrow;
    }
    mkPreviousButton() {
        const button = document.createElement('button');
        button.classList.add(css.toggleButtons, css.previousButton);
        button.append(this.mkArrowLeftIcon());
        button.addEventListener('click', () => {
            this.changePhoto('previous');
        });
        return button;
    }
    mkNextButton() {
        const button = document.createElement('button');
        button.classList.add(css.toggleButtons, css.nextButton);
        button.append(this.mkArrowRightIcon());
        button.addEventListener('click', () => {
            this.changePhoto('next');
        });
        return button;
    }
    updatePhoto() {
        if (this.imgRef && this.photos.length > 0) {
            this.imgRef.src = this.photos[this.currentPhotoIndex];
        }
    }
    handleKeyDown(event) {
        if (event.key === 'Escape') {
            this.remove();
        }
        else if (event.key === 'ArrowRight') {
            this.changePhoto('next');
        }
        else if (event.key === 'ArrowLeft') {
            this.changePhoto('previous');
        }
    }
    changePhoto(direction) {
        if (direction === 'next' && this.currentPhotoIndex < this.photos.length - 1) {
            this.currentPhotoIndex++;
            this.updatePhoto();
            return;
        }
        if (direction === 'previous' && this.currentPhotoIndex > 0) {
            this.currentPhotoIndex--;
            this.updatePhoto();
            return;
        }
    }
}
