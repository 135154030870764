import css from './confrontation.component.scss';
import { convertElementToRouterLink } from '@Helpers/router.helper';
export class ConfrontationComp extends HTMLElement {
    confrontation;
    set confrontationSetter(confrontation) {
        this.confrontation = confrontation;
    }
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
    }
    render() {
        this.assignHostStyles();
        if (this.confrontation.team1)
            this.append(this.mkParticipant(this.confrontation.team1, this.confrontation.matches?.length
                ? this.confrontation.matches?.filter(match => match.result[0] > match.result[1]).length
                : null));
        if (this.confrontation.team2)
            this.append(this.mkParticipant(this.confrontation.team2, this.confrontation.matches?.length
                ? this.confrontation.matches?.filter(match => match.result[1] > match.result[0]).length
                : null));
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkParticipant(team, points) {
        const participant = document.createElement('div');
        participant.classList.add(css.participant);
        participant.append(this.mkTeam(team), this.mkPoints(points));
        return participant;
    }
    mkTeam(team) {
        const teamCont = document.createElement('span');
        teamCont.classList.add(css.team);
        if (team.logo)
            teamCont.append(this.mkTeamLogo(team.logo));
        teamCont.append(this.mkTeamLink(team.name, team.teamId));
        return teamCont;
    }
    mkTeamLogo(logoPath) {
        const logo = document.createElement('img');
        logo.classList.add(css.logo);
        logo.src = `/teams-assets/${logoPath}`;
        return logo;
    }
    mkTeamLink(teamName, teamId) {
        const link = document.createElement('a');
        link.classList.add(css.link);
        link.textContent = teamName;
        link.href = `/team/${teamId}/stats`;
        convertElementToRouterLink(link);
        return link;
    }
    mkPoints(value) {
        const points = document.createElement('p');
        points.classList.add(css.points);
        points.textContent = Number.isInteger(value) ? String(value) : '—';
        return points;
    }
}
