import css from './player.component.scss';
import defaultAvatar from '@Assets/icons/player-default-avatar.svg';
import { getMiniAvatarPath } from '@Helpers/images.helper';
import { convertElementToRouterLink } from '@Helpers/router.helper';
export class PlayerCellRendererComp {
    gui;
    constructor() { }
    init(params) {
        if (!params.value)
            return;
        const cont = this.mkCont();
        cont.append(this.mkAvatar(params.value.avatar), this.mkName(params.value));
        this.gui = cont;
    }
    getGui() {
        return this.gui;
    }
    refresh(_) {
        return false;
    }
    mkCont() {
        const cont = document.createElement('span');
        cont.classList.add(css.playerCont);
        return cont;
    }
    mkAvatar(avatarPath) {
        const avatar = document.createElement('img');
        avatar.classList.add(css.avatar);
        avatar.src = getMiniAvatarPath(avatarPath) || defaultAvatar;
        avatar.loading = 'lazy';
        avatar.addEventListener('error', () => avatar.src = defaultAvatar);
        return avatar;
    }
    mkName(player) {
        const name = document.createElement('a');
        name.classList.add(css.name);
        name.textContent = `${player.name} ${player.surname}`;
        name.href = `/player/${player.playerId}/stats`;
        convertElementToRouterLink(name);
        return name;
    }
}
