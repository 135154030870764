import css from './admin-documents.component.scss';
import { DocumentsHttp } from '@Services/http/documents.http';
export class AdminDocumentsComp extends HTMLElement {
    documentsHttp = DocumentsHttp.instance;
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
        this.reqDocuments().then(docsSections => {
            if (!docsSections)
                return;
            const sections = [];
            docsSections.forEach(documentsSection => sections.push(this.mkDocumentsSection(documentsSection)));
            this.append(...sections, this.mkNewDocumentsSectionButton());
        });
    }
    render() {
        this.assignHostStyles();
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    reqDocuments() {
        return this.documentsHttp.getDocumentsSections();
    }
    mkDocumentsSection(documentsSection) {
        const section = document.createElement('app-admin-documents-section');
        section.documentsSectionSetter = documentsSection;
        return section;
    }
    mkNewDocumentsSectionButton() {
        const button = document.createElement('button');
        button.classList.add(css.newDocumentsSectionButton);
        button.textContent = 'Добавить секцию ';
        button.append(this.mkNewDocumentsSectionAddIcon());
        button.addEventListener('click', () => {
            const newDocumentsSection = {
                title: '',
                list: []
            };
            button.before(this.mkDocumentsSection(newDocumentsSection));
        });
        return button;
    }
    mkNewDocumentsSectionAddIcon() {
        const icon = document.createElement('img');
        icon.classList.add(css.addIcon);
        return icon;
    }
}
