import css from './players-stats-filters.component.scss';
import { getAllSeasons } from '@Helpers/season.helper';
export class PlayersStatsFiltersComp extends HTMLElement {
    subscriptions = [];
    filtersState = {
        season: {
            startDate: '',
            endDate: ''
        }
    };
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
    }
    disconnectedCallback() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkSeasonFilter());
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkSeasonFilter() {
        const filter = document.createElement('select');
        filter.classList.add(css.seasonFilter);
        filter.addEventListener('change', () => {
            const newValue = JSON.parse(filter.value);
            this.filtersState.season.startDate = newValue.startDate;
            this.filtersState.season.endDate = newValue.endDate;
            this.dispatchEvent(new CustomEvent('filtersChange', {
                detail: this.filtersState
            }));
        });
        getAllSeasons().forEach((seasonRangeDates) => {
            const seasonStartYear = seasonRangeDates[0].getFullYear();
            const seasonRangeValue = {
                startDate: seasonRangeDates[0].toISOString(),
                endDate: seasonRangeDates[1].toISOString()
            };
            filter.append(this.mkSeasonFilterOptions(`Сезон ${seasonStartYear}/${seasonStartYear + 1}`, JSON.stringify(seasonRangeValue)));
        });
        return filter;
    }
    mkSeasonFilterOptions(text, value) {
        const option = document.createElement('option');
        option.textContent = text;
        option.value = value;
        return option;
    }
}
