import css from './subnav.component.scss';
export class SubnavComp extends HTMLElement {
    subnav;
    set subnavSetter(subnav) {
        this.subnav = subnav;
    }
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkPageName(), this.mkDelimeter(), this.mkLinks());
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkPageName() {
        const heading = document.createElement('h2');
        heading.classList.add(css.heading);
        heading.textContent = this.subnav.heading;
        return heading;
    }
    mkDelimeter() {
        const delimeter = document.createElement('div');
        delimeter.classList.add(css.delimeter);
        return delimeter;
    }
    mkLinks() {
        const links = document.createElement('ul');
        links.classList.add(css.linksList);
        this.subnav.links.forEach(link => {
            const listItem = this.mkLinksItem();
            listItem.append(this.mkLink(link.name, link.elRef));
            links.append(listItem);
        });
        return links;
    }
    mkLinksItem() {
        const listItem = document.createElement('li');
        return listItem;
    }
    mkLink(text, elRef) {
        const link = document.createElement('a');
        link.classList.add(css.link);
        link.textContent = text;
        link.addEventListener('click', (e) => {
            e.preventDefault();
            e.stopImmediatePropagation();
            elRef.scrollIntoView({
                behavior: 'smooth'
            });
        });
        return link;
    }
}
