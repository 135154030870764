import css from './root-nav.component.scss';
import { NAV_LINKS } from './root-nav.conf';
import { convertElementToRouterLink } from '@Helpers/router.helper';
export class RootNavComp extends HTMLElement {
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkNav());
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkNav() {
        const nav = document.createElement('nav');
        nav.classList.add(css.nav);
        nav.append(this.mkNavList(), this.mkLoginLink());
        return nav;
    }
    mkNavList() {
        const navList = document.createElement('ul');
        navList.classList.add(css.navList);
        navList.addEventListener('click', () => this.dispatchEvent(new CustomEvent('navLinkClicked')));
        NAV_LINKS.forEach(link => navList.append(this.mkNavListItem(link)));
        return navList;
    }
    mkNavListItem(link) {
        const navListItem = document.createElement('li');
        navListItem.classList.add(css.navListItem);
        navListItem.append(this.mkNavLink(link));
        return navListItem;
    }
    mkNavLink(link) {
        const navLink = document.createElement('a');
        navLink.classList.add(css.navLink);
        navLink.textContent = link.name;
        navLink.href = link.path;
        convertElementToRouterLink(navLink);
        return navLink;
    }
    mkLoginLink() {
        const loginLink = document.createElement('app-login-link');
        return loginLink;
    }
}
