import { Subject } from 'rxjs';
import css from './admin-news.component.scss';
import { NewsHttp } from '@Services/http/news.http';
export class AdminNewsComp extends HTMLElement {
    isEditorOpened = false;
    newsHttp = NewsHttp.instance;
    news = new Subject();
    subscriptions = [];
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
        this.reqNews('all');
    }
    disconnectedCallback() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkHeader(), this.mkNewsSection());
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkCategorySelector() {
        const selector = document.createElement('select');
        selector.classList.add(css.selector);
        selector.addEventListener('change', () => {
            this.startNewsListLoading();
            this.reqNews(selector.value);
        });
        selector.append(this.mkSelectorOption('all', 'все'), this.mkSelectorOption('players', 'игроки'), this.mkSelectorOption('tournaments', 'турниры'), this.mkSelectorOption('judging', 'судейство'), this.mkSelectorOption('others', 'другое'));
        return selector;
    }
    mkHeader() {
        const header = document.createElement('div');
        header.classList.add(css.header);
        header.append(this.mkCategorySelector(), this.mkAddNewsButton());
        return header;
    }
    mkSelectorOption(value, text) {
        const option = document.createElement('option');
        option.value = value;
        option.textContent = text;
        return option;
    }
    mkAddNewsButton() {
        const button = document.createElement('button');
        button.classList.add(css.addNewsBtn);
        button.addEventListener('click', () => this.openNewsEditor());
        button.append(this.mkAddIcon());
        return button;
    }
    mkAddIcon() {
        const icon = document.createElement('img');
        icon.classList.add(css.addIcon);
        return icon;
    }
    openNewsEditor() {
        if (this.isEditorOpened)
            return;
        this.isEditorOpened = true;
        const newsEditor = this.mkNewsEditorOverlay();
        newsEditor.addEventListener('closed', () => this.isEditorOpened = false);
        this.append(newsEditor);
        setTimeout(() => newsEditor.classList.add('isOpened'), 0);
    }
    mkNewsEditorOverlay() {
        const overlay = document.createElement('app-news-editor');
        return overlay;
    }
    reqNews(category) {
        this.newsHttp.getNewsByCategory(category)
            .then(news => this.news.next(news))
            .catch();
    }
    startNewsListLoading() {
        this.news.next(null);
    }
    mkNewsSection() {
        const section = document.createElement('app-news-section');
        const newsSubscription = this.news.subscribe(news => section.newsSetter = news);
        this.subscriptions.push(newsSubscription);
        return section;
    }
}
