import { isNotNewDocumentsSectionGuard } from '@Types/document.interface';
import css from './admin-documents-section.component.scss';
import addIcon from '@Assets/icons/add-icon.svg';
import { Subject } from 'rxjs';
import { DocumentsHttp } from '@Services/http/documents.http';
export class AdminDocumentsSectionComp extends HTMLElement {
    isOpened = false;
    isNew;
    documentsListRef;
    titleRef;
    saveButtonRef;
    cancelButtonRef;
    documentsSection;
    modifiedDocumentsSection;
    documentsSectionChange = new Subject();
    subscriptions = [];
    documentsHttp = DocumentsHttp.instance;
    set documentsSectionSetter(documentsSection) {
        this.isNew = !isNotNewDocumentsSectionGuard(documentsSection);
        this.documentsSection = documentsSection;
        this.modifiedDocumentsSection = structuredClone(documentsSection);
    }
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
    }
    disconnectedCallback() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkForm());
        this.documentsSectionChange.subscribe(() => {
            this.updateButtonsStates();
        });
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    get isDocumentsListEmpty() {
        return !this.documentsListRef.hasChildNodes();
    }
    get isValid() {
        if (!this.isDocumentsListEmpty && this.titleRef.validity.valid)
            return true;
        return false;
    }
    get hasChanges() {
        return this.compareStatesEquality(this.modifiedDocumentsSection, this.documentsSection);
    }
    compareStatesEquality(modified, original) {
        if (original.title !== modified.title)
            return true;
        if (original.list.length !== modified.list.length)
            return true;
        for (let i = 0; i < original.list.length; i++) {
            const originalDocument = original.list[i];
            const modifiedDocument = modified.list[i];
            if (originalDocument.name !== modifiedDocument.name ||
                originalDocument.type !== modifiedDocument.type ||
                originalDocument.file !== modifiedDocument.file) {
                return true;
            }
        }
        return false;
    }
    openCreateDocumentOverlay() {
        if (this.isOpened)
            return;
        const documentOverlay = this.mkCreateDocumentOverlay();
        this.append(documentOverlay);
        this.isOpened = true;
        setTimeout(() => documentOverlay.classList.toggle('isOpened'), 0);
    }
    mkCreateDocumentOverlay() {
        const createDocumentOverlay = document.createElement('app-create-document');
        createDocumentOverlay.classList.add(css.overlay);
        createDocumentOverlay.addEventListener('closed', () => this.isOpened = false);
        createDocumentOverlay.addEventListener('add', ((e) => {
            this.addNewDocument(e.detail);
            if (this.isOpened) {
                createDocumentOverlay.remove();
                this.isOpened = false;
            }
        }));
        return createDocumentOverlay;
    }
    mkForm() {
        const form = document.createElement('form');
        form.classList.add(css.form);
        form.addEventListener('reset', (e) => {
            e.preventDefault();
            this.reset();
            this.updateButtonsStates();
        });
        form.addEventListener('change', () => {
            this.updateButtonsStates();
        });
        form.addEventListener('submit', (e) => {
            e.preventDefault();
        });
        form.append(this.mkTitleLabel(), this.mkNewDocumentButtonLabel(), this.mkDocumentsList(), this.mkFormButtons(), this.mkDelSectionButton());
        return form;
    }
    updateButtonsStates() {
        if (!this.isNew && this.hasChanges) {
            this.cancelButtonRef.classList.remove(css.displayNone);
        }
        else {
            this.cancelButtonRef.classList.add(css.displayNone);
        }
        if (this.hasChanges && this.isValid) {
            this.saveButtonRef.disabled = false;
        }
        else {
            this.saveButtonRef.disabled = true;
        }
    }
    mkTitleLabel() {
        const titleLabel = document.createElement('label');
        titleLabel.classList.add(css.titleLabel);
        titleLabel.textContent = 'Название секции';
        titleLabel.append(this.mkTitle());
        return titleLabel;
    }
    mkTitle() {
        const title = document.createElement('input');
        this.titleRef = title;
        title.classList.add(css.title);
        title.placeholder = 'Введите название секции';
        title.value = this.documentsSection.title;
        title.required = true;
        title.addEventListener('input', () => {
            this.modifiedDocumentsSection.title = title.value;
            this.documentsSectionChange.next();
        });
        return title;
    }
    mkNewDocumentButtonLabel() {
        const newDocumentButtonLabel = document.createElement('label');
        newDocumentButtonLabel.classList.add(css.newDocumentButtonLabel);
        newDocumentButtonLabel.textContent = 'Список документов';
        newDocumentButtonLabel.append(this.mkNewDocumentButton());
        return newDocumentButtonLabel;
    }
    mkNewDocumentButtonAddIcon() {
        const icon = document.createElement('img');
        icon.classList.add(css.addIcon);
        icon.src = addIcon;
        return icon;
    }
    mkNewDocumentButton() {
        const btn = document.createElement('button');
        btn.classList.add(css.newDocumentButton);
        btn.append(this.mkNewDocumentButtonAddIcon());
        btn.addEventListener('click', () => this.openCreateDocumentOverlay());
        return btn;
    }
    mkDelDocButton(doc, docName) {
        const btn = document.createElement('button');
        btn.classList.add(css.delButton);
        btn.addEventListener('click', () => {
            const confirmed = confirm('Удалить документ?');
            if (confirmed) {
                doc.remove();
                this.modifiedDocumentsSection.list = this.modifiedDocumentsSection.list.filter(el => el.name !== docName);
                this.documentsSectionChange.next();
            }
        });
        return btn;
    }
    mkDocumentsList() {
        const documentsList = document.createElement('ul');
        this.documentsListRef = documentsList;
        documentsList.classList.add(css.documentsList);
        this.documentsSection.list.forEach(doc => {
            documentsList.append(this.mkDocumentsListItem(doc));
        });
        return documentsList;
    }
    mkDocumentsListItem(doc) {
        const documentsListItem = document.createElement('li');
        documentsListItem.classList.add(css.documentsListItem);
        documentsListItem.append(this.mkDocument(doc), this.mkDelDocButton(documentsListItem, doc.name));
        return documentsListItem;
    }
    mkDocument(doc) {
        const documentItem = document.createElement('app-document');
        documentItem.documentSetter = doc;
        return documentItem;
    }
    mkFormButtons() {
        const buttons = document.createElement('div');
        buttons.classList.add(css.buttons);
        buttons.append(this.mkCancelButton(), this.mkSaveButton());
        return buttons;
    }
    mkSaveButton() {
        const saveButton = document.createElement('button');
        this.saveButtonRef = saveButton;
        saveButton.type = 'submit';
        saveButton.classList.add(css.saveButton);
        saveButton.textContent = 'Сохранить';
        saveButton.disabled = true;
        saveButton.addEventListener('click', () => {
            if (isNotNewDocumentsSectionGuard(this.modifiedDocumentsSection)) {
                this.updateSection();
            }
            else {
                this.postNewSection();
            }
        });
        return saveButton;
    }
    mkCancelButton() {
        const chanelButton = document.createElement('button');
        this.cancelButtonRef = chanelButton;
        chanelButton.type = 'reset';
        chanelButton.classList.add(css.cancelButton, css.displayNone);
        chanelButton.textContent = 'Отменить';
        return chanelButton;
    }
    mkDelSectionButton() {
        const button = document.createElement('button');
        button.classList.add(css.delButton, css.delSectionBtn);
        button.addEventListener('click', () => {
            const confirmed = confirm('Удалить секцию?');
            if (confirmed) {
                if (isNotNewDocumentsSectionGuard(this.documentsSection)) {
                    this.deleteSection();
                }
                else {
                    this.remove();
                }
            }
        });
        return button;
    }
    addNewDocument(doc) {
        this.documentsListRef.append(this.mkDocumentsListItem(doc));
        this.modifiedDocumentsSection.list.push(doc);
        this.documentsSectionChange.next();
    }
    reset() {
        this.titleRef.value = this.documentsSection.title;
        this.documentsListRef.replaceChildren(this.mkDocumentsList());
        this.modifiedDocumentsSection = structuredClone(this.documentsSection);
        this.documentsSectionChange.next();
    }
    postNewSection() {
        this.documentsHttp.createDocumentsSection(this.modifiedDocumentsSection)
            .then((newDocumentsSectionId) => {
            this.isNew = false;
            this.documentsSection = {
                _id: newDocumentsSectionId,
                ...this.modifiedDocumentsSection
            };
            this.modifiedDocumentsSection = structuredClone(this.documentsSection);
            this.documentsSectionChange.next();
        })
            .catch();
    }
    updateSection() {
        this.documentsHttp.updateDocumentsSection(this.modifiedDocumentsSection)
            .then(() => {
            this.isNew = false;
            this.documentsSection = this.modifiedDocumentsSection;
            this.modifiedDocumentsSection = structuredClone(this.modifiedDocumentsSection);
            this.documentsSectionChange.next();
        })
            .catch();
    }
    deleteSection() {
        this.documentsHttp.deleteDocumentsSectionById(this.documentsSection._id)
            .then(() => this.remove())
            .catch((err) => {
            alert(`Ошибка удаления секции документов, сделайте скриншот данного окна и отправьте его администратору сайта. Ошибка- ${err.message}`);
        });
    }
}
