import { ORIGIN, PROTOCOL } from '@Constants/http.constants';
import css from './team-page.component.scss';
import { Router, TEAM_ROUTES } from './team-page.router';
import { TeamsHttp } from '@Services/http/teams.http';
import { TEAM_NAV_LINKS } from './team-page-nav.conf';
import { convertElementToRouterLink } from '@Helpers/router.helper';
export class TeamPageComp extends HTMLElement {
    teamsHttp = TeamsHttp.instance;
    team;
    routerOutletRef;
    get teamIdParam() {
        return location.pathname.replace('team', '').split('/').filter(path => !!path)[0];
    }
    constructor() {
        super();
    }
    connectedCallback() {
        this.reqTeamInfo()
            .then(team => {
            this.team = team;
            this.render();
            this.initRouting();
        })
            .catch();
    }
    render() {
        this.assignHostStyles();
        if (!this.team) {
            this.append(this.mkEmptyPlaceholder());
            return;
        }
        this.append(this.mkHeader(), this.mkNav(), this.mkRouterOutlet());
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkHeader() {
        const header = document.createElement('app-team-page-header');
        header.teamSetter = this.team;
        return header;
    }
    mkEmptyPlaceholder() {
        const placeholder = document.createElement('div');
        placeholder.classList.add(css.emptyPlaceholder);
        placeholder.textContent = `Команда с ID ${this.teamIdParam} отсутствует`;
        return placeholder;
    }
    mkRouterOutlet() {
        const routerOutlet = document.createElement('div');
        this.routerOutletRef = routerOutlet;
        return routerOutlet;
    }
    initRouting() {
        const teamRouter = new Router(this.routerOutletRef, TEAM_ROUTES);
        this.addEventListener('click', (e) => {
            const target = e.target;
            if (target.matches('[data-router-link]')) {
                e.preventDefault();
                const href = target.href.replace(PROTOCOL, '').replace(ORIGIN, '');
                if (teamRouter.hasRoute(href)) {
                    e.stopPropagation();
                    history.pushState(null, '', href);
                    teamRouter.generateOutlet();
                }
            }
        });
        teamRouter.generateOutlet();
    }
    reqTeamInfo() {
        return this.teamsHttp.getTeamById(this.teamIdParam);
    }
    mkNav() {
        const nav = document.createElement('ul');
        nav.classList.add(css.nav);
        TEAM_NAV_LINKS().forEach(navLink => nav.append(this.mkNavItem(navLink)));
        return nav;
    }
    mkNavItem(navLink) {
        const listItem = document.createElement('li');
        listItem.append(this.mkNavLink(navLink));
        return listItem;
    }
    mkNavLink(navLink) {
        const anchor = document.createElement('a');
        anchor.classList.add(css.navLink);
        anchor.textContent = navLink.name;
        anchor.href = navLink.path;
        convertElementToRouterLink(anchor);
        return anchor;
    }
}
