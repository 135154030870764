import { TeamPageComp } from './team-page.component';
import { TeamPageHeaderComp } from './team-page-header/team-page-header.component';
import { TeamPageStatsComp } from './team-page-stats/team-page-stats.component';
import { TeamIndicatorComp } from './team-page-stats/team-indicator/team-indicator.component';
import { LoaderComp } from '@Components/loader/loader.component';
import { TeamPlayersStatsSectionComp } from './team-page-stats/team-players-stats-section/team-players-stats-section';
import { TeamPageLineupComp } from './team-page-lineup/team-page-lineup.component';
import { PlayerCardComp } from '@Components/player-card/player-card.component';
import { FooterComp } from '@Components/footer/footer.component';
export const CUSTOM_ELEMENTS_CONF = [
    { tag: 'app-team-page', comp: TeamPageComp },
    { tag: 'app-team-page-header', comp: TeamPageHeaderComp },
    { tag: 'app-team-page-stats', comp: TeamPageStatsComp },
    { tag: 'app-team-indicator', comp: TeamIndicatorComp },
    { tag: 'app-loader', comp: LoaderComp },
    { tag: 'app-team-players-stats-section', comp: TeamPlayersStatsSectionComp },
    { tag: 'app-team-lineup', comp: TeamPageLineupComp },
    { tag: 'app-player-card', comp: PlayerCardComp },
    { tag: 'app-footer', comp: FooterComp }
];
