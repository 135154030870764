import css from './player-card.component.scss';
import { getDDMMYYYY } from '@Helpers/date.helper';
import defaultAvatar from '@Assets/icons/player-default-avatar.svg';
import { convertElementToRouterLink } from '@Helpers/router.helper';
export class PlayerCardComp extends HTMLElement {
    player;
    set playerSetter(player) {
        this.player = player;
    }
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkLinkCont());
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkLinkCont() {
        const cont = document.createElement('a');
        cont.classList.add(css.linkCont);
        cont.href = `/player/${this.player.playerId}/stats`;
        cont.append(this.mkAvatar(), this.mkName(), this.mkInfo());
        convertElementToRouterLink(cont);
        return cont;
    }
    mkAvatar() {
        const avatar = document.createElement('img');
        avatar.classList.add(css.avatar);
        avatar.src = `/players-assets/${this.player.avatar}`;
        avatar.addEventListener('error', () => avatar.src = defaultAvatar);
        return avatar;
    }
    mkName() {
        const name = document.createElement('p');
        name.classList.add(css.name);
        name.textContent = `${this.player.name} ${this.player.surname}`;
        return name;
    }
    mkInfo() {
        const list = document.createElement('div');
        list.classList.add(css.info);
        const firstColumnData = {};
        const secondColumnData = {};
        if (this.player.position)
            firstColumnData['ПОЗ'] = this.player.position === 'defender'
                ? 'ЗАЩ'
                : this.player.position === 'goalkeeper'
                    ? 'ВРАТ'
                    : 'НАП';
        if (this.player.height)
            firstColumnData['РОСТ'] = String(this.player.height);
        if (this.player.weight)
            firstColumnData['ВЕС'] = String(this.player.weight);
        if (this.player.grip)
            secondColumnData['ХВАТ'] = this.player.grip === 'left' ? 'ЛЕВЫЙ' : 'ПРАВЫЙ';
        if (this.player.birthday)
            secondColumnData['ДР'] = getDDMMYYYY(this.player.birthday);
        list.append(this.mkInfoColumn(firstColumnData), this.mkInfoColumn(secondColumnData));
        return list;
    }
    mkInfoColumn(record) {
        const column = document.createElement('div');
        column.classList.add(css.infoColumn);
        for (const key in record) {
            column.append(this.mkInfoRecord(key, record[key]));
        }
        return column;
    }
    mkInfoRecord(name, value) {
        const record = document.createElement('span');
        record.classList.add(css.record);
        record.append(this.mkInfoRecordName(name), this.mkInfoRecordValue(value));
        return record;
    }
    mkInfoRecordName(v) {
        const name = document.createElement('p');
        name.classList.add(css.recordName);
        name.textContent = v;
        return name;
    }
    mkInfoRecordValue(v) {
        const value = document.createElement('p');
        value.classList.add(css.recordValue);
        value.textContent = v;
        return value;
    }
}
