import css from './base-settings.component.scss';
import { PLAYTIME_TYPES, PLAYTIME_TYPES_LOCALS, TIMER_TYPES, TIMER_TYPES_LOCALS, TOURNAMENT_TYPES, TOURNAMENT_TYPES_LOCALS } from '../../tournament-editor.conf';
export class TournamentBaseSettingsComp extends HTMLElement {
    tournamentSettings;
    tournamentType;
    groupName;
    typeSettingsRef;
    roundsCountEditorRef;
    set tournamentSettingsSetter(tournamentSettings) {
        this.tournamentSettings = tournamentSettings;
    }
    set tournamentTypeSetter(tournamentType) {
        this.tournamentType = tournamentType;
    }
    set groupNameSetter(groupName) {
        this.groupName = groupName;
    }
    constructor() {
        super();
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkTypeSettings() {
        const cont = document.createElement('div');
        this.typeSettingsRef = cont;
        cont.classList.add(css.settingsCont);
        cont.append(this.mkTypeEditor());
        if (this.tournamentType === TOURNAMENT_TYPES[1])
            cont.append(this.mkRoundsCountEditor());
        return cont;
    }
    mkTypeEditor() {
        const editor = this.mkEditor('*Тип', this.mkTypeSelector());
        return editor;
    }
    mkTypeSelector() {
        const selector = document.createElement('select');
        selector.classList.add(css.selector);
        selector.required = true;
        selector.name = this.groupName ? `${this.groupName}_type` : 'type';
        TOURNAMENT_TYPES.forEach(type => selector.append(this.mkSelectorOption(TOURNAMENT_TYPES_LOCALS[type], type, this.tournamentType === type)));
        selector.addEventListener('change', () => {
            if (selector.value === TOURNAMENT_TYPES[1]) {
                this.typeSettingsRef.append(this.mkRoundsCountEditor());
                return;
            }
            if (this.roundsCountEditorRef)
                this.roundsCountEditorRef.remove();
        });
        return selector;
    }
    mkRoundsCountEditor() {
        const editor = this.mkEditor('*Кол-во туров', this.mkRoundsCountInput());
        this.roundsCountEditorRef = editor;
        return editor;
    }
    mkRoundsCountInput() {
        const input = document.createElement('input');
        input.classList.add(css.input);
        input.type = 'number';
        input.min = '1';
        input.required = true;
        input.placeholder = 'Число от 1';
        input.name = this.groupName ? `${this.groupName}_roundsCount` : 'roundsCount';
        if (this.tournamentSettings?.roundsCount)
            input.value = String(this.tournamentSettings.roundsCount);
        return input;
    }
    mkTimeoutSettings() {
        const cont = document.createElement('div');
        cont.classList.add(css.settingsCont);
        cont.append(this.mkTimeoutsCountEditor(), this.mkTimeoutDurationEditor());
        return cont;
    }
    mkTimeoutsCountEditor() {
        return this.mkEditor('*Кол-во таймаутов', this.mkTimeoutsCountInput());
    }
    mkTimeoutsCountInput() {
        const input = document.createElement('input');
        input.classList.add(css.input);
        input.required = true;
        input.type = 'number';
        input.min = '1';
        input.placeholder = 'Число от 1';
        input.name = this.groupName ? `${this.groupName}_timeoutsCount` : 'timeoutsCount';
        if (this.tournamentSettings?.timeoutsCount)
            input.value = String(this.tournamentSettings.timeoutsCount);
        return input;
    }
    mkTimeoutDurationEditor() {
        return this.mkEditor('*Длительность таймаута', this.mkTimeoutDurationInput());
    }
    mkTimeoutDurationInput() {
        const input = document.createElement('input');
        input.classList.add(css.input);
        input.required = true;
        input.type = 'number';
        input.min = '1';
        input.placeholder = 'Время в минутах';
        input.name = this.groupName ? `${this.groupName}_timeoutDuration` : 'timeoutDuration';
        if (this.tournamentSettings?.timeoutDuration)
            input.value = String(this.tournamentSettings.timeoutDuration);
        return input;
    }
    mkPeriodsSettings() {
        const cont = document.createElement('div');
        cont.classList.add(css.settingsCont);
        cont.append(this.mkPeriodsCountEditor(), this.mkPeriodDurationEditor());
        return cont;
    }
    mkPeriodsCountEditor() {
        return this.mkEditor('*Кол-во периодов', this.mkPeriodsCountInput());
    }
    mkPeriodsCountInput() {
        const input = document.createElement('input');
        input.classList.add(css.input);
        input.required = true;
        input.type = 'number';
        input.min = '1';
        input.placeholder = 'Число от 1';
        input.name = this.groupName ? `${this.groupName}_periodsCount` : 'periodsCount';
        if (this.tournamentSettings?.periodsCount)
            input.value = String(this.tournamentSettings.periodsCount);
        return input;
    }
    mkPeriodDurationEditor() {
        return this.mkEditor('*Длительность периода', this.mkPeriodDurationInput());
    }
    mkPeriodDurationInput() {
        const input = document.createElement('input');
        input.classList.add(css.input);
        input.required = true;
        input.type = 'number';
        input.min = '1';
        input.placeholder = 'Время в минутах';
        input.name = this.groupName ? `${this.groupName}_periodDuration` : 'periodDuration';
        if (this.tournamentSettings?.periodDuration)
            input.value = String(this.tournamentSettings.periodDuration);
        return input;
    }
    mkPenaltySettings() {
        const cont = document.createElement('div');
        cont.classList.add(css.settingsCont);
        cont.append(this.mkSmallPenaltyDurationEditor(), this.mkBigPenaltyDurationEditor(), this.mkDisciplinePenaltyDurationEditor(), this.mkDisqualPenaltyDurationEditor(), this.mkMatchPenaltyDurationEditor());
        return cont;
    }
    mkSmallPenaltyDurationEditor() {
        return this.mkEditor('*Длительность малого штрафа', this.mkSmallPenaltyDurationInput());
    }
    mkSmallPenaltyDurationInput() {
        const input = document.createElement('input');
        input.classList.add(css.input);
        input.required = true;
        input.type = 'number';
        input.min = '1';
        input.placeholder = 'Время в секундах';
        input.name = this.groupName ? `${this.groupName}_smallPenaltyDuration` : 'smallPenaltyDuration';
        if (this.tournamentSettings?.smallPenaltyDuration)
            input.value = String(this.tournamentSettings.smallPenaltyDuration);
        return input;
    }
    mkBigPenaltyDurationEditor() {
        return this.mkEditor('*Длительность большого штрафа', this.mkBigPenaltyDurationInput());
    }
    mkBigPenaltyDurationInput() {
        const input = document.createElement('input');
        input.classList.add(css.input);
        input.required = true;
        input.type = 'number';
        input.min = '1';
        input.placeholder = 'Время в секундах';
        input.name = this.groupName ? `${this.groupName}_bigPenaltyDuration` : 'bigPenaltyDuration';
        if (this.tournamentSettings?.bigPenaltyDuration)
            input.value = String(this.tournamentSettings.bigPenaltyDuration);
        return input;
    }
    mkDisciplinePenaltyDurationEditor() {
        return this.mkEditor('*Длительность дисц. штрафа', this.mkDisciplinePenaltyDurationInput());
    }
    mkDisciplinePenaltyDurationInput() {
        const input = document.createElement('input');
        input.classList.add(css.input);
        input.required = true;
        input.type = 'number';
        input.min = '1';
        input.placeholder = 'Время в секундах';
        input.name = this.groupName ? `${this.groupName}_disciplinePenaltyDuration` : 'disciplinePenaltyDuration';
        if (this.tournamentSettings?.disciplinePenaltyDuration)
            input.value = String(this.tournamentSettings.disciplinePenaltyDuration);
        return input;
    }
    mkDisqualPenaltyDurationEditor() {
        return this.mkEditor('*Длительность дисквал. штрафа', this.mkDisqualPenaltyDurationInput());
    }
    mkDisqualPenaltyDurationInput() {
        const input = document.createElement('input');
        input.classList.add(css.input);
        input.required = true;
        input.type = 'number';
        input.min = '1';
        input.placeholder = 'Время в секундах';
        input.name = this.groupName ? `${this.groupName}_disqualPenaltyDuration` : 'disqualPenaltyDuration';
        if (this.tournamentSettings?.disqualPenaltyDuration)
            input.value = String(this.tournamentSettings.disqualPenaltyDuration);
        return input;
    }
    mkMatchPenaltyDurationEditor() {
        return this.mkEditor('*Длительность матч штрафа', this.mkMatchPenaltyDurationInput());
    }
    mkMatchPenaltyDurationInput() {
        const input = document.createElement('input');
        input.classList.add(css.input);
        input.required = true;
        input.type = 'number';
        input.min = '1';
        input.placeholder = 'Время в секундах';
        input.name = this.groupName ? `${this.groupName}_matchPenaltyDuration` : 'matchPenaltyDuration';
        if (this.tournamentSettings?.matchPenaltyDuration)
            input.value = String(this.tournamentSettings.matchPenaltyDuration);
        return input;
    }
    mkTimerSettings() {
        const cont = document.createElement('div');
        cont.classList.add(css.settingsCont);
        cont.append(this.mkTimerTypeEditor(), this.mkPlaytimeTypeEditor());
        return cont;
    }
    mkTimerTypeEditor() {
        return this.mkEditor('*Тип таймера', this.mkTimerTypeSelector());
    }
    mkTimerTypeSelector() {
        const selector = document.createElement('select');
        selector.classList.add(css.selector);
        selector.required = true;
        selector.name = this.groupName ? `${this.groupName}_timerType` : 'timerType';
        TIMER_TYPES.forEach(type => selector.append(this.mkSelectorOption(TIMER_TYPES_LOCALS[type], type, this.tournamentSettings?.timerType === type)));
        return selector;
    }
    mkPlaytimeTypeEditor() {
        return this.mkEditor('*Тип игрового времени', this.mkPlaytimeTypeSelector());
    }
    mkPlaytimeTypeSelector() {
        const selector = document.createElement('select');
        selector.classList.add(css.selector);
        selector.required = true;
        selector.name = this.groupName ? `${this.groupName}_playtimeType` : 'playtimeType';
        PLAYTIME_TYPES.forEach(type => selector.append(this.mkSelectorOption(PLAYTIME_TYPES_LOCALS[type], type, this.tournamentSettings?.playtimeType === type)));
        return selector;
    }
    mkMaxApplicationPlayers() {
        return this.mkEditor('Максимальное кол-во игроков в заявке', this.mkMaxApplicationPlayerInput());
    }
    mkMaxApplicationPlayerInput() {
        const input = document.createElement('input');
        input.classList.add(css.input);
        input.required = true;
        input.type = 'number';
        input.min = '6';
        input.placeholder = 'Число от 6';
        input.name = this.groupName ? `${this.groupName}_maxApplicationPlayers` : 'maxApplicationPlayers';
        if (this.tournamentSettings?.maxApplicationPlayers)
            input.value = String(this.tournamentSettings.maxApplicationPlayers);
        return input;
    }
    mkEditor(name, formControl) {
        const label = document.createElement('label');
        label.classList.add(css.editor);
        label.textContent = name;
        label.append(formControl);
        return label;
    }
    mkSelectorOption(name, value, selected = false) {
        const option = document.createElement('option');
        option.textContent = name;
        option.value = value;
        if (selected)
            option.selected = true;
        return option;
    }
}
