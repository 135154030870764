import css from './match-header.component.scss';
import { getDDMonthhhmm } from '@Helpers/date.helper';
export class MatchHeaderComp extends HTMLElement {
    matchInfo;
    set matchHeaderInfo(info) {
        this.matchInfo = info;
    }
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
    }
    render() {
        this.assignHostStyles();
        this.append(...this.mkBackground(), this.mkDate(), this.mkMatchInfoBlock());
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkBackground() {
        const firstTeam = document.createElement('img');
        firstTeam.src = `/teams-assets/${this.matchInfo.team1.logo}`;
        firstTeam.classList.add(css.firstBackgroundImg);
        const secondTeam = document.createElement('img');
        secondTeam.src = `/teams-assets/${this.matchInfo.team2.logo}`;
        secondTeam.classList.add(css.secondBackgroundImg);
        return [firstTeam, secondTeam];
    }
    mkDate() {
        const matchDate = document.createElement('time');
        matchDate.classList.add(css.date);
        matchDate.dateTime = this.matchInfo.datetime;
        matchDate.textContent = getDDMonthhhmm(matchDate.dateTime);
        return matchDate;
    }
    mkMatchInfoBlock() {
        const el = document.createElement('div');
        el.classList.add(css.matchInfoBlock);
        el.append(this.mkFirstTeamLogo(), this.mkVsTag(), this.mkSecondTeamLogo());
        return el;
    }
    mkFirstTeamLogo() {
        const firstTeam = document.createElement('img');
        firstTeam.classList.add(css.teams, css.teamLeft);
        firstTeam.src = `/teams-assets/${this.matchInfo.team1.logo}`;
        firstTeam.alt = this.matchInfo.team1.name;
        return firstTeam;
    }
    mkSecondTeamLogo() {
        const secondTeam = document.createElement('img');
        secondTeam.classList.add(css.teams, css.teamRight);
        secondTeam.src = `/teams-assets/${this.matchInfo.team2.logo}`;
        secondTeam.alt = this.matchInfo.team2.name;
        return secondTeam;
    }
    mkVsTag() {
        const vsTag = document.createElement('p');
        vsTag.classList.add(css.vsTag);
        vsTag.textContent = 'VS';
        return vsTag;
    }
}
