import css from './general-settings.component.scss';
export class TournamentGeneralSettingsComp extends HTMLElement {
    tournament;
    set tournamentSetter(tournament) {
        this.tournament = tournament;
    }
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkHeading(), this.mkSettings());
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkHeading() {
        const heading = document.createElement('h4');
        heading.classList.add(css.heading);
        heading.textContent = 'Общие настройки';
        return heading;
    }
    mkSettings() {
        const cont = document.createElement('div');
        cont.classList.add(css.settingsCont);
        cont.append(this.mkNameEditor(), this.mkNameAbbrEditor(), this.mkDateEditor());
        return cont;
    }
    mkNameEditor() {
        return this.mkEditor('*Название', this.mkNameInput());
    }
    mkNameInput() {
        const input = document.createElement('input');
        input.classList.add(css.input, css.nameInput);
        input.required = true;
        input.placeholder = 'Любой текст';
        input.name = 'name';
        if (this.tournament?.name)
            input.value = this.tournament.name;
        return input;
    }
    mkNameAbbrEditor() {
        return this.mkEditor('Сокращенное название', this.mkNameAbbrInput());
    }
    mkNameAbbrInput() {
        const input = document.createElement('input');
        input.classList.add(css.input);
        input.placeholder = 'Любой текст';
        input.name = 'nameAbbr';
        if (this.tournament?.nameAbbr)
            input.value = this.tournament.nameAbbr;
        return input;
    }
    mkDateEditor() {
        return this.mkEditor('*Дата начала', this.mkDateInput());
    }
    mkDateInput() {
        const input = document.createElement('input');
        input.classList.add(css.input);
        input.type = 'date';
        input.required = true;
        input.name = 'date';
        if (this.tournament?.date)
            input.valueAsDate = new Date(this.tournament.date);
        return input;
    }
    mkEditor(name, formControl) {
        const label = document.createElement('label');
        label.classList.add(css.editor);
        label.textContent = name;
        label.append(formControl);
        return label;
    }
}
