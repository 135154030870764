import { MatchesHttp } from '@Services/http/matches.http';
import css from './match-media.component.scss';
import { Subject } from 'rxjs';
import { paths } from '../match-page-nav.conf';
import { Layout } from '@Services/layout.service';
export class MatchMediaComp extends HTMLElement {
    matchesHttp = MatchesHttp.instance;
    photos = new Subject();
    subscriptions = [];
    layoutService = Layout.instance;
    photosSnapshot;
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
    }
    disconnectedCallback() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkPaddingContent());
        this.reqPhotosByMatch();
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkPaddingContent() {
        const paddingContent = document.createElement('div');
        paddingContent.classList.add(css.paddingContent);
        paddingContent.append(this.mkPhotoGallery());
        return paddingContent;
    }
    mkPhotoGallery() {
        const gallery = document.createElement('div');
        gallery.classList.add(css.gallery);
        const columns = this.defineGalleryColumns();
        const photoesSubscription = this.photos.subscribe(photos => {
            this.photosSnapshot = photos;
            photos.forEach((photo, idx) => {
                columns[idx % columns.length].append(this.mkPhoto(photo, idx));
            });
        });
        this.subscriptions.push(photoesSubscription);
        columns.forEach(column => gallery.append(column));
        return gallery;
    }
    defineGalleryColumns() {
        return this.layoutService.isMobile
            ? [this.mkGalleryColumn(), this.mkGalleryColumn()]
            : this.layoutService.isTablet
                ? [this.mkGalleryColumn(), this.mkGalleryColumn(), this.mkGalleryColumn()]
                : [this.mkGalleryColumn(), this.mkGalleryColumn(), this.mkGalleryColumn(), this.mkGalleryColumn()];
    }
    mkGalleryColumn() {
        const column = document.createElement('div');
        column.classList.add(css.galleryColumn);
        return column;
    }
    mkPhoto(photoUrl, idx) {
        const photo = document.createElement('img');
        photo.classList.add(css.photo);
        photo.src = photoUrl;
        photo.addEventListener('click', () => this.openPhotoPreviewer(idx));
        return photo;
    }
    openPhotoPreviewer(selectedPhotoIdx) {
        this.append(this.mkPhotoPreviewer(selectedPhotoIdx));
    }
    mkPhotoPreviewer(selectedPhotoIdx) {
        const preview = document.createElement('app-photo-previewer');
        preview.photoSetter = {
            photos: this.photosSnapshot,
            selectedIndex: selectedPhotoIdx
        };
        return preview;
    }
    reqPhotosByMatch() {
        this.matchesHttp.getPhotoGalleryByMatchId(paths()[1])
            .then(photoes => this.photos.next(photoes))
            .catch();
    }
}
