import css from './intro-section.component.scss';
import { convertElementToRouterLink } from '@Helpers/router.helper';
export class IntroSectionComp extends HTMLElement {
    firstNews;
    lastFiveNews;
    set firstNewsSetter(news) {
        this.firstNews = news;
    }
    set lastFiveNewsSetter(news) {
        this.lastFiveNews = news;
    }
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkFirstNews(), this.mkMainSponsor(), this.mkLastFiveNewsCont());
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkFirstNews() {
        const mainNews = document.createElement('div');
        mainNews.classList.add(css.mainNews);
        mainNews.append(this.mkFirstNewsGradientPlate(), this.mkFirstNewsImage());
        return mainNews;
    }
    mkFirstNewsImage() {
        const image = document.createElement('img');
        image.classList.add(css.mainNewsImage);
        image.src = `/news-assets/${this.firstNews.img}`;
        return image;
    }
    mkMainSponsor() {
        const sponsor = document.createElement('app-main-sponsor');
        sponsor.classList.add(css.sponsor);
        return sponsor;
    }
    mkLastFiveNewsCont() {
        const cont = document.createElement('div');
        cont.classList.add(css.lastFiveNewsCont);
        this.lastFiveNews.forEach(n => cont.append(this.mkNewsMiniPreview(n)));
        return cont;
    }
    mkNewsMiniPreview(news) {
        const newsMiniPreview = document.createElement('app-news-mini-preview');
        newsMiniPreview.classList.add(css.newsMiniPreview);
        newsMiniPreview.newsSetter = news;
        return newsMiniPreview;
    }
    mkFirstNewsGradientPlate() {
        const plate = document.createElement('div');
        plate.classList.add(css.firstNewsPlate);
        plate.append(this.mkFirstNewsTitle(), this.mkLead(), this.mkWatchButton());
        return plate;
    }
    mkFirstNewsTitle() {
        const title = document.createElement('p');
        title.textContent = this.firstNews.title;
        title.classList.add(css.title);
        return title;
    }
    mkLead() {
        const title = document.createElement('p');
        title.textContent = this.firstNews.lead;
        title.classList.add(css.lead);
        return title;
    }
    mkWatchButton() {
        const button = document.createElement('button');
        button.classList.add(css.watchButton);
        button.append(this.mkMainNewsLink());
        return button;
    }
    mkMainNewsLink() {
        const newsLink = document.createElement('a');
        newsLink.textContent = 'Смотреть';
        newsLink.classList.add(css.newsLink);
        newsLink.href = `/news-details/${this.firstNews._id}`;
        convertElementToRouterLink(newsLink);
        return newsLink;
    }
}
