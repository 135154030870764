import { TeamCellRendererComp } from '@Components/cell-renderers/team/team.component';
export const defaultColDef = {
    resizable: false,
    suppressMovable: true,
    flex: 1
};
export const columnDefs = [
    {
        headerName: 'Команда',
        valueGetter: (params) => {
            if (!params.data)
                return;
            return {
                teamName: params.data.team.name,
                teamId: params.data.team.teamId
            };
        },
        cellRenderer: TeamCellRendererComp,
        flex: 4
    },
    {
        headerName: 'И',
        headerTooltip: 'Количество игр',
        field: 'gamesAmount'
    },
    {
        headerName: 'О',
        headerTooltip: 'Очки',
        field: 'points'
    },
    {
        headerName: 'В',
        headerTooltip: 'Победы',
        field: 'wins'
    },
    {
        headerName: 'П',
        headerTooltip: 'Поражения',
        field: 'losses'
    },
    {
        headerName: 'Н',
        headerTooltip: 'Ничьи',
        field: 'draws'
    },
    {
        headerName: 'Ш',
        headerTooltip: 'Заброшеные шайбы',
        field: 'goalsFor'
    },
    {
        headerName: 'ПШ',
        headerTooltip: 'Пропущеные шайбы',
        field: 'goalsAgainst'
    },
    {
        headerName: 'ПИ',
        headerTooltip: 'Победные игры',
        valueGetter: (params) => {
            if (!params.data)
                return;
            return `${params.data.wins} из ${params.data.gamesAmount}`;
        }
    }
];
