import { MainComponent } from './main-page.component';
import { BirthdaySectionComp } from './birthday-section/birthday-section.component';
import { VideoSectionComp } from './video-section/video-section.component';
import { SliderComp } from '@Components/slider/slider.component';
import { FooterComp } from '@Components/footer/footer.component';
import { LeaderboardComp } from '@Components/leaderboard/leaderboard.component';
import { LeaderboardSectionComp } from './leaderboard-section/leaderboard-section.component';
import { IntroSectionComp } from './intro-section/intro-section.component';
import { SponsorsComp } from '@Components/sponsors/sponsors.component';
import { NewsMiniPreviewComp } from '@Components/news-mini-preview/news-mini-preview.component';
import { MainSponsorComp } from '@Components/main-sponsor/main-sponsor.component';
export const CUSTOM_ELEMENTS_CONF = [
    { tag: 'app-main', comp: MainComponent },
    { tag: 'app-birthday-section', comp: BirthdaySectionComp },
    { tag: 'app-video-section', comp: VideoSectionComp },
    { tag: 'app-slider', comp: SliderComp },
    { tag: 'app-footer', comp: FooterComp },
    { tag: 'app-leaderboard', comp: LeaderboardComp },
    { tag: 'app-leaderboard-section', comp: LeaderboardSectionComp },
    { tag: 'app-intro-section', comp: IntroSectionComp },
    { tag: 'app-sponsors', comp: SponsorsComp },
    { tag: 'app-main-sponsor', comp: MainSponsorComp },
    { tag: 'app-news-mini-preview', comp: NewsMiniPreviewComp }
];
