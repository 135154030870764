import { ORIGIN, PROTOCOL } from '@Constants/http.constants';
export class LoginHttp {
    static #instance;
    constructor() { }
    static get instance() {
        if (!LoginHttp.#instance)
            LoginHttp.#instance = new LoginHttp();
        return LoginHttp.#instance;
    }
    async login(form) {
        try {
            const res = await fetch(`${PROTOCOL}${ORIGIN}/api/auth/login`, {
                method: 'POST',
                body: new FormData(form)
            });
            if (!res.ok) {
                const parsedResponse = await res.json();
                throw new Error(parsedResponse.msg);
            }
            return res.json();
        }
        catch (err) {
            console.log('[HTTP] error during login', err);
            throw err;
        }
    }
}
