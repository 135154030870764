import { StatisticsHttp } from '@Services/http/statistics.http';
import css from './statistics-page.component.scss';
import { Subject } from 'rxjs';
export class StatisticsComp extends HTMLElement {
    statsHttp = StatisticsHttp.instance;
    statsParams = {};
    stats = new Subject();
    subscriptions = [];
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
        this.reqStats();
    }
    disconnectedCallback() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkPaddingContent());
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkPaddingContent() {
        const paddingContent = document.createElement('div');
        paddingContent.classList.add(css.paddingContent);
        paddingContent.append(this.mkStatsFilters(), this.mkPlayersStatsSection());
        return paddingContent;
    }
    mkPlayersStatsSection() {
        const section = document.createElement('app-players-stats-section');
        const statsSubscription = this.stats.subscribe(stats => section.playersStatsSetter = stats);
        this.subscriptions.push(statsSubscription);
        section.playersStatsSetter = null;
        return section;
    }
    reqStats() {
        this.stats.next(null);
        this.statsHttp.getStatsByFilters(this.statsParams)
            .then(stats => this.stats.next(stats))
            .catch();
    }
    mkStatsFilters() {
        const filters = document.createElement('app-players-stats-filters');
        filters.addEventListener('filtersChange', ((e) => {
            this.statsParams.startDate = e.detail.season.startDate;
            this.statsParams.endDate = e.detail.season.endDate;
            this.reqStats();
        }));
        return filters;
    }
}
