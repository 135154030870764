import { paths } from '../player-page-nav.conf';
import css from './player-stats.component.scss';
export class PlayerStatsComp extends HTMLElement {
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkPaddingContent());
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkPaddingContent() {
        const paddingContent = document.createElement('div');
        paddingContent.classList.add(css.paddingContent);
        paddingContent.append(this.mkLastFiveMatchesSection(), this.mkStatsByTeams(), this.mkStatsBySeasons());
        return paddingContent;
    }
    mkLastFiveMatchesSection() {
        const section = document.createElement('app-player-last-five-matches-section');
        section.playerIdSetter = paths()[1];
        return section;
    }
    mkStatsByTeams() {
        const section = document.createElement('app-player-stats-by-teams-section');
        section.playerIdSetter = paths()[1];
        return section;
    }
    mkStatsBySeasons() {
        const section = document.createElement('app-player-stats-by-seasons-section');
        section.playerIdSetter = paths()[1];
        return section;
    }
}
