import EditorJS from '@editorjs/editorjs';
import css from './editorjs.component.scss';
import Header from '@editorjs/header';
import Paragraph from '@editorjs/paragraph';
import List from '@editorjs/list';
import Delimiter from '@editorjs/delimiter';
import Underline from '@editorjs/underline';
import AlighmentTune from 'editorjs-text-alignment-blocktune';
import Table from '@editorjs/table';
export class EditorJSComp extends HTMLElement {
    editorRef;
    data;
    readonlyMode = false;
    get instance() {
        return this.editorRef;
    }
    set dataSetter(data) {
        this.data = data;
    }
    set readonlyModeSetter(readonlyMode) {
        this.readonlyMode = readonlyMode;
    }
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
    }
    save() {
        return this.editorRef.save();
    }
    render() {
        this.assignHostStyles();
        this.initEditor();
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    getDefaultConfig() {
        return {
            holder: this,
            data: this.data,
            tools: {
                paragraph: {
                    class: Paragraph,
                    tunes: ['alighmentTune']
                },
                header: {
                    class: Header,
                    config: {
                        placeholder: 'Enter a header',
                        levels: [2, 3, 4],
                        defaultLevel: 3
                    },
                    tunes: ['alighmentTune']
                },
                list: {
                    class: List
                },
                delimiter: {
                    class: Delimiter
                },
                alighmentTune: {
                    class: AlighmentTune
                },
                underline: {
                    class: Underline
                },
                table: {
                    class: Table
                }
            }
        };
    }
    initEditor() {
        this.editorRef = new EditorJS(this.readonlyMode
            ? {
                ...this.getDefaultConfig(),
                readOnly: true
            }
            : {
                ...this.getDefaultConfig(),
                autofocus: true,
                placeholder: 'Выберите элемент или введите текст...',
                i18n: {
                    messages: {
                        ui: {
                            "blockTunes": {
                                "toggler": {
                                    "Click to tune": "Нажмите, чтобы настроить",
                                    "or drag to move": "или перетащите"
                                },
                            },
                            "inlineToolbar": {
                                "converter": {
                                    "Convert to": "Конвертировать в"
                                }
                            },
                            "toolbar": {
                                "toolbox": {
                                    "Add": "Добавить"
                                }
                            },
                            "popover": {
                                "Filter": "Фильтр",
                                "Nothing found": "Ничего не найдено",
                                "Convert to": "Конвертировать в"
                            }
                        },
                        toolNames: {
                            "Text": "Параграф",
                            "Heading": "Заголовок",
                            "List": "Список",
                            "Delimiter": "Разделитель",
                            "Table": "Таблица",
                            "Marker": "Маркер",
                            "Bold": "Полужирный",
                            "Italic": "Курсив",
                            "InlineCode": "Моноширинный",
                            "Underline": "Нижнее подчеркивание",
                            "Convert to": "Конвертировать в"
                        },
                        blockTunes: {
                            "delete": {
                                "Delete": "Удалить",
                                "Click to delete": "Нажмите, чтобы удалить"
                            },
                            "moveUp": {
                                "Move up": "Переместить вверх"
                            },
                            "moveDown": {
                                "Move down": "Переместить вниз"
                            },
                            "Stretch": "Растянуть",
                            "With headings": "С заголовками"
                        }
                    }
                }
            });
    }
}
