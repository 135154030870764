export const paths = () => location.pathname.split('/').filter(path => !!path);
export const pPagePrefix = () => `/${paths()[0]}/${paths()[1]}`;
export const PLAYER_NAV_LINKS = () => [
    {
        name: 'Статистика',
        path: `${pPagePrefix()}/stats`
    },
    {
        name: 'Матчи',
        path: `${pPagePrefix()}/matches`
    }
];
