import { ORIGIN, PROTOCOL } from '@Constants/http.constants';
export class DocumentsHttp {
    static #instance;
    contructor() { }
    static get instance() {
        if (!DocumentsHttp.#instance)
            DocumentsHttp.#instance = new DocumentsHttp();
        return DocumentsHttp.#instance;
    }
    async getDocumentsSections() {
        try {
            const res = await fetch(`${PROTOCOL}${ORIGIN}/api/documents/getAll`, {
                method: 'GET'
            });
            if (!res.ok) {
                const parsedResponse = await res.json();
                throw new Error(parsedResponse.msg);
            }
            return res.json();
        }
        catch (err) {
            console.log('[HTTP] error during getDocuments', err);
            throw err;
        }
    }
    async createDocumentsSection(body) {
        try {
            const res = await fetch(`${PROTOCOL}${ORIGIN}/api/documents/create`, {
                method: 'POST',
                body: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (!res.ok) {
                const parsedResponse = await res.json();
                throw new Error(parsedResponse.msg);
            }
            return res.json();
        }
        catch (err) {
            console.log('[HTTP] error during createDocumentsSection', err);
            throw err;
        }
    }
    async updateDocumentsSection(body) {
        try {
            const res = await fetch(`${PROTOCOL}${ORIGIN}/api/documents/update/${body._id}`, {
                method: 'PUT',
                body: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (!res.ok) {
                const parsedResponse = await res.json();
                throw new Error(parsedResponse.msg);
            }
        }
        catch (err) {
            console.log('[HTTP] error during updateDocumentsSection', err);
            throw err;
        }
    }
    async deleteDocumentsSectionById(id) {
        try {
            const res = await fetch(`${PROTOCOL}${ORIGIN}/api/documents/delete/${id}`, {
                method: 'DELETE'
            });
            if (!res.ok) {
                const parsedResponse = await res.json();
                throw new Error(parsedResponse.msg);
            }
        }
        catch (err) {
            console.log('[HTTP] error during deleteDocumentsSection', err);
            throw err;
        }
    }
}
