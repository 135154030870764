import css from './about-desc.component.scss';
import { DefaultSection } from '@Components/section/section.component';
export class AboutDescComp extends DefaultSection {
    textSection;
    set dataSetter(textSection) {
        this.textSection = textSection;
    }
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkTitle(this.textSection.title), this.mkHeaderUnderline(), this.mkDesc());
    }
    mkDesc() {
        const desc = document.createElement('p');
        desc.classList.add(css.desc);
        desc.textContent = this.textSection.text;
        return desc;
    }
}
