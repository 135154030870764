import css from './team-players-stats-section.scss';
import { Layout } from '@Services/layout.service';
import { DefaultSection } from '@Components/section/section.component';
import { createGrid } from '@ag-grid-community/core';
import { columnDefs, defaultColDef } from './team-players-stats-table.conf';
import { themeQuartz } from '@ag-grid-community/theming';
import { GridLoaderComp } from '@Components/grid-loader/grid-loader.component';
import { GridNoDataComp } from '@Components/grid-no-data/grid-no-data.component';
import { TeamsHttp } from '@Services/http/teams.http';
export class TeamPlayersStatsSectionComp extends DefaultSection {
    layoutService = Layout.instance;
    gridOptions = this.defineGridOptions();
    gridApi;
    teamsHttp = TeamsHttp.instance;
    teamId;
    gridContRef;
    set teamIdSetter(teamId) {
        this.teamId = teamId;
    }
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
        this.reqPlayersStats();
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkTitle('Статистика игроков'), this.mkHeaderUnderline(), this.mkTable());
    }
    assignHostStyles() {
        super.assignHostStyles();
        this.classList.add(css.host);
    }
    mkTable() {
        const cont = document.createElement('div');
        this.gridContRef = cont;
        cont.classList.add(css.table, css.tableDefaultHeight);
        this.gridApi = createGrid(cont, this.gridOptions);
        this.showLoading();
        return cont;
    }
    defineGridOptions() {
        return this.layoutService.isDesktop
            ? this.defineDesktopGridOptions()
            : this.defineMobileGridOptions();
    }
    defineDesktopGridOptions() {
        return {
            defaultColDef,
            columnDefs,
            theme: themeQuartz,
            loadThemeGoogleFonts: false,
            loadingOverlayComponent: GridLoaderComp,
            noRowsOverlayComponent: GridNoDataComp,
            tooltipShowDelay: 0
        };
    }
    defineMobileGridOptions() {
        return {
            defaultColDef,
            columnDefs: columnDefs.map(col => {
                if (col.headerName === 'Игрок') {
                    return {
                        ...col,
                        pinned: 'left'
                    };
                }
                return col;
            }),
            theme: themeQuartz,
            loadThemeGoogleFonts: false,
            loadingOverlayComponent: GridLoaderComp,
            noRowsOverlayComponent: GridNoDataComp,
            tooltipShowDelay: 0,
            autoSizeStrategy: {
                type: 'fitCellContents'
            }
        };
    }
    reqPlayersStats() {
        this.teamsHttp.getTeamPlayersStatsById(this.teamId)
            .then(playersStats => {
            this.gridContRef.classList.remove(css.tableDefaultHeight);
            this.gridContRef.classList.add(css.tableFullHeight);
            this.gridApi.setGridOption('rowData', playersStats);
        })
            .catch(() => {
            this.showEmptyPlaceholder();
        });
    }
    showLoading() {
        this.gridApi.setGridOption('rowData', null);
    }
    showEmptyPlaceholder() {
        this.gridApi.setGridOption('rowData', []);
    }
}
