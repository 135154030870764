import css from './document.component.scss';
import pdfIcon from '@Assets/icons/document-pdf-icon.svg';
import docxIcon from '@Assets/icons/document-docx-icon.svg';
import { getAltFromPath } from '@Helpers/images.helper';
export class DocumentComp extends HTMLElement {
    document;
    set documentSetter(document) {
        this.document = document;
    }
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkDocumentIcon(), this.mkDocumentLink());
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkDocumentIcon() {
        const documentIcon = document.createElement('img');
        documentIcon.src = this.getDocumentIconByType();
        documentIcon.alt = getAltFromPath(this.getDocumentIconByType());
        return documentIcon;
    }
    mkDocumentLink() {
        const documentLink = document.createElement('a');
        documentLink.classList.add(css.link);
        documentLink.textContent = this.document.name;
        documentLink.href = `documents/${this.document.file}`;
        return documentLink;
    }
    getDocumentIconByType() {
        return this.document.type === 'pdf' ? pdfIcon : docxIcon;
    }
}
