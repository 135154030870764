import css from './slider.component.scss';
export class SliderComp extends HTMLElement {
    data;
    set sliderSetter(attachments) {
        this.data = attachments;
    }
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
        this.observeChildren();
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkPrevBtn(), this.mkSlider(), this.mkNextBtn());
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkArrowLeft() {
        const arrow = document.createElement('span');
        arrow.classList.add(css.arrow, css.arrowLeft);
        return arrow;
    }
    mkArrowRight() {
        const arrow = document.createElement('span');
        arrow.classList.add(css.arrow, css.arrowRight);
        return arrow;
    }
    mkSlider() {
        const list = document.createElement('ul');
        list.classList.add(css.slider);
        for (let i = 0; i < this.data.length; i++) {
            list.append(this.mkListItem(this.data[i]));
        }
        return list;
    }
    mkListItem(child) {
        const el = document.createElement('li');
        el.append(child);
        return el;
    }
    mkPrevBtn() {
        const btn = document.createElement('button');
        btn.append(this.mkArrowLeft());
        btn.classList.add(css.scrollBtn, css.prevScrollBtn);
        btn.addEventListener('click', () => this.scrollPrev());
        return btn;
    }
    mkNextBtn() {
        const btn = document.createElement('button');
        btn.append(this.mkArrowRight());
        btn.classList.add(css.scrollBtn, css.nextScrollBtn);
        btn.addEventListener('click', () => this.scrollNext());
        return btn;
    }
    scrollPrev() {
        for (let i = 0; i < this.data.length; i++) {
            if (!this.data[i].hasAttribute('data-visible') && this.data[i + 1]?.hasAttribute('data-visible')) {
                this.data[i].scrollIntoView({ inline: 'start', block: 'nearest', behavior: 'smooth' });
                break;
            }
            if (i === 0) {
                this.data[i].scrollIntoView({ inline: 'start', block: 'nearest', behavior: 'smooth' });
            }
        }
    }
    scrollNext() {
        for (let i = this.data.length - 1; i > 0; i--) {
            if (!this.data[i].hasAttribute('data-visible') && this.data[i - 1]?.hasAttribute('data-visible')) {
                this.data[i].scrollIntoView({ inline: 'end', block: 'nearest', behavior: 'smooth' });
                break;
            }
            if (this.data.length - 1 === i) {
                this.data[i].scrollIntoView({ inline: 'end', block: 'nearest', behavior: 'smooth' });
            }
        }
    }
    observeChildren() {
        const viewPortObs = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.dataset.visible = 'true';
                }
                else {
                    entry.target.removeAttribute('data-visible');
                }
            });
        }, {
            root: this,
            threshold: 0.6
        });
        this.data.forEach(el => viewPortObs.observe(el));
    }
}
