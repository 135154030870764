import css from './leaderboard.component.scss';
import { Subject } from 'rxjs';
import defaultAvatar from '@Assets/icons/player-default-avatar.svg';
import { convertElementToRouterLink } from '@Helpers/router.helper';
export class LeaderboardComp extends HTMLElement {
    subscriptions = [];
    leaderboard;
    currentPlayer = new Subject();
    avatarRef;
    nameRef;
    pointsRef;
    defaultPlayer;
    selectedPlayerPreviewRef;
    leaderboardsTitleLocals = {
        'goals': 'Голы',
        'points': 'Очки',
        'saves': 'Сейвы',
        'assists': 'Ассисты',
        'games': 'Игры'
    };
    set dataSetter(leaderboard) {
        this.leaderboard = leaderboard;
        this.defaultPlayer = leaderboard.players[0];
    }
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
    }
    disconnectedCallback() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
    render() {
        this.append(this.mkLeaderboard());
    }
    mkLeaderboard() {
        const leaderboard = document.createElement('div');
        leaderboard.classList.add(css.leaderboard);
        leaderboard.append(this.mkTitle(), this.mkSelectedPlayerPreview(), this.mkPlayersList());
        return leaderboard;
    }
    mkTitle() {
        const title = document.createElement('h3');
        title.classList.add(css.title);
        title.textContent = this.leaderboardsTitleLocals[this.leaderboard.name];
        return title;
    }
    mkSelectedPlayerPreview() {
        const selectedPlayerPreview = document.createElement('a');
        this.selectedPlayerPreviewRef = selectedPlayerPreview;
        selectedPlayerPreview.classList.add(css.selectedPlayerPreview);
        const currentPlayerSubscription = this.currentPlayer.subscribe((leaderboardPlayer) => {
            this.updateSelectedPlayerPreview(leaderboardPlayer);
        });
        this.subscriptions.push(currentPlayerSubscription);
        selectedPlayerPreview.append(this.mkPlayerPreviewPhoto(), this.mkPlayerPreviewName(), this.mkPlayerPreviewPoints());
        selectedPlayerPreview.href = `/player/${this.defaultPlayer.player.playerId}/stats`;
        convertElementToRouterLink(selectedPlayerPreview);
        return selectedPlayerPreview;
    }
    updateSelectedPlayerPreview(leaderboardPlayer) {
        this.avatarRef.src = `/players-assets/${leaderboardPlayer.player.avatar ?? defaultAvatar}`;
        this.nameRef.textContent = `${leaderboardPlayer.player.name} ${leaderboardPlayer.player.surname}`;
        this.pointsRef.textContent = String(leaderboardPlayer.points);
        this.selectedPlayerPreviewRef.href = `/player/${leaderboardPlayer.player.playerId}/stats`;
    }
    mkPlayerPreviewPhoto() {
        const playerPhoto = document.createElement('img');
        this.avatarRef = playerPhoto;
        playerPhoto.classList.add(css.playerPhoto);
        playerPhoto.src = `/players-assets/${this.defaultPlayer.player.avatar ?? defaultAvatar}`;
        playerPhoto.addEventListener('error', () => playerPhoto.src = defaultAvatar);
        return playerPhoto;
    }
    mkPlayerPreviewName() {
        const playerName = document.createElement('p');
        this.nameRef = playerName;
        playerName.classList.add(css.playerName);
        playerName.textContent = `${this.defaultPlayer.player.name} ${this.defaultPlayer.player.surname}`;
        return playerName;
    }
    mkPlayersList() {
        const list = document.createElement('ul');
        list.classList.add(css.playersList);
        this.leaderboard.players.forEach((player, index) => {
            list.append(this.mkPlayersListItem(player, index));
        });
        return list;
    }
    mkPlayersListItem(player, index) {
        const el = document.createElement('li');
        el.append(this.mkPlayerLink(player, index));
        return el;
    }
    mkPlayerLink(player, index) {
        const el = document.createElement('a');
        el.classList.add(css.player);
        el.textContent = `${index + 1}. ${player.player.name} ${player.player.surname}`;
        el.href = `/player/${player.player.playerId}/stats`;
        convertElementToRouterLink(el);
        el.addEventListener('mouseenter', () => this.currentPlayer.next(player));
        el.append(this.mkPlayerPoints(player.points));
        return el;
    }
    mkPlayerPoints(points) {
        const el = document.createElement('p');
        el.classList.add(css.points);
        el.textContent = String(points);
        return el;
    }
    mkPlayerPreviewPoints() {
        const points = document.createElement('p');
        this.pointsRef = points;
        points.classList.add(css.points, css.previewPoints);
        points.textContent = String(this.defaultPlayer.points);
        return points;
    }
}
