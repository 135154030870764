// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editorjs-component__host{display:block}.ce-block__content,.ce-toolbar__content,.cdx-block{max-width:100% !important}`, "",{"version":3,"sources":["webpack://./shared/components/editorjs/editorjs.component.scss"],"names":[],"mappings":"AAAA,0BACI,aAAA,CAIA,mDACI,yBAAA","sourcesContent":[".host {\n    display: block;\n}\n\n:global {\n    .ce-block__content, .ce-toolbar__content, .cdx-block {\n        max-width: 100% !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"host": `editorjs-component__host`
};
module.exports = ___CSS_LOADER_EXPORT___;
