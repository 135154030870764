import { CalendarComp } from './calendar-page.component';
import { WeeksCalendarComp } from '@Components/weeks-calendar/weeks-calendar.component';
import { DayMatchesSection } from './day-matches-section/day-matches-section.component';
import { LoaderComp } from '@Components/loader/loader.component';
export const CUSTOM_ELEMENTS_CONF = [
    { tag: 'app-calendar', comp: CalendarComp },
    { tag: 'app-weeks-calendar', comp: WeeksCalendarComp },
    { tag: 'app-day-matches-section', comp: DayMatchesSection },
    { tag: 'app-loader', comp: LoaderComp }
];
