export const TOURNAMENT_TYPES = ['round', 'playoff', 'group'];
export const TOURNAMENT_TYPES_LOCALS = {
    'round': 'Круговой',
    'playoff': 'Плей-офф',
    'group': 'Групповой'
};
export const TIMER_TYPES = ['forward', 'reverse'];
export const TIMER_TYPES_LOCALS = {
    'forward': 'Прямой',
    'reverse': 'Обратный'
};
export const PLAYTIME_TYPES = ['clean', 'dirty'];
export const PLAYTIME_TYPES_LOCALS = {
    'clean': 'Чистое',
    'dirty': 'Грязное'
};
