import { ORIGIN, PROTOCOL } from '@Constants/http.constants';
export class LeaderboardsHttp {
    static #instance;
    constructor() { }
    static get instance() {
        if (!LeaderboardsHttp.#instance)
            LeaderboardsHttp.#instance = new LeaderboardsHttp();
        return LeaderboardsHttp.#instance;
    }
    async getLeaderboards() {
        try {
            const res = await fetch(`${PROTOCOL}${ORIGIN}/api/leaderboards/getAll`, {
                method: 'GET'
            });
            if (!res.ok) {
                const parsedResponse = await res.json();
                throw new Error(parsedResponse.msg);
            }
            return res.json();
        }
        catch (err) {
            console.log('[HTTP] error during getLeaderboards', err);
            throw err;
        }
    }
}
