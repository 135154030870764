import css from './sponsors.component.scss';
import { Layout } from '@Services/layout.service';
export class SponsorsComp extends HTMLElement {
    logos;
    layoutService = Layout.instance;
    set sponsorsImgSetter(urls) {
        this.logos = urls;
    }
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
    }
    render() {
        this.append(...this.mkLogos());
        this.assignHostStyles();
    }
    mkLogos() {
        const logos = [];
        for (let logo of this.logos) {
            logos.push(this.mkLogo(logo));
        }
        return logos;
    }
    mkLogo(logo) {
        const img = document.createElement('img');
        img.src = logo;
        img.classList.add(css.logo);
        return img;
    }
    assignHostStyles() {
        if (this.layoutService.isMobile || this.layoutService.isTablet) {
            this.classList.add(css.host);
            return;
        }
        if (this.logos.length <= 2)
            this.classList.add(css.oneColumn);
        this.classList.add(css.host);
    }
}
