import { FIRST_SEASON_START_YEAR_MONTH_DAY, SEASON_END_MONTH_DAY, SEASON_START_MONTH_DAY } from '@Constants/season.constants';
export const getAllSeasons = () => {
    const lastSeasonDate = new Date(new Date().getFullYear() + 1, ...SEASON_END_MONTH_DAY);
    const seasonDate = new Date(...FIRST_SEASON_START_YEAR_MONTH_DAY);
    const seasons = [];
    while (seasonDate < lastSeasonDate) {
        seasons.push([new Date(seasonDate), getSeasonEnd(seasonDate)]);
        setDateToNextSeason(seasonDate);
    }
    return seasons;
};
const getSeasonEnd = (seasonDate) => {
    const result = new Date(seasonDate);
    result.setFullYear(seasonDate.getFullYear() + 1, ...SEASON_END_MONTH_DAY);
    return result;
};
const setDateToNextSeason = (seasonDate) => {
    seasonDate.setFullYear(seasonDate.getFullYear() + 1, ...SEASON_START_MONTH_DAY);
};
