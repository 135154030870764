import css from './photo-editor.component.scss';
export class PhotoEditorComp extends HTMLElement {
    photoInputRef;
    photoPreviewRef;
    uploadPhotoBytesSizeLimit = 350000;
    initialPhoto = null;
    internals;
    selectedFile;
    static formAssociated = true;
    get value() {
        return this.selectedFile;
    }
    set uploadPhotoBytesSizeLimitSetter(bytesLimit) {
        this.uploadPhotoBytesSizeLimit = bytesLimit;
    }
    set initialPhotoSetter(photoPath) {
        this.initialPhoto = photoPath;
        this.selectedFile = photoPath;
    }
    set controlNameSetter(name) {
        this.setAttribute('name', name);
    }
    constructor() {
        super();
        this.internals = this.attachInternals();
    }
    connectedCallback() {
        this.render();
        this.makeHostFocusable();
        this.updateValidity();
    }
    reportValidity() {
        if (!this.internals.checkValidity())
            this.internals.reportValidity();
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkPhotoEditorLabel(), this.mkPhotoInput(), this.mkPhotoPreview());
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkPhotoEditorLabel() {
        const label = document.createElement('label');
        label.textContent = 'Фотография*';
        return label;
    }
    mkPhotoInput() {
        const input = document.createElement('input');
        this.photoInputRef = input;
        input.classList.add(css.chooseFileBtn);
        input.type = 'file';
        input.accept = 'image/*';
        input.addEventListener('change', () => {
            this.updateValidity();
            this.updateValue();
            this.reportValidity();
            this.updatePhotoPreviewView();
        });
        return input;
    }
    mkPhotoPreview() {
        const preview = document.createElement('div');
        this.photoPreviewRef = preview;
        if (this.initialPhoto)
            preview.append(this.mkPhoto(this.initialPhoto));
        return preview;
    }
    updatePhotoPreviewView() {
        this.photoPreviewRef.replaceChildren(this.mkPhoto(URL.createObjectURL(this.photoInputRef.files[0])));
    }
    mkPhoto(photoPath) {
        const photo = document.createElement('img');
        photo.classList.add(css.photo);
        photo.src = photoPath;
        return photo;
    }
    updateValue() {
        const selectedFile = this.photoInputRef.files?.[0];
        if (!selectedFile) {
            if (this.initialPhoto) {
                this.internals.setFormValue(this.initialPhoto);
                this.selectedFile = this.initialPhoto;
                return;
            }
            this.internals.setFormValue('');
            this.selectedFile = '';
            return;
        }
        const validityState = this.internals.validity;
        if (validityState.valueMissing || validityState.customError) {
            this.internals.setFormValue('');
            this.selectedFile = '';
            return;
        }
        this.internals.setFormValue(selectedFile);
        this.selectedFile = selectedFile;
    }
    updateValidity() {
        const selectedFile = this.photoInputRef.files?.[0];
        if (selectedFile) {
            if (selectedFile.size > this.uploadPhotoBytesSizeLimit) {
                this.internals.setValidity({
                    customError: true
                }, `Размер файла превышает ${this.uploadPhotoBytesSizeLimit} байт`);
                return;
            }
            this.internals.setValidity({});
            return;
        }
        if (this.initialPhoto) {
            this.internals.setValidity({});
            return;
        }
        this.internals.setValidity({
            valueMissing: true
        }, 'Выберите фотографию');
    }
    makeHostFocusable() {
        this.tabIndex = 0;
    }
}
