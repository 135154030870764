import { ORIGIN, PROTOCOL } from '@Constants/http.constants';
export class NewsHttp {
    static #instance;
    constructor() { }
    static get instance() {
        if (!NewsHttp.#instance)
            NewsHttp.#instance = new NewsHttp();
        return NewsHttp.#instance;
    }
    async postNews(body) {
        try {
            const res = await fetch(`${PROTOCOL}${ORIGIN}/api/news/create`, {
                method: 'POST',
                body: body
            });
            if (!res.ok) {
                const parsedResponse = await res.json();
                throw new Error(parsedResponse.msg);
            }
            return res.json();
        }
        catch (err) {
            console.log('[HTTP] error during getData', err);
            throw err;
        }
    }
    async updateNews(newsId, body) {
        try {
            const res = await fetch(`${PROTOCOL}${ORIGIN}/api/news/update/${newsId}`, {
                method: 'PUT',
                body: body
            });
            if (!res.ok) {
                const parsedResponse = await res.json();
                throw new Error(parsedResponse.msg);
            }
        }
        catch (err) {
            console.log('[HTTP] error during updateNews', err);
            throw err;
        }
    }
    async getNewsByCategory(category) {
        try {
            const res = await fetch(`${PROTOCOL}${ORIGIN}/api/news/getByCategory/${category}`, {
                method: 'GET'
            });
            if (!res.ok) {
                const parsedResponse = await res.json();
                throw new Error(parsedResponse.msg);
            }
            return res.json();
        }
        catch (err) {
            console.log('[HTTP] error during getNewsByCategory', err);
            throw err;
        }
    }
    async getLast20ByCategory(category) {
        try {
            const res = await fetch(`${PROTOCOL}${ORIGIN}/api/news/getLast20ByCategory/${category}`, {
                method: 'GET'
            });
            if (!res.ok) {
                const parsedResponse = await res.json();
                throw new Error(parsedResponse.msg);
            }
            return res.json();
        }
        catch (err) {
            console.log('[HTTP] error during getLast20ByCategory', err);
            throw err;
        }
    }
    async getById(id) {
        try {
            const res = await fetch(`${PROTOCOL}${ORIGIN}/api/news/getById/${id}`, {
                method: 'GET'
            });
            if (!res.ok) {
                const parsedResponse = await res.json();
                throw new Error(parsedResponse.msg);
            }
            return res.json();
        }
        catch (err) {
            console.log('[HTTP] error during getById', err);
            throw err;
        }
    }
    async getNewsByRange(take, skip = 0) {
        try {
            const res = await fetch(`${PROTOCOL}${ORIGIN}/api/news/range?skip=${skip}&take=${take}`, {
                method: 'GET'
            });
            if (!res.ok) {
                const parsedResponse = await res.json();
                throw new Error(parsedResponse.msg);
            }
            return res.json();
        }
        catch (err) {
            console.log('[HTTP] error during getById', err);
            throw err;
        }
    }
}
