import { Days, MonthTranslates } from '../../enums/date.enum';
import css from './weeks-calendar.component.scss';
export class WeeksCalendarComp extends HTMLElement {
    date;
    defaultInputRef;
    set weeksCalendarSetter(monthStartISOString) {
        this.date = new Date(monthStartISOString);
    }
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
        this.dispatchDefaultValue();
    }
    render() {
        this.assignHostStyles();
        this.append(...this.mkWeeks());
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkWeeks() {
        const weeks = [];
        let weekStartDate = 1;
        for (let date = 1; date <= this.getLastDate(); date++) {
            this.date.setDate(date);
            if (this.date.getDay() === Days.Sunday) {
                weeks.push(this.mkWeek(weekStartDate, date, weekStartDate === 1, JSON.stringify({
                    startDate: this.genWeekStartDate(weekStartDate),
                    endDate: this.genWeekEndDate()
                })));
                weekStartDate = date + 1;
                continue;
            }
            if (date === this.getLastDate()) {
                weeks.push(this.mkWeek(weekStartDate, date, weekStartDate === 1, JSON.stringify({
                    startDate: this.genWeekStartDate(weekStartDate),
                    endDate: this.genWeekEndDate()
                })));
            }
        }
        return weeks;
    }
    getLastDate() {
        return new Date(this.date.getFullYear(), this.date.getMonth() + 1, 0, this.date.getHours()).getDate();
    }
    genWeekStartDate(date) {
        const startDate = new Date(this.date);
        startDate.setDate(date);
        startDate.setHours(0);
        startDate.setMinutes(0);
        return startDate.toISOString();
    }
    genWeekEndDate() {
        const endDate = new Date(this.date);
        endDate.setDate(this.date.getDate());
        endDate.setHours(23);
        endDate.setMinutes(59);
        return endDate.toISOString();
    }
    mkWeek(weekStart, weekEnd, checked, value) {
        const week = document.createElement('label');
        week.classList.add(css.week);
        week.append(this.mkInput(value, checked), this.mkTime(weekStart, weekEnd));
        return week;
    }
    mkTime(weekStart, weekEnd) {
        const time = document.createElement('p');
        time.classList.add(css.time);
        time.textContent = weekStart === weekEnd
            ? `${weekStart} ${MonthTranslates[this.date.getMonth()]}`
            : `${weekStart} - ${weekEnd} ${MonthTranslates[this.date.getMonth()]}`;
        return time;
    }
    mkInput(value, checked) {
        const el = document.createElement('input');
        el.classList.add(css.input);
        el.type = 'radio';
        el.name = 'weeks';
        el.value = value;
        el.checked = checked;
        el.required = true;
        if (checked)
            this.defaultInputRef = el;
        return el;
    }
    dispatchDefaultValue() {
        this.defaultInputRef.dispatchEvent(new Event('change', {
            bubbles: true
        }));
    }
}
