// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.team-page-lineup-component__host{display:block}.team-page-lineup-component__paddingContent{display:flex;padding:2rem 5vw;flex-direction:column;gap:1rem;min-height:70vh}.team-page-lineup-component__loaderCont{width:100%;height:300px;display:flex;justify-content:center;align-items:center}.team-page-lineup-component__lineup{display:grid;grid-template-columns:repeat(auto-fit, minmax(210px, 1fr));gap:1rem}`, "",{"version":3,"sources":["webpack://./team-page/team-page-lineup/team-page-lineup.component.scss"],"names":[],"mappings":"AAAA,kCACI,aAAA,CAGJ,4CACI,YAAA,CACA,gBAAA,CACA,qBAAA,CACA,QAAA,CACA,eAAA,CAGJ,wCACI,UAAA,CACA,YAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CAGJ,oCACI,YAAA,CACA,0DAAA,CACA,QAAA","sourcesContent":[".host {\n    display: block;\n}\n\n.paddingContent {\n    display: flex;\n    padding: 2rem 5vw;\n    flex-direction: column;\n    gap: 1rem;\n    min-height: 70vh;\n}\n\n.loaderCont {\n    width: 100%;\n    height: 300px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.lineup {\n    display: grid;\n    grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));\n    gap: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"host": `team-page-lineup-component__host`,
	"paddingContent": `team-page-lineup-component__paddingContent`,
	"loaderCont": `team-page-lineup-component__loaderCont`,
	"lineup": `team-page-lineup-component__lineup`
};
module.exports = ___CSS_LOADER_EXPORT___;
