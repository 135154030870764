import { ORIGIN, PROTOCOL } from '@Constants/http.constants';
export class MatchesHttp {
    static #instance;
    constructor() { }
    static get instance() {
        if (!MatchesHttp.#instance)
            MatchesHttp.#instance = new MatchesHttp();
        return MatchesHttp.#instance;
    }
    async getMatchesByDateAndTeam(startISO, endISO, teamId) {
        try {
            const res = await fetch(teamId
                ? `${PROTOCOL}${ORIGIN}/api/matches/getMatchesByDateAndTeam?start=${startISO}&end=${endISO}&teamId=${teamId}`
                : `${PROTOCOL}${ORIGIN}/api/matches/getMatchesByDateAndTeam?start=${startISO}&end=${endISO}`, {
                method: 'GET'
            });
            if (!res.ok) {
                const parsedResponse = await res.json();
                throw new Error(parsedResponse.msg);
            }
            return res.json();
        }
        catch (err) {
            console.log('[HTTP] error during getData', err);
            throw err;
        }
    }
    async getMatchById(matchId) {
        try {
            const res = await fetch(`${PROTOCOL}${ORIGIN}/api/matches/getMatchById/${matchId}`, {
                method: 'GET'
            });
            if (!res.ok) {
                const parsedResponse = await res.json();
                throw new Error(parsedResponse.msg);
            }
            return res.json();
        }
        catch (err) {
            console.log('[HTTP] error during getMatchById', err);
            throw err;
        }
    }
    async getMatchesComparisonStats(matchId) {
        try {
            const res = await fetch(`${PROTOCOL}${ORIGIN}/api/matches/getMatchesComparisonStats/${matchId}`, {
                method: 'GET'
            });
            if (!res.ok) {
                const parsedResponse = await res.json();
                throw new Error(parsedResponse.msg);
            }
            return res.json();
        }
        catch (err) {
            console.log('[HTTP] error during getMatchesComparisonStats', err);
            throw err;
        }
    }
    async getMatchProtocolById(matchId) {
        try {
            const res = await fetch(`${PROTOCOL}${ORIGIN}/api/matches/getMatchProtocolById/${matchId}`, {
                method: 'GET'
            });
            if (!res.ok) {
                const parsedResponse = await res.json();
                throw new Error(parsedResponse.msg);
            }
            return res.json();
        }
        catch (err) {
            console.log('[HTTP] error during getMatchProtocolById', err);
            throw err;
        }
    }
    async getPhotoGalleryByMatchId(matchId) {
        try {
            const res = await fetch(`${PROTOCOL}${ORIGIN}/api/matches/getPhotoesByMatch/${matchId}`, {
                method: 'GET'
            });
            if (!res.ok) {
                const parsedResponse = await res.json();
                throw new Error(parsedResponse.msg);
            }
            return res.json();
        }
        catch (err) {
            console.log('[HTTP] error during getPhotoGalleryByMatchId', err);
            throw err;
        }
    }
    async getMatchesByTournamentId(tournamentId) {
        try {
            const res = await fetch(`${PROTOCOL}${ORIGIN}/api/matches/getMatchesByTournamentId/${tournamentId}`, {
                method: 'GET'
            });
            if (!res.ok) {
                const parsedResponse = await res.json();
                throw new Error(parsedResponse.msg);
            }
            return res.json();
        }
        catch (err) {
            console.log('[HTTP] error during getPhotoGalleryByMatchId', err);
            throw err;
        }
    }
}
