// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avatar-input-component__avatar{width:2rem;height:2rem;border-radius:50%}.avatar-input-component__avatarCont{height:100%;display:flex;align-items:center}`, "",{"version":3,"sources":["webpack://./shared/components/cell-renderers/avatar-input/avatar-input.component.scss"],"names":[],"mappings":"AAAA,gCACI,UAAA,CACA,WAAA,CACA,iBAAA,CAGJ,oCACI,WAAA,CACA,YAAA,CACA,kBAAA","sourcesContent":[".avatar {\n    width: 2rem;\n    height: 2rem;\n    border-radius: 50%;\n}\n\n.avatarCont {\n    height: 100%;\n    display: flex;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avatar": `avatar-input-component__avatar`,
	"avatarCont": `avatar-input-component__avatarCont`
};
module.exports = ___CSS_LOADER_EXPORT___;
