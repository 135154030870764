import css from './footer.component.scss';
import shlLogo from '@Assets/logos/shl-default-logo-white.png';
import instagramIcon from '@Assets/icons/instagram-icon-v2.svg';
import vkIcon from '@Assets/icons/vk-icon-v2.svg';
import youtubeIcon from '@Assets/icons/youtube-icon-v2.svg';
export class FooterComp extends HTMLElement {
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkAddress(), this.mkGoTopBtn(), this.mkSeparator(), this.mkPoliciesList(), this.mkCopyright());
    }
    mkAddress() {
        const address = document.createElement('address');
        address.classList.add(css.address);
        address.append(this.mkShlLogo(), this.mkSocialMedias());
        return address;
    }
    mkShlLogo() {
        const logo = document.createElement('img');
        logo.classList.add(css.logo);
        logo.src = shlLogo;
        logo.alt = 'Логотип лиги';
        return logo;
    }
    mkSocialMedias() {
        const socialMediaEl = document.createElement('div');
        socialMediaEl.classList.add(css.mediaEl);
        socialMediaEl.append(this.mkYoutubeLink(), this.mkVkLink(), this.mkInstagramLink());
        return socialMediaEl;
    }
    mkMediaIcon(icon) {
        const mediaIcon = document.createElement('img');
        mediaIcon.src = icon;
        mediaIcon.classList.add(css.icon);
        return mediaIcon;
    }
    mkYoutubeLink() {
        const youtube = document.createElement('a');
        youtube.href = 'https://youtube.com/@shlmoscow9954';
        youtube.classList.add(css.socialMediaLink, css.youtubeLink);
        youtube.target = '_blank';
        youtube.append(this.mkMediaIcon(youtubeIcon));
        return youtube;
    }
    mkVkLink() {
        const vk = document.createElement('a');
        vk.href = 'https://vk.com/shl_moscow';
        vk.classList.add(css.socialMediaLink);
        vk.target = '_blank';
        vk.append(this.mkMediaIcon(vkIcon));
        return vk;
    }
    mkInstagramLink() {
        const instagram = document.createElement('a');
        instagram.href = 'https://instagram.com/shl_moscow';
        instagram.classList.add(css.socialMediaLink);
        instagram.target = '_blank';
        instagram.append(this.mkMediaIcon(instagramIcon));
        return instagram;
    }
    mkSeparator() {
        const separator = document.createElement('hr');
        separator.classList.add(css.separator);
        return separator;
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkGoTopBtn() {
        const goTopBtn = document.createElement('button');
        goTopBtn.classList.add(css.goTopBtn);
        goTopBtn.textContent = 'В начало';
        goTopBtn.addEventListener('click', () => this.smoothScrollTop());
        return goTopBtn;
    }
    smoothScrollTop() {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }
    mkPoliciesList() {
        const policiesList = document.createElement('ul');
        policiesList.classList.add(css.policiesList);
        policiesList.append(this.mkConfPolicy(), this.mkTermsOfServicePolicy(), this.mkTermsOfUsePolicy(), this.mkCookiePolicy());
        return policiesList;
    }
    mkConfPolicy() {
        const confPolicy = document.createElement('li');
        confPolicy.append(this.mkLink('Политики конфиденциальности', '#'));
        return confPolicy;
    }
    mkTermsOfServicePolicy() {
        const termsOfService = document.createElement('li');
        termsOfService.append(this.mkLink('Условия предоставления услуг', '#'));
        return termsOfService;
    }
    mkTermsOfUsePolicy() {
        const termsOfUse = document.createElement('li');
        termsOfUse.append(this.mkLink('Правила использования сайта', '#'));
        return termsOfUse;
    }
    mkCookiePolicy() {
        const cookie = document.createElement('li');
        cookie.append(this.mkLink('Политика кеширования и cookies', '#'));
        return cookie;
    }
    mkLink(text, link) {
        const el = document.createElement('a');
        el.textContent = text;
        el.href = link;
        el.target = '_blank';
        el.classList.add(css.policiesLink);
        return el;
    }
    mkCopyright() {
        const copyright = document.createElement('p');
        copyright.textContent = 'shlmos.ru является официальным сайтом SHL. Все логотипы команд, фотоизображения контента, присутствующего на данном сайте, тексты новостей, статистика команд, статистика игроков, статистика турниров, статистика дивизионов являются собственностью SHL. Запрещается их использование без письменного согласия SHL. Дизайн веб-приложения принадлежит SHL. Копирование элементов веб-приложения без письменного согласия запрещается. Юридический адрес: ул. Краснопресненский периулок, д.90';
        copyright.classList.add(css.copyright);
        return copyright;
    }
}
