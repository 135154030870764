// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.match-media-component__host{display:block}.match-media-component__paddingContent{display:flex;padding:2rem 5vw;flex-direction:column;gap:1rem;min-height:95vh}.match-media-component__gallery{display:flex;width:100%;gap:1rem}.match-media-component__galleryColumn{display:flex;flex-direction:column;gap:1rem;width:25%}.match-media-component__photo{width:100%;height:30vh;object-fit:cover;cursor:pointer}@media(max-width: 599px){.match-media-component__galleryColumn{width:50%}.match-media-component__photo{height:20vh}}@media(min-width: 600px)and (max-width: 960px){.match-media-component__galleryColumn{width:33%}.match-media-component__photo{height:20vh}}`, "",{"version":3,"sources":["webpack://./match-page/match-media/match-media.component.scss"],"names":[],"mappings":"AAAA,6BACI,aAAA,CAGJ,uCACI,YAAA,CACA,gBAAA,CACA,qBAAA,CACA,QAAA,CACA,eAAA,CAGJ,gCACI,YAAA,CACA,UAAA,CACA,QAAA,CAGJ,sCACI,YAAA,CACA,qBAAA,CACA,QAAA,CACA,SAAA,CAGJ,8BACI,UAAA,CACA,WAAA,CACA,gBAAA,CACA,cAAA,CAGJ,yBACI,sCACI,SAAA,CAGJ,8BACI,WAAA,CAAA,CAIR,+CACI,sCACI,SAAA,CAGJ,8BACI,WAAA,CAAA","sourcesContent":[".host {\n    display: block;\n}\n\n.paddingContent {\n    display: flex;\n    padding: 2rem 5vw;\n    flex-direction: column;\n    gap: 1rem;\n    min-height: 95vh;\n}\n\n.gallery {\n    display: flex;\n    width: 100%;\n    gap: 1rem;\n}\n\n.galleryColumn {\n    display: flex;\n    flex-direction: column;\n    gap: 1rem;\n    width: 25%;\n}\n\n.photo {\n    width: 100%;\n    height: 30vh;\n    object-fit: cover;\n    cursor: pointer;\n}\n\n@media (max-width: 599px) {\n    .galleryColumn {\n        width: 50%;\n    }\n\n    .photo {\n        height: 20vh;\n    }\n}\n\n@media (min-width: 600px) and (max-width: 960px) {\n    .galleryColumn {\n        width: 33%;\n    }\n\n    .photo {\n        height: 20vh;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"host": `match-media-component__host`,
	"paddingContent": `match-media-component__paddingContent`,
	"gallery": `match-media-component__gallery`,
	"galleryColumn": `match-media-component__galleryColumn`,
	"photo": `match-media-component__photo`
};
module.exports = ___CSS_LOADER_EXPORT___;
