// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.round-settings-component__host{display:block}.round-settings-component__settings{display:flex;gap:1rem;flex-wrap:wrap;flex-direction:column}`, "",{"version":3,"sources":["webpack://./admin-page/admin-tournaments/tournament-editor/settings/round/round-settings.component.scss"],"names":[],"mappings":"AAAA,gCACI,aAAA,CAGJ,oCACI,YAAA,CACA,QAAA,CACA,cAAA,CACA,qBAAA","sourcesContent":[".host {\n    display: block;\n}\n\n.settings {\n    display: flex;\n    gap: 1rem;\n    flex-wrap: wrap;\n    flex-direction: column;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"host": `round-settings-component__host`,
	"settings": `round-settings-component__settings`
};
module.exports = ___CSS_LOADER_EXPORT___;
