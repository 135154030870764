import css from './match-page.component.scss';
import { MATCH_ROUTES, Router } from './match-page.router';
import { MATCH_NAV_LINKS, paths } from './match-page-nav.conf';
import { convertElementToRouterLink } from '@Helpers/router.helper';
import { MatchesHttp } from '@Services/http/matches.http';
import { ORIGIN, PROTOCOL } from '@Constants/http.constants';
export class MatchPageComp extends HTMLElement {
    routerOutletRef;
    matchesHttp = MatchesHttp.instance;
    matchHeaderInfo;
    constructor() {
        super();
    }
    connectedCallback() {
        this.reqMatchHeaderInfo().then(matchHeaderInfo => {
            this.matchHeaderInfo = matchHeaderInfo;
            this.render();
            this.initRouting();
        });
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkHeader(), this.mkNav(), this.mkRouterOutlet(), this.mkFooter());
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkRouterOutlet() {
        const routerOutlet = document.createElement('div');
        this.routerOutletRef = routerOutlet;
        return routerOutlet;
    }
    mkFooter() {
        const footer = document.createElement('app-footer');
        return footer;
    }
    initRouting() {
        const matchRouter = new Router(this.routerOutletRef, MATCH_ROUTES);
        this.addEventListener('click', (e) => {
            const target = e.target;
            if (target.matches('[data-router-link]')) {
                e.preventDefault();
                const href = target.href.replace(PROTOCOL, '').replace(ORIGIN, '');
                if (matchRouter.hasRoute(href)) {
                    e.stopPropagation();
                    history.pushState(null, '', href);
                    matchRouter.generateOutlet();
                }
            }
        });
        matchRouter.generateOutlet();
    }
    mkNav() {
        const nav = document.createElement('ul');
        nav.classList.add(css.nav);
        MATCH_NAV_LINKS().forEach(navLink => nav.append(this.mkNavItem(navLink)));
        return nav;
    }
    mkNavItem(navLink) {
        const listItem = document.createElement('li');
        listItem.append(this.mkNavLink(navLink));
        return listItem;
    }
    mkNavLink(navLink) {
        const anchor = document.createElement('a');
        anchor.classList.add(css.navLink);
        anchor.textContent = navLink.name;
        anchor.href = navLink.path;
        convertElementToRouterLink(anchor);
        return anchor;
    }
    mkHeader() {
        const header = document.createElement('app-match-header');
        header.matchHeaderInfo = this.matchHeaderInfo;
        return header;
    }
    reqMatchHeaderInfo() {
        return this.matchesHttp.getMatchById(paths()[1]);
    }
}
