import { customDateFieldGuard, customNumberFieldGuard, customTextFieldGuard } from '@Types/player.interface';
import css from './players-table.component.scss';
import { PlayersHttp } from '@Services/http/players.http';
import { createGrid } from '@ag-grid-community/core';
import { columnDefs, defaultColDef } from './players-table.config';
import { customDateFieldValueSetter, customNumFieldValueSetter, customTextFieldValueSetter, dateEqualityCompator, dateValueFormatter, filterDateComparator, playerCustomFieldValueGetter } from '@Helpers/ag-grid.helper';
import { themeQuartz } from '@ag-grid-community/theming';
export class PlayersTableComp extends HTMLElement {
    template = {};
    gridApi;
    playersHttp = PlayersHttp.instance;
    gridOptions = {
        defaultColDef,
        columnDefs,
        theme: themeQuartz,
        loadThemeGoogleFonts: false,
        rowData: [],
        undoRedoCellEditing: true,
        undoRedoCellEditingLimit: 20,
        onCellValueChanged: (event) => this.onCellValueChanged(event)
    };
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkTable());
        this.reqAllPlayers();
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkTable() {
        const cont = document.createElement('div');
        cont.classList.add(css.table);
        this.template.gridCont = cont;
        return cont;
    }
    reqAllPlayers() {
        this.gridApi = createGrid(this.template.gridCont, this.gridOptions);
        this.playersHttp.getAllPlayers()
            .then(players => {
            if (!players?.length)
                return;
            this.gridApi.setGridOption('columnDefs', [...columnDefs, ...this.addCustomFieldCols(players[0].customFields)]);
            this.gridApi.setGridOption('rowData', players);
        });
    }
    addCustomFieldCols(customFields) {
        return customFields.map(customField => {
            const colDef = {};
            const isDateType = customField.type === 'date';
            const isNumberType = customField.type === 'number';
            colDef.cellEditor = isDateType ? 'agDateCellEditor'
                : isNumberType
                    ? 'agNumberCellEditor'
                    : 'agTextCellEditor';
            colDef.headerName = customField.name;
            colDef.valueGetter = playerCustomFieldValueGetter;
            if (customDateFieldGuard(customField))
                this.completeCustomDateFieldColDef(colDef);
            if (customTextFieldGuard(customField))
                this.completeCustomTextFieldColDef(colDef);
            if (customNumberFieldGuard(customField))
                this.completeCustomNumFieldColDef(colDef);
            return colDef;
        });
    }
    completeCustomDateFieldColDef(colDef) {
        colDef.valueFormatter = dateValueFormatter;
        colDef.valueSetter = customDateFieldValueSetter;
        colDef.filter = 'agDateColumnFilter';
        colDef.filterParams = {
            comparator: filterDateComparator
        };
        colDef.equals = dateEqualityCompator;
    }
    completeCustomTextFieldColDef(colDef) {
        colDef.valueSetter = customTextFieldValueSetter;
        colDef.cellEditor = 'agLargeTextCellEditor';
        colDef.cellEditorPopupPosition = 'under';
    }
    completeCustomNumFieldColDef(colDef) {
        colDef.valueSetter = customNumFieldValueSetter;
    }
    onCellValueChanged(event) {
        this.playersHttp.updatePlayer(event.data);
    }
}
