import { getDDMonthYYYY } from '@Helpers/date.helper';
import css from './news-preview.component.scss';
import { convertElementToRouterLink } from '@Helpers/router.helper';
export default class NewsPreviewComp extends HTMLElement {
    news;
    turnOffRouting = false;
    set turnOffRoutingSetter(turnOff) {
        this.turnOffRouting = turnOff;
    }
    set newsSetter(news) {
        this.news = news;
    }
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
    }
    render() {
        this.assingHostStyles();
        this.append(this.mkNewsLink());
    }
    mkNewsLink() {
        const link = document.createElement('a');
        link.classList.add(css.link);
        link.href = `/news-details/${this.news._id}`;
        convertElementToRouterLink(link);
        // do not route to details page from admin
        if (this.turnOffRouting)
            link.style.pointerEvents = 'none';
        link.append(this.mkNewsImage(), this.mkTextContent());
        return link;
    }
    mkNewsImage() {
        const newsImage = document.createElement('img');
        newsImage.classList.add(css.newsImage);
        newsImage.src = `/news-assets/${this.news.img}`;
        return newsImage;
    }
    mkTextContent() {
        const textContent = document.createElement('div');
        textContent.classList.add(css.textContent);
        textContent.append(this.mkHeadlineAndParagraphWrap());
        textContent.append(this.mkPostDate());
        return textContent;
    }
    mkHeadlineAndParagraphWrap() {
        const wrap = document.createElement('div');
        wrap.append(this.mkHeadline());
        wrap.append(this.mkPreviewDesc());
        return wrap;
    }
    mkHeadline() {
        const headline = document.createElement('h3');
        headline.classList.add(css.headLine);
        headline.textContent = this.news.title;
        return headline;
    }
    mkPreviewDesc() {
        const previewDesc = document.createElement('p');
        previewDesc.classList.add(css.previewDesc);
        previewDesc.textContent = this.news.lead;
        return previewDesc;
    }
    mkPostDate() {
        const postDate = document.createElement('time');
        postDate.classList.add(css.postDate);
        postDate.textContent = getDDMonthYYYY(this.news.postedDate);
        postDate.dateTime = this.news.postedDate;
        return postDate;
    }
    assingHostStyles() {
        this.classList.add(css.host);
    }
}
