import css from './team-confrontation.component.scss';
import { convertElementToRouterLink } from '@Helpers/router.helper';
export class TeamConfrontationCellRendererComp {
    gui;
    constructor() { }
    init(params) {
        if (!params.value)
            return;
        const cont = this.mkCont();
        cont.append(this.mkTeam1(params.value.team1), '✕', this.mkTeam2(params.value.team2));
        this.gui = cont;
    }
    getGui() {
        return this.gui;
    }
    refresh(_) {
        return false;
    }
    mkCont() {
        const cont = document.createElement('span');
        cont.classList.add(css.cont);
        return cont;
    }
    mkTeam1(team) {
        const teamCont = document.createElement('span');
        teamCont.classList.add(css.team);
        teamCont.append(this.mkTeamLink(team.name, team.teamId));
        if (team.logo)
            teamCont.append(this.mkTeamLogo(team.logo));
        return teamCont;
    }
    mkTeam2(team) {
        const teamCont = document.createElement('span');
        teamCont.classList.add(css.team);
        if (team.logo)
            teamCont.append(this.mkTeamLogo(team.logo));
        teamCont.append(this.mkTeamLink(team.name, team.teamId));
        return teamCont;
    }
    mkTeamLogo(logoPath) {
        const logo = document.createElement('img');
        logo.classList.add(css.logo);
        logo.src = `/teams-assets/${logoPath}`;
        return logo;
    }
    mkTeamLink(teamName, teamId) {
        const link = document.createElement('a');
        link.classList.add(css.link);
        link.textContent = teamName;
        link.href = `/team/${teamId}/stats`;
        convertElementToRouterLink(link);
        return link;
    }
}
