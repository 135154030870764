import { Subject } from 'rxjs';
import css from './tournament-card.component.scss';
import { convertElementToRouterLink } from '@Helpers/router.helper';
export class TournamentCardComp extends HTMLElement {
    tournament;
    activeTab = 'regular';
    tabChange = new Subject();
    subscriptions = [];
    strongestBattleTabRef;
    regularTabRef;
    set tournamentSetter(tournament) {
        this.tournament = tournament;
    }
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
    }
    disconnectedCallback() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkHeader(), this.mkContent());
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkHeader() {
        const header = document.createElement('div');
        header.classList.add(css.header);
        header.append(this.mkRegularBtn());
        if (this.tournament.strongestBattle)
            header.append(this.mkStrongestBattleBtn());
        return header;
    }
    mkRegularBtn() {
        const btn = document.createElement('button');
        this.regularTabRef = btn;
        btn.classList.add(css.headerBtn, css.activeTab);
        btn.textContent = 'Регулярный турнир';
        btn.addEventListener('click', () => {
            if (this.strongestBattleTabRef)
                this.strongestBattleTabRef.classList.remove(css.activeTab);
            btn.classList.add(css.activeTab);
            this.activeTab = 'regular';
            this.tabChange.next();
        });
        return btn;
    }
    mkStrongestBattleBtn() {
        const btn = document.createElement('button');
        this.strongestBattleTabRef = btn;
        btn.classList.add(css.headerBtn);
        btn.textContent = 'Битва сильнейших';
        btn.addEventListener('click', () => {
            this.regularTabRef.classList.remove(css.activeTab);
            btn.classList.add(css.activeTab);
            this.activeTab = 'strongestBattle';
            this.tabChange.next();
        });
        return btn;
    }
    mkContent() {
        const content = document.createElement('div');
        content.classList.add(css.content);
        content.append(this.mkName(), this.mkPlaces(), this.mkBg());
        const tabChangeSubscription = this.tabChange.subscribe(() => {
            content.replaceChildren(this.mkName(), this.mkPlaces(), this.mkBg());
        });
        this.subscriptions.push(tabChangeSubscription);
        content.addEventListener('click', () => {
            this.dispatchClick();
        });
        return content;
    }
    mkName() {
        const name = document.createElement('p');
        name.classList.add(css.name);
        name.textContent = this.tournament.name;
        return name;
    }
    mkPlaces() {
        const cont = document.createElement('ul');
        cont.classList.add(css.placesList);
        const firstPlaceTeam = this.tournament[this.activeTab]?.firstPlace;
        if (firstPlaceTeam) {
            cont.append(this.mkFisrtPlace(firstPlaceTeam.name, firstPlaceTeam.teamId));
        }
        const secondPlaceTeam = this.tournament[this.activeTab]?.secondPlace;
        if (secondPlaceTeam) {
            cont.append(this.mkSecondPlace(secondPlaceTeam.name, secondPlaceTeam.teamId));
        }
        const thirdPlaceTeam = this.tournament[this.activeTab]?.thirdPlace;
        if (thirdPlaceTeam) {
            cont.append(this.mkThirdPlace(thirdPlaceTeam.name, thirdPlaceTeam.teamId));
        }
        return cont;
    }
    mkFisrtPlace(teamName, teamId) {
        const li = document.createElement('li');
        li.classList.add(css.placesListItem);
        li.append(this.mkGoldCupIcon(), this.mkTeamLink(teamName, teamId));
        return li;
    }
    mkSecondPlace(teamName, teamId) {
        const li = document.createElement('li');
        li.classList.add(css.placesListItem);
        li.append(this.mkSilverCupIcon(), this.mkTeamLink(teamName, teamId));
        return li;
    }
    mkThirdPlace(teamName, teamId) {
        const li = document.createElement('li');
        li.classList.add(css.placesListItem);
        li.append(this.mkBronzeCupIcon(), this.mkTeamLink(teamName, teamId));
        return li;
    }
    mkGoldCupIcon() {
        const icon = document.createElement('span');
        icon.classList.add(css.cupIcon, css.goldCup);
        return icon;
    }
    mkSilverCupIcon() {
        const icon = document.createElement('span');
        icon.classList.add(css.cupIcon, css.silverCup);
        return icon;
    }
    mkBronzeCupIcon() {
        const icon = document.createElement('span');
        icon.classList.add(css.cupIcon, css.bronzeCup);
        return icon;
    }
    mkTeamLink(teamName, teamId) {
        const link = document.createElement('a');
        link.classList.add(css.teamLink);
        link.textContent = teamName;
        link.href = `/team/${teamId}/stats`;
        convertElementToRouterLink(link);
        return link;
    }
    mkBg() {
        const bg = document.createElement('div');
        bg.classList.add(css.bg);
        bg.append(this.mkBgPhoto(), this.mkBgGradient());
        return bg;
    }
    mkBgPhoto() {
        const photo = document.createElement('img');
        photo.classList.add(css.bgPhoto);
        const bgPhoto = this.tournament[this.activeTab]?.bgPhoto;
        if (bgPhoto)
            photo.src = `/tournaments-assets/backgrounds/${bgPhoto}`;
        return photo;
    }
    mkBgGradient() {
        const gradient = document.createElement('div');
        gradient.classList.add(css.bgGradient);
        return gradient;
    }
    dispatchClick() {
        this.dispatchEvent(new CustomEvent('cardClicked', {
            detail: {
                activeTab: this.activeTab
            }
        }));
    }
}
