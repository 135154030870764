import { Subject } from 'rxjs';
import css from './contacts-page.component.scss';
import { ContactsHttp } from '@Services/http/contacts.http';
export class ContactsPageComp extends HTMLElement {
    subscriptions = [];
    contactsSections = new Subject();
    contactsHttp = ContactsHttp.instance;
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
        this.reqContacts();
    }
    disconnectedCallback() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkPaddingContent(), this.mkFooter());
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkContacts() {
        const cont = document.createElement('div');
        cont.classList.add(css.contactsSections);
        this.subscriptions.push(this.contactsSections.subscribe(sections => {
            if (!sections)
                return;
            const frag = document.createDocumentFragment();
            sections.forEach(section => frag.append(this.mkContactsSection(section)));
            cont.append(frag);
        }));
        return cont;
    }
    mkContactsSection(section) {
        const contactsSection = document.createElement('app-contacts-section');
        contactsSection.contactsSetter = section;
        return contactsSection;
    }
    reqContacts() {
        this.contactsHttp.getContacts().then(contacts => this.contactsSections.next(contacts));
    }
    mkPaddingContent() {
        const paddingContent = document.createElement('div');
        paddingContent.classList.add(css.paddingContent);
        paddingContent.append(this.mkContacts());
        return paddingContent;
    }
    mkFooter() {
        const footer = document.createElement('app-footer');
        return footer;
    }
}
