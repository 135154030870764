import css from './contacts-section.component.scss';
import { DefaultSection } from '@Components/section/section.component';
import { getAltFromPath } from '@Helpers/images.helper';
import phoneIcon from '@Assets/icons/phone-icon.svg';
import mailIcon from '@Assets/icons/mail-icon.svg';
import telegramIcon from '@Assets/icons/telegram-icon.svg';
import youtubeIcon from '@Assets/icons/youtube-icon.svg';
import whatsappIcon from '@Assets/icons/whatsapp-icon.svg';
import instagramIcon from '@Assets/icons/instagram-icon.svg';
import vkIcon from '@Assets/icons/vk-icon.svg';
export default class ContactsSectionComp extends DefaultSection {
    сontactsFabricMethods = {
        phone: () => this.mkPhoneContact(),
        mail: () => this.mkMailContact(),
        telegram: () => this.mkTelegramContact(),
        youtube: () => this.mkYoutubeContact(),
        whatsapp: () => this.mkWhatsappContact(),
        instagram: () => this.mkInstagramContact(),
        vk: () => this.mkVkContact()
    };
    contactsSections;
    set contactsSetter(v) {
        this.contactsSections = v;
    }
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkTitle(this.contactsSections.title));
        this.append(this.mkHeaderUnderline());
        this.append(this.mkContactsList());
    }
    mkContactsList() {
        const contanctsList = document.createElement('ul');
        contanctsList.classList.add(css.contactsList);
        let contactType;
        for (contactType in this.contactsSections.contacts) {
            const listItem = document.createElement('li');
            listItem.append(this.сontactsFabricMethods[contactType]());
            contanctsList.append(listItem);
        }
        return contanctsList;
    }
    mkPhoneContact() {
        const phoneContact = document.createElement('a');
        phoneContact.classList.add(css.contact);
        phoneContact.href = 'tel:' + this.contactsSections.contacts.phone.replace(/[ +()-]/g, '').trim();
        phoneContact.target = '_blank';
        phoneContact.append(this.mkContactIcon(phoneIcon), this.contactsSections.contacts.phone);
        return phoneContact;
    }
    mkMailContact() {
        const mailContact = document.createElement('a');
        mailContact.classList.add(css.contact);
        mailContact.href = 'mailto:' + this.contactsSections.contacts.mail;
        mailContact.target = '_blank';
        mailContact.append(this.mkContactIcon(mailIcon), this.contactsSections.contacts.mail);
        return mailContact;
    }
    mkTelegramContact() {
        const telegramContact = document.createElement('a');
        telegramContact.classList.add(css.contact);
        telegramContact.href = 'https://t.me/' + this.contactsSections.contacts.telegram;
        telegramContact.target = '_blank';
        telegramContact.append(this.mkContactIcon(telegramIcon), this.contactsSections.contacts.telegram);
        return telegramContact;
    }
    mkYoutubeContact() {
        const youtubeContact = document.createElement('a');
        youtubeContact.classList.add(css.contact);
        youtubeContact.href = 'https://youtube.com/' + this.contactsSections.contacts.youtube;
        youtubeContact.target = '_blank';
        youtubeContact.append(this.mkContactIcon(youtubeIcon), 'SHL Moscow');
        return youtubeContact;
    }
    mkWhatsappContact() {
        const whatsappContact = document.createElement('a');
        whatsappContact.classList.add(css.contact);
        whatsappContact.href = 'https://wa.me/' + this.contactsSections.contacts.whatsapp.replace(/[ +()-]/g, '').trim();
        whatsappContact.target = '_blank';
        whatsappContact.append(this.mkContactIcon(whatsappIcon), this.contactsSections.contacts.whatsapp);
        return whatsappContact;
    }
    mkInstagramContact() {
        const instagramContact = document.createElement('a');
        instagramContact.classList.add(css.contact);
        instagramContact.href = 'https://instagram.com/' + this.contactsSections.contacts.instagram;
        instagramContact.target = '_blank';
        instagramContact.append(this.mkContactIcon(instagramIcon), this.contactsSections.contacts.instagram);
        return instagramContact;
    }
    mkVkContact() {
        const vkContact = document.createElement('a');
        vkContact.classList.add(css.contact);
        vkContact.href = 'https://vk.com/' + this.contactsSections.contacts.vk;
        vkContact.target = '_blank';
        vkContact.append(this.mkContactIcon(vkIcon), this.contactsSections.contacts.vk);
        return vkContact;
    }
    mkContactIcon(icon) {
        const contactIcon = document.createElement('img');
        contactIcon.classList.add(css.contactIcon);
        contactIcon.src = icon;
        contactIcon.alt = getAltFromPath(icon);
        return contactIcon;
    }
}
