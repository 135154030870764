import { isCompRoute } from '@Helpers/router.helper';
export class Router {
    host;
    routes;
    static NOT_FOUND_ROUTE = {
        path: /not found/,
        view: 'app-404'
    };
    constructor(host, routes) {
        this.host = host;
        this.routes = routes;
    }
    hasRoute(path) {
        return !!this.routes.find(route => route.path.test(path.replace(/^\/team\/[a-zA-Z0-9]{24}/, '')));
    }
    generateOutlet() {
        if (this.host.hasChildNodes())
            this.clearOutlet();
        const routeMatch = this.findMatch();
        if (isCompRoute(routeMatch)) {
            this.host.append(document.createElement(routeMatch.view));
            return;
        }
    }
    clearOutlet() {
        this.host.removeChild(this.host.firstChild);
    }
    findMatch() {
        return this.routes.find(route => route.path.test(location.pathname.replace(/^\/team\/[a-zA-Z0-9]{24}/, '')))
            || Router.NOT_FOUND_ROUTE;
    }
}
export const TEAM_ROUTES = [
    {
        path: /^\/stats\/?$/,
        view: 'app-team-page-stats'
    },
    {
        path: /^\/lineup\/?$/,
        view: 'app-team-lineup'
    }
];
