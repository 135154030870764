import { DefaultSection } from '@Components/section/section.component';
import css from './news-slider-section.component.scss';
import { NewsHttp } from '@Services/http/news.http';
import { Subject } from 'rxjs';
export class NewsSliderSectionComp extends DefaultSection {
    newsHttp = NewsHttp.instance;
    news = new Subject();
    subscriptions = [];
    newsCategory;
    heading;
    set headingSetter(heading) {
        this.heading = heading;
    }
    set newsCategorySetter(category) {
        this.newsCategory = category;
    }
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
        this.reqPLayersNews();
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkTitle(this.heading), this.mkHeaderUnderline(), this.mkNewsCont());
    }
    mkNewsCont() {
        const cont = document.createElement('div');
        cont.classList.add(css.newsCont);
        const newsSubscription = this.news.subscribe(news => {
            if (!news)
                return;
            if (news.length) {
                cont.replaceChildren(this.mkNewsSlider(this.mkNews(news)));
                return;
            }
            cont.replaceChildren(this.mkEmptyPlaceholder());
        });
        this.subscriptions.push(newsSubscription);
        cont.append(this.mkLoader());
        return cont;
    }
    mkLoader() {
        const loader = document.createElement('app-loader');
        return loader;
    }
    mkNews(news) {
        const result = [];
        news.forEach(n => result.push(this.mkNewsMiniPreview(n)));
        return result;
    }
    mkNewsSlider(sliderData) {
        const slider = document.createElement('app-slider');
        slider.classList.add(css.slider);
        slider.sliderSetter = sliderData;
        return slider;
    }
    mkNewsMiniPreview(news) {
        const newsMiniPreview = document.createElement('app-news-mini-preview');
        newsMiniPreview.classList.add(css.newsMiniPreview);
        newsMiniPreview.newsSetter = news;
        return newsMiniPreview;
    }
    mkEmptyPlaceholder() {
        const placeholder = document.createElement('div');
        placeholder.classList.add(css.placeholder);
        placeholder.textContent = 'Пусто';
        return placeholder;
    }
    reqPLayersNews() {
        this.newsHttp.getLast20ByCategory(this.newsCategory)
            .then(news => this.news.next(news));
    }
}
