import { DefaultSection } from '@Components/section/section.component';
import css from './matches-comparison-section.component.scss';
import { MatchesHttp } from '@Services/http/matches.http';
import { paths } from '../../match-page-nav.conf';
import { Subject } from 'rxjs';
import { removeChildren } from '@Helpers/dom-manipulation.helper';
export class MatchesComparisonSectionComp extends DefaultSection {
    matchesHttp = MatchesHttp.instance;
    stats = new Subject();
    subscriptions = [];
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
        this.reqMatchComparisonStats();
    }
    disconnectedCallback() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkTitle('Сравнение'), this.mkHeaderUnderline(), this.mkComparison());
    }
    mkComparison() {
        const comparisonCont = document.createElement('div');
        comparisonCont.classList.add(css.comparison);
        const statsSubscription = this.stats.subscribe(stats => {
            removeChildren(comparisonCont);
            if (!stats.length) {
                comparisonCont.append(this.mkEmptyPlaceholder());
                return;
            }
            comparisonCont.append(this.mkFirstTeamStats(stats[0].team1.teamStats), this.mkStatsNames(), this.mkTeamStats(stats[0].team2.teamStats));
        });
        this.subscriptions.push(statsSubscription);
        comparisonCont.append(this.mkLoader());
        return comparisonCont;
    }
    mkFirstTeamStats(teamStats) {
        const stats = this.mkTeamStats(teamStats);
        stats.classList.add(css.textAlignRight);
        return stats;
    }
    mkEmptyPlaceholder() {
        const placeholder = document.createElement('p');
        placeholder.classList.add(css.emptyPlaceholder);
        placeholder.textContent = 'Пусто';
        return placeholder;
    }
    mkStatsNames() {
        const list = document.createElement('ul');
        list.classList.add(css.namesList);
        list.append(this.mkNamesListItem('Броски'), this.mkNamesListItem('Броски в створ'), this.mkNamesListItem('Процент реализации'), this.mkNamesListItem('Шайбы в большинстве'), this.mkNamesListItem('Шайбы в меньшинстве'), this.mkNamesListItem('Штрафное время'), this.mkNamesListItem('Количество отраженных бросков'));
        return list;
    }
    mkLoader() {
        const loaderCont = document.createElement('div');
        loaderCont.classList.add(css.loaderCont);
        const loader = document.createElement('app-loader');
        loaderCont.append(loader);
        return loaderCont;
    }
    mkTeamStats(teamStats) {
        const list = document.createElement('ul');
        list.classList.add(css.teamStats);
        list.append(this.mkShots(teamStats), this.mkShotsOnGoal(teamStats), this.mkGoalPct(teamStats), this.mkPPGoals(teamStats), this.mkSHGoals(teamStats), this.mkPenaltyMinutes(teamStats), this.mkSaves(teamStats));
        return list;
    }
    mkStatsListItem(value) {
        const statsItem = document.createElement('li');
        statsItem.classList.add(css.statsItem);
        statsItem.textContent = String(value);
        return statsItem;
    }
    mkNamesListItem(name) {
        const namesItem = document.createElement('li');
        namesItem.classList.add(css.namesItem);
        namesItem.textContent = name;
        return namesItem;
    }
    mkShots(teamStats) {
        const shots = teamStats.shotsOnGoal + teamStats.shotsMisses + teamStats.shotsBlocked;
        return this.mkStatsListItem(shots);
    }
    mkShotsOnGoal(teamStats) {
        return this.mkStatsListItem(teamStats.shotsOnGoal);
    }
    mkGoalPct(teamStats) {
        const pct = Math.round(teamStats.goals /
            (teamStats.shotsOnGoal + teamStats.shotsMisses + teamStats.shotsBlocked) * 1000) / 10;
        return this.mkStatsListItem(pct);
    }
    mkPPGoals(teamStats) {
        return this.mkStatsListItem(teamStats.ppGoals);
    }
    mkSHGoals(teamStats) {
        return this.mkStatsListItem(teamStats.shGoals);
    }
    mkPenaltyMinutes(teamStats) {
        return this.mkStatsListItem(teamStats.penaltyMinutes);
    }
    mkSaves(teamStats) {
        return this.mkStatsListItem(teamStats.saves);
    }
    reqMatchComparisonStats() {
        this.matchesHttp.getMatchesComparisonStats(paths()[1])
            .then(stats => this.stats.next(stats))
            .catch();
    }
}
