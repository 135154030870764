import css from './stats-by-seasons-section.component.scss';
export const defaultColDef = {
    resizable: false,
    flex: 1
};
export const columnDefs = [
    {
        headerName: 'Сезон',
        field: 'seasonName',
        cellClass: css.seasonCell,
        flex: 4
    },
    {
        headerName: 'Г',
        headerTooltip: 'Голы',
        valueGetter: (params) => {
            if (!params.data?.playerStats.goals)
                return 0;
            return params.data.playerStats.goals;
        }
    },
    {
        headerName: 'ГМ',
        headerTooltip: 'Голы в меньшинстве',
        valueGetter: (params) => {
            if (!params.data?.playerStats.shGoals)
                return 0;
            return params.data.playerStats.shGoals;
        }
    },
    {
        headerName: 'ГБ',
        headerTooltip: 'Голы в большинстве',
        valueGetter: (params) => {
            return params.data.playerStats.shGoals;
        }
    },
    {
        headerName: '%БВ',
        headerTooltip: '% реализованных голов',
        valueGetter: (params) => {
            const shouts = params.data.playerStats.shotsOnGoal + params.data.playerStats.shotsBlocked
                + params.data.playerStats.shotsMisses;
            return Math.round(params.data.playerStats.goals / shouts * 1000) / 10 || 0;
        }
    },
    {
        headerName: 'Б',
        headerTooltip: 'Броски',
        valueGetter: (params) => {
            return params.data.playerStats.shotsOnGoal + params.data.playerStats.shotsBlocked
                + params.data.playerStats.shotsMisses;
        }
    },
    {
        headerName: 'БВ',
        headerTooltip: 'Броски в створ',
        valueGetter: (params) => {
            return params.data.playerStats.shotsOnGoal;
        }
    },
    {
        headerName: 'А',
        headerTooltip: 'Голевые передачи',
        valueGetter: (params) => {
            return params.data.playerStats.assists;
        }
    },
    {
        headerName: 'АО',
        headerTooltip: 'Острые передачи',
        valueGetter: (params) => {
            return params.data.playerStats.sharpPasses;
        }
    },
    {
        headerName: 'О',
        headerTooltip: 'Очки',
        valueGetter: (params) => {
            return params.data.playerStats.points;
        }
    },
    {
        headerName: 'ББ',
        headerTooltip: 'Блокированные броски',
        valueGetter: (params) => {
            return params.data.playerStats.shotsBlocked;
        }
    },
    {
        headerName: '+Вбр',
        headerTooltip: 'Выигранные вбрасывания',
        valueGetter: (params) => {
            return params.data.playerStats.faceoffWins;
        }
    },
    {
        headerName: '-Вбр',
        headerTooltip: 'Проигранные вбрасывания',
        valueGetter: (params) => {
            return params.data.playerStats.faceoffLosses;
        }
    },
    {
        headerName: '%Вбр',
        headerTooltip: '% выигранных вбрасывания',
        valueGetter: (params) => {
            return Math.round(params.data.playerStats.faceoffWins /
                (params.data.playerStats.faceoffWins + params.data.playerStats.faceoffLosses) * 1000) / 10 || 0;
        }
    },
    {
        headerName: 'Отб',
        headerTooltip: 'Отборы',
        valueGetter: (params) => {
            return params.data.playerStats.steals;
        }
    },
    {
        headerName: 'Птр',
        headerTooltip: 'Потери',
        valueGetter: (params) => {
            return params.data.playerStats.losses;
        }
    },
    {
        headerName: 'Ф',
        headerTooltip: 'Заработано фолов',
        valueGetter: (params) => {
            return params.data.playerStats.penaltiesDrawn;
        }
    },
    {
        headerName: 'Э',
        headerTooltip: 'Эффективность (Голы в меньшинстве * 8 + Голы в равных составах * 7 + Голы в большинстве * 6 + Броски в створ в меньшинстве * 4 + Броски в створ в равных составах * 3 + Броски в створ в большинстве * 2 + Броски мимо в меньшинстве * 2 + Броски мимо в равных составах + Выигр. вбрасыв. * 3 + Отборы в меньшинстве * 4 + Отборы в равных составах * 3 + Отборы в большинстве * 2 + Острые передачи в меньшинстве * 4 + Острые передачи в равных составах * 3 + Острые передачи в большинстве * 2 + Голевые передачи в меньшинстве * 7 + Голевые передачи в равных составах * 6 + Голевые передачи в большинстве * 5 - Проигр. вбрасыв. * 2 - Потери в меньшинстве - Потери в равных составах * 2 - Потери в большинстве * 3 - Малый штраф * 3 + Блокированный бросок * 4 + Заработанное удаление * 4)',
        valueGetter: (params) => {
            return params.data.playerStats.penaltiesDrawn;
        }
    }
];
