import css from './news-mini-preview.components.scss';
import { convertElementToRouterLink } from '@Helpers/router.helper';
export class NewsMiniPreviewComp extends HTMLElement {
    news;
    set newsSetter(news) {
        this.news = news;
    }
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkNewsLink());
    }
    mkPhoto() {
        const photo = document.createElement('img');
        photo.classList.add(css.photo);
        photo.src = `/news-assets/${this.news.img}`;
        return photo;
    }
    mkGradientPlate() {
        const plate = document.createElement('div');
        plate.classList.add(css.plate);
        plate.append(this.mkTitle());
        return plate;
    }
    mkTitle() {
        const title = document.createElement('p');
        title.textContent = this.news.title;
        title.classList.add(css.title);
        return title;
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkNewsLink() {
        const newsLink = document.createElement('a');
        newsLink.classList.add(css.link);
        newsLink.href = `/news-details/${this.news._id}`;
        convertElementToRouterLink(newsLink);
        newsLink.append(this.mkPhoto(), this.mkGradientPlate());
        return newsLink;
    }
}
