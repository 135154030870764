export const INDICATOR_NAME_LOCALS = {
    goals: 'Голы',
    evGoals: 'Голы в равной игре',
    ppGoals: 'Голы в больш.',
    shGoals: 'Голы в меньш.',
    assists: 'Ассисты',
    goalsAgainst: 'Пропущ. голы',
    points: 'Очки',
    saves: 'Сейвы',
    sharpPasses: 'Острые передачи',
    losses: 'Потери',
    efficiency: 'Средняя эфф-ть',
    faceoffPct: '% Выигранных вбр',
    steals: 'Отборы',
    shotsOnGoal: 'Броски в створ'
};
