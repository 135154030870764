// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-page-component__host{display:block}.about-page-component__paddingContent{display:flex;padding:2rem 5vw;flex-direction:column;gap:1rem;min-height:100vh}.about-page-component__arenas{display:flex;flex-direction:column;gap:1rem}`, "",{"version":3,"sources":["webpack://./about-page/about-page.component.scss"],"names":[],"mappings":"AAAA,4BACI,aAAA,CAGJ,sCACI,YAAA,CACA,gBAAA,CACA,qBAAA,CACA,QAAA,CACA,gBAAA,CAGJ,8BACI,YAAA,CACA,qBAAA,CACA,QAAA","sourcesContent":[".host {\n    display: block;\n}\n\n.paddingContent {\n    display: flex;\n    padding: 2rem 5vw;\n    flex-direction: column;\n    gap: 1rem;\n    min-height: 100vh;\n}\n\n.arenas {\n    display: flex;\n    flex-direction: column;\n    gap: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"host": `about-page-component__host`,
	"paddingContent": `about-page-component__paddingContent`,
	"arenas": `about-page-component__arenas`
};
module.exports = ___CSS_LOADER_EXPORT___;
