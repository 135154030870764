import { ORIGIN, PROTOCOL } from '@Constants/http.constants';
export class TournamentsHttp {
    static #instance;
    constructor() { }
    static get instance() {
        if (!TournamentsHttp.#instance)
            TournamentsHttp.#instance = new TournamentsHttp();
        return TournamentsHttp.#instance;
    }
    async getAll() {
        try {
            const res = await fetch(`${PROTOCOL}${ORIGIN}/api/tournaments/getAll`, {
                method: 'GET'
            });
            if (!res.ok) {
                const parsedResponse = await res.json();
                throw new Error(parsedResponse.msg);
            }
            return res.json();
        }
        catch (err) {
            console.log('[HTTP] error during getAll', err);
            throw err;
        }
    }
    async getAllBySeasons() {
        try {
            const res = await fetch(`${PROTOCOL}${ORIGIN}/api/tournaments/getAllBySeasons`, {
                method: 'GET'
            });
            if (!res.ok) {
                const parsedResponse = await res.json();
                throw new Error(parsedResponse.msg);
            }
            return res.json();
        }
        catch (err) {
            console.log('[HTTP] error during getAll', err);
            throw err;
        }
    }
    async getTournamentsBySeasonsAndStatus(status) {
        try {
            const res = await fetch(`${PROTOCOL}${ORIGIN}/api/tournaments/getAllBySeasonsAndStatus?status=${status}`, {
                method: 'GET'
            });
            if (!res.ok) {
                const parsedResponse = await res.json();
                throw new Error(parsedResponse.msg);
            }
            return res.json();
        }
        catch (err) {
            console.log('[HTTP] error during getTournamentsBySeasonsAndStatus', err);
            throw err;
        }
    }
    async updateTournamentGeneralSettings(tournamentId, formData) {
        try {
            const res = await fetch(`${PROTOCOL}${ORIGIN}/api/tournaments/general-settings/update/${tournamentId}`, {
                method: 'PUT',
                body: formData
            });
            if (!res.ok) {
                const parsedResponse = await res.json();
                throw new Error(parsedResponse.msg);
            }
        }
        catch (err) {
            console.log('[HTTP] error during updateTournamentGeneralSettings', err);
            throw err;
        }
    }
    async updateTournamentRegularRoundSettings(tournamentId, formData) {
        try {
            const res = await fetch(`${PROTOCOL}${ORIGIN}/api/tournaments/regular-settings/round/${tournamentId}`, {
                method: 'PUT',
                body: formData
            });
            if (!res.ok) {
                const parsedResponse = await res.json();
                throw new Error(parsedResponse.msg);
            }
        }
        catch (err) {
            console.log('[HTTP] error during updateTournamentRegularRoundSettings', err);
            throw err;
        }
    }
    async updateTournamentRegularPlayoffSettings(tournamentId, formData) {
        try {
            const res = await fetch(`${PROTOCOL}${ORIGIN}/api/tournaments/regular-settings/playoff/${tournamentId}`, {
                method: 'PUT',
                body: formData
            });
            if (!res.ok) {
                const parsedResponse = await res.json();
                throw new Error(parsedResponse.msg);
            }
        }
        catch (err) {
            console.log('[HTTP] error during updateTournamentRegularPlayoffSettings', err);
            throw err;
        }
    }
    async updateTournamentRegularGroupSettings(tournamentId, body) {
        try {
            const res = await fetch(`${PROTOCOL}${ORIGIN}/api/tournaments/regular-settings/group/${tournamentId}`, {
                method: 'PUT',
                body: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (!res.ok) {
                const parsedResponse = await res.json();
                throw new Error(parsedResponse.msg);
            }
        }
        catch (err) {
            console.log('[HTTP] error during updateTournamentRegularGroupSettings', err);
            throw err;
        }
    }
    async updateTournamentStrongestBattleRoundSettings(tournamentId, formData) {
        try {
            const res = await fetch(`${PROTOCOL}${ORIGIN}/api/tournaments/strongestBattle-settings/round/${tournamentId}`, {
                method: 'PUT',
                body: formData
            });
            if (!res.ok) {
                const parsedResponse = await res.json();
                throw new Error(parsedResponse.msg);
            }
        }
        catch (err) {
            console.log('[HTTP] error during updateTournamentStrongestBattleRoundSettings', err);
            throw err;
        }
    }
    async updateTournamentStrongestBattlePlayoffSettings(tournamentId, formData) {
        try {
            const res = await fetch(`${PROTOCOL}${ORIGIN}/api/tournaments/strongestBattle-settings/playoff/${tournamentId}`, {
                method: 'PUT',
                body: formData
            });
            if (!res.ok) {
                const parsedResponse = await res.json();
                throw new Error(parsedResponse.msg);
            }
        }
        catch (err) {
            console.log('[HTTP] error during updateTournamentStrongestBattlePlayoffSettings', err);
            throw err;
        }
    }
    async updateTournamentStrongestBattleGroupSettings(tournamentId, body) {
        try {
            const res = await fetch(`${PROTOCOL}${ORIGIN}/api/tournaments/strongestBattle-settings/group/${tournamentId}`, {
                method: 'PUT',
                body: JSON.stringify(body),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (!res.ok) {
                const parsedResponse = await res.json();
                throw new Error(parsedResponse.msg);
            }
        }
        catch (err) {
            console.log('[HTTP] error during updateTournamentStrongestBattleGroupSettings', err);
            throw err;
        }
    }
    async createTournament(body) {
        try {
            const res = await fetch(`${PROTOCOL}${ORIGIN}/api/tournaments/create`, {
                method: 'POST',
                body: body
            });
            if (!res.ok) {
                const parsedResponse = await res.json();
                throw new Error(parsedResponse.msg);
            }
        }
        catch (err) {
            console.log('[HTTP] error during createTournament', err);
            throw err;
        }
    }
}
