import css from './grid-no-data.component.scss';
export class GridNoDataComp {
    getGui() {
        return this.mkEmptyPlaceholder();
    }
    mkEmptyPlaceholder() {
        const placeholder = document.createElement('p');
        placeholder.classList.add(css.placeholder);
        placeholder.textContent = 'Пусто 😥';
        return placeholder;
    }
}
