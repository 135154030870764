import css from './admin-tournaments.component.scss';
import { Subject } from 'rxjs';
import { TournamentsHttp } from '@Services/http/tournaments.http';
export class AdminTournamentsComp extends HTMLElement {
    tournamentsHttp = TournamentsHttp.instance;
    subscriptions = [];
    tournamentsBySeasons = new Subject();
    seasonsContRef;
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
        this.reqTournaments('completed');
    }
    disconnectedCallback() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkHeader(), this.mkSeasons());
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkHeader() {
        const header = document.createElement('div');
        header.classList.add(css.header);
        header.append(this.mkTournamentsStatusSelector(), this.mkAddTournamentBtn());
        return header;
    }
    mkAddTournamentBtn() {
        const button = document.createElement('button');
        button.classList.add(css.addTournamentBtn);
        button.addEventListener('click', () => this.openTournamentCreator());
        button.append(this.mkAddIcon());
        return button;
    }
    mkAddIcon() {
        const icon = document.createElement('img');
        icon.classList.add(css.addIcon);
        return icon;
    }
    mkTournamentsStatusSelector() {
        const selector = document.createElement('select');
        selector.classList.add(css.selector);
        selector.append(this.mkSelectorOption('Незавершеные', 'incompleted'), this.mkSelectorOption('Завершеные', 'completed', true));
        selector.addEventListener('change', () => {
            this.startLoading();
            this.reqTournaments(selector.value);
        });
        return selector;
    }
    mkSelectorOption(text, value, selected = false) {
        const option = document.createElement('option');
        option.textContent = text;
        option.value = value;
        option.selected = selected;
        return option;
    }
    mkSeasons() {
        const cont = document.createElement('div');
        this.seasonsContRef = cont;
        cont.classList.add(css.seasonsCont);
        const tournamentsSubscription = this.tournamentsBySeasons.subscribe(tournamentsBySeasons => {
            if (!tournamentsBySeasons.length) {
                cont.replaceChildren(this.mkEmptyPlaceholder());
                return;
            }
            const seasons = [];
            tournamentsBySeasons.forEach(tournamentsBySeason => {
                seasons.push(this.mkSeason(tournamentsBySeason));
            });
            cont.replaceChildren(...seasons);
        });
        this.subscriptions.push(tournamentsSubscription);
        cont.append(this.mkLoader());
        return cont;
    }
    mkSeason(seasonTournaments) {
        const season = document.createElement('section');
        season.append(this.mkSeasonHeading(seasonTournaments.seasonName), this.mkTournaments(seasonTournaments.tournaments));
        return season;
    }
    mkTournaments(tournaments) {
        const cont = document.createElement('div');
        cont.classList.add(css.tournamentsCont);
        tournaments.forEach(tournament => {
            cont.append(this.mkTournamentCard(tournament));
        });
        return cont;
    }
    mkSeasonHeading(seasonName) {
        const heading = document.createElement('h3');
        heading.classList.add(css.seasonHeading);
        heading.textContent = seasonName;
        return heading;
    }
    mkTournamentCard(tournament) {
        const tournamentCard = document.createElement('app-tournament-card');
        tournamentCard.classList.add(css.tournamentCard);
        tournamentCard.tournamentSetter = {
            ...tournament,
            tournamentId: tournament._id
        };
        tournamentCard.addEventListener('cardClicked', ((e) => {
            this.openTournamentEditor(tournament, e.detail.activeTab);
        }));
        return tournamentCard;
    }
    reqTournaments(status) {
        this.tournamentsHttp.getTournamentsBySeasonsAndStatus(status)
            .then(tournaments => this.tournamentsBySeasons.next(tournaments))
            .catch();
    }
    mkLoader() {
        const loaderCont = document.createElement('div');
        loaderCont.classList.add(css.loaderCont);
        const loader = document.createElement('app-loader');
        loaderCont.append(loader);
        return loaderCont;
    }
    startLoading() {
        this.seasonsContRef.replaceChildren(this.mkLoader());
    }
    mkEmptyPlaceholder() {
        const placeholder = document.createElement('div');
        placeholder.classList.add(css.emptyPlaceholder);
        placeholder.textContent = 'Пусто';
        return placeholder;
    }
    openTournamentEditor(tournament, tournamentStatsType) {
        const editor = this.mkTournamentEditor();
        editor.tournamentSetter = tournament;
        editor.tournamentStatsTypeSetter = tournamentStatsType;
        this.append(editor);
        setTimeout(() => editor.classList.add('isOpened'));
    }
    openTournamentCreator() {
        const creator = this.mkTournamentCreator();
        this.append(creator);
        setTimeout(() => creator.classList.add('isOpened'));
    }
    mkTournamentEditor() {
        const editor = document.createElement('app-tournament-editor');
        return editor;
    }
    mkTournamentCreator() {
        const creator = document.createElement('app-tournament-creator');
        return creator;
    }
}
