import { ORIGIN, PROTOCOL } from '@Constants/http.constants';
export class StatisticsHttp {
    static #instance;
    constructor() { }
    static get instance() {
        if (!StatisticsHttp.#instance)
            StatisticsHttp.#instance = new StatisticsHttp();
        return StatisticsHttp.#instance;
    }
    async getStatsByFilters(params) {
        try {
            let queries = [];
            let prop;
            for (prop in params) {
                if (params.hasOwnProperty(prop))
                    queries.push(`${prop}=${params[prop]}`);
            }
            const res = await fetch(queries.length
                ? `${PROTOCOL}${ORIGIN}/api/statistics/getByFilters?${queries.join('&')}`
                : `${PROTOCOL}${ORIGIN}/api/statistics/getByFilters`, {
                method: 'GET'
            });
            if (!res.ok) {
                const parsedResponse = await res.json();
                throw new Error(parsedResponse.msg);
            }
            return res.json();
        }
        catch (err) {
            console.log('[HTTP] error during getStatsByFilters', err);
            throw err;
        }
    }
}
