import { Subject } from 'rxjs';
import css from './about-page.component.scss';
import { AboutHttp } from '@Services/http/about.http';
export class AboutPageComp extends HTMLElement {
    subscriptions = [];
    aboutHttp = AboutHttp.instance;
    aboutData = new Subject();
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
        this.reqAboutInfo();
    }
    disconnectedCallback() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkPaddingContent(), this.mkFooter());
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    reqAboutInfo() {
        this.aboutHttp.getData().then(data => this.aboutData.next(data));
    }
    mkAboutDesc() {
        const frag = document.createElement('div');
        this.subscriptions.push(this.aboutData.subscribe(data => {
            if (!data)
                return;
            const aboutDesc = document.createElement('app-about-desc');
            aboutDesc.dataSetter = data.desc;
            frag.append(aboutDesc);
        }));
        return frag;
    }
    mkArenas() {
        const arenas = document.createElement('div');
        arenas.classList.add(css.arenas);
        this.aboutData.subscribe(data => data?.arenas.forEach(arena => arenas.append(this.mkArena(arena))));
        return arenas;
    }
    mkArena(arena) {
        const arenaSection = document.createElement('app-arena-section');
        arenaSection.dataSetter = arena;
        return arenaSection;
    }
    mkFooter() {
        const footer = document.createElement('app-footer');
        return footer;
    }
    mkPaddingContent() {
        const paddingContent = document.createElement('div');
        paddingContent.classList.add(css.paddingContent);
        paddingContent.append(this.mkAboutDesc(), this.mkArenas());
        return paddingContent;
    }
}
