import { isPlayoffTournamentStatsGuard, isRoundTournamentStatsGuard } from '@Types/tournament.interface';
import css from './tournament-settings.component.scss';
import { TournamentsHttp } from '@Services/http/tournaments.http';
import { forkJoin, Subject } from 'rxjs';
export class TournamentSettingsComp extends HTMLElement {
    tournament;
    tournamentType;
    tournamentsHttp = TournamentsHttp.instance;
    generalSettingsUpdated = new Subject();
    advancedSettingsUpdated = new Subject();
    settingsUpdated = forkJoin([this.generalSettingsUpdated, this.advancedSettingsUpdated]);
    generalSettings;
    generalSettingsFormRef;
    advancedSettingsFormRef;
    set tournamentSetter(tournament) {
        this.tournament = tournament;
    }
    set tournamentTypeSetter(tournamentType) {
        this.tournamentType = tournamentType;
    }
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkGeneralSettingsForm(), this.mkHeading(), this.mkAdvancedSettingsForm(), this.mkSaveBtn());
    }
    mkGeneralSettingsForm() {
        const form = document.createElement('form');
        this.generalSettingsFormRef = form;
        form.classList.add(css.generalSettingsForm);
        form.append(this.mkGeneralSettings());
        form.addEventListener('submit', e => {
            e.preventDefault();
            if (this.tournament) {
                this.updateTournamentGeneralSettings(new FormData(form));
            }
            else {
                this.generalSettings = this.genGeneralSettings(new FormData(form));
                this.generalSettingsUpdated.next();
            }
        });
        return form;
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkAdvancedSettingsForm() {
        const form = document.createElement('form');
        this.advancedSettingsFormRef = form;
        form.classList.add(css.advancedSettingsForm);
        form.append(this.mkPhotoEditor(), this.mkAdvancedSettings());
        form.addEventListener('submit', e => {
            e.preventDefault();
            if (!this.tournament) {
                const formData = new FormData(form);
                this.createTournament(this.genTournament(formData), form.bgPhoto.value);
                return;
            }
            if (this.tournamentType === 'regular') {
                this.updateTournamentRegularSettings(new FormData(form));
            }
            else {
                this.updateTournamentStrongestBattleSettings(new FormData(form));
            }
        });
        return form;
    }
    createTournament(tournament, bgPhoto) {
        const formData = new FormData;
        formData.append('tournament', JSON.stringify(tournament));
        formData.append('bgPhoto', bgPhoto);
        this.tournamentsHttp.createTournament(formData)
            .then(() => {
            this.advancedSettingsUpdated.next();
            this.advancedSettingsUpdated.complete();
            this.generalSettingsUpdated.complete();
        })
            .catch();
    }
    genTournament(formData) {
        const advancedSettings = this.genAdvancedSettings(formData);
        const { bgPhoto, type, ...regularSettings } = advancedSettings;
        const { date, ...generalSettings } = this.generalSettings;
        const regularStats = type === 'round'
            ? {
                firstPlace: null,
                secondPlace: null,
                thirdPlace: null,
                bgPhoto: bgPhoto.name,
                logo: '',
                type: 'round',
                overallStanding: [],
                settings: regularSettings
            }
            : type === 'playoff'
                ? {
                    firstPlace: null,
                    secondPlace: null,
                    thirdPlace: null,
                    bgPhoto: bgPhoto.name,
                    logo: '',
                    type: 'playoff',
                    stages: [],
                    settings: regularSettings
                }
                : {
                    firstPlace: null,
                    secondPlace: null,
                    thirdPlace: null,
                    bgPhoto: bgPhoto.name,
                    logo: '',
                    type: 'group',
                    groups: this.getGroupsFromFormData(formData).map(group => {
                        return group.type === 'round'
                            ? {
                                ...group,
                                overallStanding: []
                            }
                            : {
                                ...group,
                                stages: []
                            };
                    })
                };
        return {
            ...generalSettings,
            date: new Date(date).toISOString(),
            completed: false,
            regular: regularStats,
            strongestBattle: null
        };
    }
    mkGeneralSettings() {
        const settings = document.createElement('app-tournament-general-settings');
        settings.tournamentSetter = this.tournament;
        return settings;
    }
    updateTournamentGeneralSettings(formData) {
        this.tournamentsHttp.updateTournamentGeneralSettings(this.tournament._id, formData)
            .then(() => {
            this.generalSettingsUpdated.next();
        })
            .catch();
    }
    genGeneralSettings(formData) {
        const result = {};
        for (const [key, value] of formData) {
            result[key] = value;
        }
        return result;
    }
    genAdvancedSettings(formData) {
        const result = {};
        for (const [key, value] of formData) {
            result[key] = value;
        }
        return result;
    }
    mkAdvancedSettings() {
        return this.tournamentType === 'regular'
            ? this.mkRegularSettings()
            : this.mkStrongestBattleSettings();
    }
    mkRegularSettings() {
        const settings = document.createElement('app-tournament-regular-settings');
        settings.tournamentStatsSetter = this.tournament?.regular;
        return settings;
    }
    mkStrongestBattleSettings() {
        const settings = document.createElement('app-tournament-strongest-battle-settings');
        settings.tournamentStatsSetter = this.tournament.strongestBattle;
        return settings;
    }
    mkHeading() {
        const heading = document.createElement('h4');
        heading.classList.add(css.heading);
        heading.textContent = this.tournamentType === 'regular'
            ? 'Настройки регулярного турнира'
            : 'Настройки битвы сильнейших';
        return heading;
    }
    mkPhotoEditor() {
        const editor = document.createElement('app-photo-editor');
        editor.controlNameSetter = 'bgPhoto';
        const tournamentStats = this.tournament?.[this.tournamentType];
        if (tournamentStats)
            editor.initialPhotoSetter = `/tournaments-assets/backgrounds/${tournamentStats.bgPhoto}`;
        return editor;
    }
    mkSaveBtn() {
        const btn = document.createElement('button');
        btn.classList.add(css.saveBtn);
        btn.textContent = 'Сохранить';
        this.generalSettingsUpdated.subscribe(() => this.advancedSettingsFormRef.requestSubmit());
        btn.addEventListener('click', () => {
            this.generalSettingsFormRef.requestSubmit();
        });
        this.settingsUpdated.subscribe(() => location.reload());
        return btn;
    }
    updateTournamentRegularSettings(formData) {
        if (isRoundTournamentStatsGuard(this.tournament.regular)) {
            this.updateTournamentRegularRoundSettings(formData);
        }
        else if (isPlayoffTournamentStatsGuard(this.tournament.regular)) {
            this.updateTournamentRegularPlayoffSettings(formData);
        }
        else {
            this.updateTournamentRegularGroupSettings(formData);
        }
    }
    updateTournamentRegularRoundSettings(formData) {
        this.tournamentsHttp.updateTournamentRegularRoundSettings(this.tournament._id, formData)
            .then(() => {
            this.advancedSettingsUpdated.next();
            this.advancedSettingsUpdated.complete();
            this.generalSettingsUpdated.complete();
        })
            .catch();
    }
    updateTournamentRegularPlayoffSettings(formData) {
        this.tournamentsHttp.updateTournamentRegularPlayoffSettings(this.tournament._id, formData)
            .then(() => {
            this.advancedSettingsUpdated.next();
            this.advancedSettingsUpdated.complete();
            this.generalSettingsUpdated.complete();
        })
            .catch();
    }
    updateTournamentRegularGroupSettings(formData) {
        this.tournamentsHttp.updateTournamentRegularGroupSettings(this.tournament._id, {
            type: formData.get('type'),
            groups: this.getGroupsFromFormData(formData)
        })
            .then(() => {
            this.advancedSettingsUpdated.next();
            this.advancedSettingsUpdated.complete();
            this.generalSettingsUpdated.complete();
        })
            .catch();
    }
    updateTournamentStrongestBattleSettings(formData) {
        if (isRoundTournamentStatsGuard(this.tournament.strongestBattle)) {
            this.updateTournamentStrongestBattleRoundSettings(formData);
        }
        else if (isPlayoffTournamentStatsGuard(this.tournament.strongestBattle)) {
            this.updateTournamentStrongestBattlePlayoffSettings(formData);
        }
        else {
            this.updateTournamentStrongestBattleGroupSettings(formData);
        }
    }
    updateTournamentStrongestBattleRoundSettings(formData) {
        this.tournamentsHttp.updateTournamentStrongestBattleRoundSettings(this.tournament._id, formData)
            .then(() => {
            this.advancedSettingsUpdated.next();
            this.advancedSettingsUpdated.complete();
            this.generalSettingsUpdated.complete();
        })
            .catch();
    }
    updateTournamentStrongestBattlePlayoffSettings(formData) {
        this.tournamentsHttp.updateTournamentStrongestBattlePlayoffSettings(this.tournament._id, formData)
            .then(() => {
            this.advancedSettingsUpdated.next();
            this.advancedSettingsUpdated.complete();
            this.generalSettingsUpdated.complete();
        })
            .catch();
    }
    updateTournamentStrongestBattleGroupSettings(formData) {
        this.tournamentsHttp.updateTournamentStrongestBattleGroupSettings(this.tournament._id, {
            type: formData.get('type'),
            groups: this.getGroupsFromFormData(formData)
        })
            .then(() => {
            this.advancedSettingsUpdated.next();
            this.advancedSettingsUpdated.complete();
            this.generalSettingsUpdated.complete();
        })
            .catch();
    }
    getGroupsFromFormData(formData) {
        const result = [];
        const groups = {};
        for (const [key, value] of formData) {
            const [groupName, settingsName] = key.split('_');
            if (settingsName === undefined)
                continue;
            if (groups.hasOwnProperty(groupName)) {
                if (settingsName === 'type') {
                    groups[groupName].type = value;
                    continue;
                }
                groups[groupName].settings[settingsName] = value;
                continue;
            }
            if (settingsName === 'type') {
                groups[groupName] = {
                    name: groupName,
                    type: value,
                    settings: {}
                };
                continue;
            }
            groups[groupName] = {
                name: groupName,
                settings: {
                    [settingsName]: value
                }
            };
        }
        for (const prop in groups) {
            if (groups.hasOwnProperty(prop))
                result.push(groups[prop]);
        }
        return result;
    }
}
