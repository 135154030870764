import { ORIGIN, PROTOCOL } from '@Constants/http.constants';
export class TeamsHttp {
    static #instance;
    constructor() { }
    static get instance() {
        if (!TeamsHttp.#instance)
            TeamsHttp.#instance = new TeamsHttp();
        return TeamsHttp.#instance;
    }
    async getCalendarTeams() {
        try {
            const res = await fetch(`${PROTOCOL}${ORIGIN}/api/teams/getCalendarTeams`, {
                method: 'GET'
            });
            if (!res.ok) {
                const parsedResponse = await res.json();
                throw new Error(parsedResponse.msg);
            }
            return res.json();
        }
        catch (err) {
            console.log('[HTTP] error during getCalendarTeams', err);
            throw err;
        }
    }
    async getTeamById(teamId) {
        try {
            const res = await fetch(`${PROTOCOL}${ORIGIN}/api/teams/getTeamById/${teamId}`, {
                method: 'GET'
            });
            if (!res.ok) {
                const parsedResponse = await res.json();
                throw new Error(parsedResponse.msg);
            }
            return res.json();
        }
        catch (err) {
            console.log('[HTTP] error during getTeamById', err);
            throw err;
        }
    }
    async getTeamIndicatorsById(teamId) {
        try {
            const res = await fetch(`${PROTOCOL}${ORIGIN}/api/teams/getTeamIndicatorsById/${teamId}`, {
                method: 'GET'
            });
            if (!res.ok) {
                const parsedResponse = await res.json();
                throw new Error(parsedResponse.msg);
            }
            return res.json();
        }
        catch (err) {
            console.log('[HTTP] error during getTeamIndicatorsById', err);
            throw err;
        }
    }
    async getTeamPlayersStatsById(teamId) {
        try {
            const res = await fetch(`${PROTOCOL}${ORIGIN}/api/teams/getTeamPlayersStatsById/${teamId}`, {
                method: 'GET'
            });
            if (!res.ok) {
                const parsedResponse = await res.json();
                throw new Error(parsedResponse.msg);
            }
            return res.json();
        }
        catch (err) {
            console.log('[HTTP] error during getTeamPlayersStatsById', err);
            throw err;
        }
    }
}
