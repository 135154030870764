// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.documents-page-component__host{display:block;padding:2rem 5vw}.documents-page-component__documentsSections{display:flex;flex-direction:column;gap:1rem}`, "",{"version":3,"sources":["webpack://./documents-page/documents-page.component.scss"],"names":[],"mappings":"AAAA,gCACI,aAAA,CACA,gBAAA,CAGJ,6CACI,YAAA,CACA,qBAAA,CACA,QAAA","sourcesContent":[".host {\n    display: block;\n    padding: 2rem 5vw;\n}\n\n.documentsSections {\n    display: flex;\n    flex-direction: column;\n    gap: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"host": `documents-page-component__host`,
	"documentsSections": `documents-page-component__documentsSections`
};
module.exports = ___CSS_LOADER_EXPORT___;
