const locationAdminPrefix = '/admin';
export const ADMIN_NAV_LINKS = [
    {
        path: `${locationAdminPrefix}/players`,
        name: 'Игроки'
    },
    {
        path: `${locationAdminPrefix}/documents`,
        name: 'Документы'
    },
    {
        path: `${locationAdminPrefix}/news`,
        name: 'Новости'
    },
    {
        path: `${locationAdminPrefix}/tournaments`,
        name: 'Турниры'
    }
];
