export const paths = () => location.pathname.split('/').filter(path => !!path);
export const pPagePrefix = () => `/${paths()[0]}/${paths()[1]}`;
export const MATCH_NAV_LINKS = () => [
    {
        name: 'Общее',
        path: `${pPagePrefix()}/general`
    },
    {
        name: 'Протокол',
        path: `${pPagePrefix()}/protocol`
    },
    {
        name: 'Ход игры',
        path: `${pPagePrefix()}/chronology`
    },
    {
        name: 'Медиа',
        path: `${pPagePrefix()}/media`
    }
];
