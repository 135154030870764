import css from './admin-players.component.scss';
export class AdminPlayersComp extends HTMLElement {
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkPlayersTable());
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkPlayersTable() {
        const table = document.createElement('app-admin-players-table');
        return table;
    }
}
