import { DefaultSection } from '@Components/section/section.component';
import css from './news-feed-section.component.scss';
import { NewsHttp } from '@Services/http/news.http';
import { Subject, take } from 'rxjs';
import { NEWS_FEED_PAGINATION_CHUNK } from '@Constants/pagination.constants';
export class NewsFeedSectionComp extends DefaultSection {
    newsHttp = NewsHttp.instance;
    news = new Subject();
    newsLoading = new Subject();
    subscriptions = [];
    take = NEWS_FEED_PAGINATION_CHUNK;
    skip = 0;
    lastNewsRef;
    viewPortObsRef;
    newsListRef;
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
        this.reqNewsByRange();
        this.observeViewPort();
    }
    disconnectedCallback() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkTitle('Последнее в SHL'), this.mkHeaderUnderline(), this.mkNewsFeed());
    }
    mkNewsFeed() {
        const section = document.createElement('section');
        section.classList.add(css.newsFeed);
        const newsSubscription = this.news.pipe(take(1)).subscribe(news => {
            if (!news.length) {
                section.replaceChildren(this.mkEmptyPlaceholder());
                return;
            }
            section.replaceChildren(this.mkNewsList(news));
        });
        this.subscriptions.push(newsSubscription);
        section.append(this.mkLoader());
        return section;
    }
    mkNewsList(news) {
        const list = document.createElement('ul');
        this.newsListRef = list;
        list.classList.add(css.newsList);
        const newsSubscription = this.news.subscribe(news => {
            this.appendNewsToList(news);
        });
        this.subscriptions.push(newsSubscription);
        let loaderRef;
        const loadingSubscription = this.newsLoading.subscribe(isLoading => {
            if (isLoading) {
                loaderRef = this.mkLoader();
                list.append(loaderRef);
                return;
            }
            loaderRef.remove();
        });
        this.subscriptions.push(loadingSubscription);
        this.appendNewsToList(news);
        return list;
    }
    appendNewsToList(news) {
        news.forEach((n, idx) => {
            const li = document.createElement('li');
            li.classList.add(css.newsListItem);
            li.append(this.mkNewsPreview(n));
            if (idx === news.length - 1) {
                this.lastNewsRef = li;
                this.connectObsEl();
            }
            this.newsListRef.append(li);
        });
    }
    mkNewsPreview(news) {
        const newsPreview = document.createElement('app-news-preview');
        newsPreview.newsSetter = news;
        return newsPreview;
    }
    mkEmptyPlaceholder() {
        const placeholder = document.createElement('div');
        placeholder.classList.add(css.placeholder);
        placeholder.textContent = 'Нет новостей';
        return placeholder;
    }
    mkLoader() {
        const loader = document.createElement('app-loader');
        return loader;
    }
    reqNewsByRange() {
        this.newsLoading.next(true);
        this.newsHttp.getNewsByRange(this.take, this.skip)
            .then(news => {
            this.newsLoading.next(false);
            this.news.next(news);
            this.skip += this.take;
        })
            .catch();
    }
    observeViewPort() {
        const viewPortObs = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    this.disconnectObsEl();
                    this.reqNewsByRange();
                }
            });
        }, {
            threshold: 1
        });
        this.viewPortObsRef = viewPortObs;
    }
    connectObsEl() {
        this.viewPortObsRef.observe(this.lastNewsRef);
    }
    disconnectObsEl() {
        this.viewPortObsRef.unobserve(this.lastNewsRef);
    }
}
