export const paths = () => location.pathname.split('/').filter(path => !!path);
export const tPagePrefix = () => `/${paths()[0]}/${paths()[1]}`;
export const TEAM_NAV_LINKS = () => [
    {
        name: 'Статистика',
        path: `${tPagePrefix()}/stats`
    },
    {
        name: 'Состав',
        path: `${tPagePrefix()}/lineup`
    }
];
