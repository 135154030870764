// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.team-page-stats-component__host{display:block}.team-page-stats-component__paddingContent{display:flex;padding:2rem 5vw;flex-direction:column;gap:1rem;min-height:70vh}.team-page-stats-component__loaderCont{width:100%;height:300px;display:flex;justify-content:center;align-items:center}.team-page-stats-component__indicators{display:grid;grid-template-columns:repeat(auto-fit, minmax(250px, 1fr));gap:1rem}@media(max-width: 599px){.team-page-stats-component__indicators{grid-template-columns:repeat(2, 1fr)}}@media(min-width: 600px)and (max-width: 960px){.team-page-stats-component__indicators{grid-template-columns:repeat(3, 1fr)}}`, "",{"version":3,"sources":["webpack://./team-page/team-page-stats/team-page-stats.component.scss"],"names":[],"mappings":"AAAA,iCACI,aAAA,CAGJ,2CACI,YAAA,CACA,gBAAA,CACA,qBAAA,CACA,QAAA,CACA,eAAA,CAGJ,uCACI,UAAA,CACA,YAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CAGJ,uCACI,YAAA,CACA,0DAAA,CACA,QAAA,CAGJ,yBACI,uCACI,oCAAA,CAAA,CAIR,+CACI,uCACI,oCAAA,CAAA","sourcesContent":[".host {\n    display: block;\n}\n\n.paddingContent {\n    display: flex;\n    padding: 2rem 5vw;\n    flex-direction: column;\n    gap: 1rem;\n    min-height: 70vh;\n}\n\n.loaderCont {\n    width: 100%;\n    height: 300px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.indicators {\n    display: grid;\n    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));\n    gap: 1rem;\n}\n\n@media (max-width: 599px) {\n    .indicators {\n        grid-template-columns: repeat(2, 1fr);\n    }\n}\n\n@media (min-width: 600px) and (max-width: 960px) {\n    .indicators {\n        grid-template-columns: repeat(3, 1fr);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"host": `team-page-stats-component__host`,
	"paddingContent": `team-page-stats-component__paddingContent`,
	"loaderCont": `team-page-stats-component__loaderCont`,
	"indicators": `team-page-stats-component__indicators`
};
module.exports = ___CSS_LOADER_EXPORT___;
