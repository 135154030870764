import { MatchPageComp } from './match-page.component';
import { MatchHeaderComp } from './match-header/match-header.component';
import { MatchGeneralComp } from './match-general/match-general.component';
import { MatchProtocolComp } from './match-protocol/match-protocol.component';
import { MatchChronologyComp } from './match-chronology/match-chronology.component';
import { MatchMediaComp } from './match-media/match-media.component';
import { MatchesComparisonSectionComp } from './match-general/matches-comparison-section/matches-comparison-section.component';
import { TeamStatsSectionComp } from './match-protocol/team-stats-section/team-stats-section.component';
import { PhotoPreviewerComp } from '@Components/photo-previewer/photo-previewer-component';
export const CUSTOM_ELEMENTS_CONF = [
    { tag: 'app-match-page', comp: MatchPageComp },
    { tag: 'app-match-header', comp: MatchHeaderComp },
    { tag: 'app-match-general', comp: MatchGeneralComp },
    { tag: 'app-match-protocol', comp: MatchProtocolComp },
    { tag: 'app-match-chronology', comp: MatchChronologyComp },
    { tag: 'app-match-media', comp: MatchMediaComp },
    { tag: 'app-matches-comparison-section', comp: MatchesComparisonSectionComp },
    { tag: 'app-match-team-stats-section', comp: TeamStatsSectionComp },
    { tag: 'app-photo-previewer', comp: PhotoPreviewerComp }
];
