import { createGrid } from '@ag-grid-community/core';
import css from './overall-standing-section.component.scss';
import { DefaultSection } from '@Components/section/section.component';
import { Layout } from '@Services/layout.service';
import { GridLoaderComp } from '@Components/grid-loader/grid-loader.component';
import { GridNoDataComp } from '@Components/grid-no-data/grid-no-data.component';
import { columnDefs, defaultColDef } from './overall-standing-table.conf';
import { themeQuartz } from '@ag-grid-community/theming';
export class OverallStandingComp extends DefaultSection {
    overallStanding;
    sectionTitle;
    layoutService = Layout.instance;
    gridOptions = this.defineGridOptions();
    gridApi;
    set overallStandingSetter(overallStanding) {
        this.overallStanding = overallStanding;
    }
    set sectionTitleSetter(title) {
        this.sectionTitle = title;
    }
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkTitle(this.sectionTitle), this.mkHeaderUnderline(), this.mkTable());
    }
    assignHostStyles() {
        super.assignHostStyles();
        this.classList.add(css.host);
    }
    mkTable() {
        const cont = document.createElement('div');
        cont.classList.add(css.table);
        this.gridApi = createGrid(cont, this.gridOptions);
        if (this.overallStanding) {
            this.gridApi.setGridOption('domLayout', 'autoHeight');
            this.gridApi.setGridOption('rowData', this.overallStanding);
            return cont;
        }
        cont.classList.add(css.tableDefaultHeight);
        return cont;
    }
    defineGridOptions() {
        return this.layoutService.isDesktop
            ? this.defineDesktopGridOptions()
            : this.defineMobileGridOptions();
    }
    defineDesktopGridOptions() {
        return {
            defaultColDef,
            columnDefs,
            theme: themeQuartz,
            loadThemeGoogleFonts: false,
            loadingOverlayComponent: GridLoaderComp,
            noRowsOverlayComponent: GridNoDataComp,
            tooltipShowDelay: 0
        };
    }
    defineMobileGridOptions() {
        return {
            defaultColDef,
            columnDefs: columnDefs.map(col => {
                if (col.headerName === 'Команда') {
                    return {
                        ...col,
                        pinned: 'left'
                    };
                }
                return col;
            }),
            theme: themeQuartz,
            loadThemeGoogleFonts: false,
            loadingOverlayComponent: GridLoaderComp,
            noRowsOverlayComponent: GridNoDataComp,
            tooltipShowDelay: 0,
            autoSizeStrategy: {
                type: 'fitCellContents'
            }
        };
    }
}
