import css from './tabs.component.scss';
export class TournamentOverlayTabsComp extends HTMLElement {
    activeTab;
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkStatsTab(), this.mkCalendarTab());
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkStatsTab() {
        const tab = document.createElement('button');
        tab.classList.add(css.tab, css.activeTab);
        tab.textContent = 'Статистика';
        tab.dataset.value = 'stats';
        tab.addEventListener('click', () => {
            this.updateActiveTab(tab);
            setTimeout(() => this.dispatchChange());
        });
        this.activeTab = tab;
        return tab;
    }
    mkCalendarTab() {
        const tab = document.createElement('button');
        tab.classList.add(css.tab);
        tab.textContent = 'Календарь';
        tab.dataset.value = 'calendar';
        tab.addEventListener('click', () => {
            this.updateActiveTab(tab);
            setTimeout(() => this.dispatchChange());
        });
        return tab;
    }
    updateActiveTab(tab) {
        for (const child of this.children) {
            child.classList.remove(css.activeTab);
        }
        tab.classList.add(css.activeTab);
        this.activeTab = tab;
    }
    dispatchChange() {
        this.dispatchEvent(new CustomEvent('tabChange', {
            detail: this.activeTab.dataset.value
        }));
    }
}
