import css from './section.components.scss';
export class DefaultSection extends HTMLElement {
    constructor() {
        super();
    }
    assignHostStyles() {
        this.classList.add(css.defaultSection);
    }
    mkHeaderUnderline() {
        const hr = document.createElement('hr');
        hr.classList.add(css.lineDecoration);
        return hr;
    }
    mkTitle(text) {
        const title = document.createElement('h2');
        title.classList.add(css.title);
        title.textContent = text;
        return title;
    }
}
