export var MonthTranslates;
(function (MonthTranslates) {
    MonthTranslates[MonthTranslates["\u044F\u043D\u0432\u0430\u0440\u044F"] = 0] = "\u044F\u043D\u0432\u0430\u0440\u044F";
    MonthTranslates[MonthTranslates["\u0444\u0435\u0432\u0440\u0430\u043B\u044F"] = 1] = "\u0444\u0435\u0432\u0440\u0430\u043B\u044F";
    MonthTranslates[MonthTranslates["\u043C\u0430\u0440\u0442\u0430"] = 2] = "\u043C\u0430\u0440\u0442\u0430";
    MonthTranslates[MonthTranslates["\u0430\u043F\u0440\u0435\u043B\u044F"] = 3] = "\u0430\u043F\u0440\u0435\u043B\u044F";
    MonthTranslates[MonthTranslates["\u043C\u0430\u044F"] = 4] = "\u043C\u0430\u044F";
    MonthTranslates[MonthTranslates["\u0438\u044E\u043D\u044F"] = 5] = "\u0438\u044E\u043D\u044F";
    MonthTranslates[MonthTranslates["\u0438\u044E\u043B\u044F"] = 6] = "\u0438\u044E\u043B\u044F";
    MonthTranslates[MonthTranslates["\u0430\u0432\u0433\u0443\u0441\u0442\u0430"] = 7] = "\u0430\u0432\u0433\u0443\u0441\u0442\u0430";
    MonthTranslates[MonthTranslates["\u0441\u0435\u043D\u0442\u044F\u0431\u0440\u044F"] = 8] = "\u0441\u0435\u043D\u0442\u044F\u0431\u0440\u044F";
    MonthTranslates[MonthTranslates["\u043E\u043A\u0442\u044F\u0431\u0440\u044F"] = 9] = "\u043E\u043A\u0442\u044F\u0431\u0440\u044F";
    MonthTranslates[MonthTranslates["\u043D\u043E\u044F\u0431\u0440\u044F"] = 10] = "\u043D\u043E\u044F\u0431\u0440\u044F";
    MonthTranslates[MonthTranslates["\u0434\u0435\u043A\u0430\u0431\u0440\u044F"] = 11] = "\u0434\u0435\u043A\u0430\u0431\u0440\u044F";
})(MonthTranslates || (MonthTranslates = {}));
export var MonthAbbrTranslates;
(function (MonthAbbrTranslates) {
    MonthAbbrTranslates[MonthAbbrTranslates["\u044F\u043D\u0432"] = 0] = "\u044F\u043D\u0432";
    MonthAbbrTranslates[MonthAbbrTranslates["\u0444\u0435\u0432"] = 1] = "\u0444\u0435\u0432";
    MonthAbbrTranslates[MonthAbbrTranslates["\u043C\u0430\u0440"] = 2] = "\u043C\u0430\u0440";
    MonthAbbrTranslates[MonthAbbrTranslates["\u0430\u043F\u0440"] = 3] = "\u0430\u043F\u0440";
    MonthAbbrTranslates[MonthAbbrTranslates["\u043C\u0430\u0439"] = 4] = "\u043C\u0430\u0439";
    MonthAbbrTranslates[MonthAbbrTranslates["\u0438\u044E\u043D"] = 5] = "\u0438\u044E\u043D";
    MonthAbbrTranslates[MonthAbbrTranslates["\u0438\u044E\u043B"] = 6] = "\u0438\u044E\u043B";
    MonthAbbrTranslates[MonthAbbrTranslates["\u0430\u0432\u0433"] = 7] = "\u0430\u0432\u0433";
    MonthAbbrTranslates[MonthAbbrTranslates["\u0441\u0435\u043D"] = 8] = "\u0441\u0435\u043D";
    MonthAbbrTranslates[MonthAbbrTranslates["\u043E\u043A\u0442"] = 9] = "\u043E\u043A\u0442";
    MonthAbbrTranslates[MonthAbbrTranslates["\u043D\u043E\u044F"] = 10] = "\u043D\u043E\u044F";
    MonthAbbrTranslates[MonthAbbrTranslates["\u0434\u0435\u043A"] = 11] = "\u0434\u0435\u043A";
})(MonthAbbrTranslates || (MonthAbbrTranslates = {}));
export var DayTranslates;
(function (DayTranslates) {
    DayTranslates[DayTranslates["\u0412\u043E\u0441\u043A\u0440\u0435\u0441\u0435\u043D\u044C\u0435"] = 0] = "\u0412\u043E\u0441\u043A\u0440\u0435\u0441\u0435\u043D\u044C\u0435";
    DayTranslates[DayTranslates["\u041F\u043E\u043D\u0435\u0434\u0435\u043B\u044C\u043D\u0438\u043A"] = 1] = "\u041F\u043E\u043D\u0435\u0434\u0435\u043B\u044C\u043D\u0438\u043A";
    DayTranslates[DayTranslates["\u0412\u0442\u043E\u0440\u043D\u0438\u043A"] = 2] = "\u0412\u0442\u043E\u0440\u043D\u0438\u043A";
    DayTranslates[DayTranslates["\u0421\u0440\u0435\u0434\u0430"] = 3] = "\u0421\u0440\u0435\u0434\u0430";
    DayTranslates[DayTranslates["\u0427\u0435\u0442\u0432\u0435\u0440\u0433"] = 4] = "\u0427\u0435\u0442\u0432\u0435\u0440\u0433";
    DayTranslates[DayTranslates["\u041F\u044F\u0442\u043D\u0438\u0446\u0430"] = 5] = "\u041F\u044F\u0442\u043D\u0438\u0446\u0430";
    DayTranslates[DayTranslates["\u0421\u0443\u0431\u0431\u043E\u0442\u0430"] = 6] = "\u0421\u0443\u0431\u0431\u043E\u0442\u0430";
})(DayTranslates || (DayTranslates = {}));
export var Days;
(function (Days) {
    Days[Days["Sunday"] = 0] = "Sunday";
    Days[Days["Monday"] = 1] = "Monday";
    Days[Days["Tuesday"] = 2] = "Tuesday";
    Days[Days["Wednesday"] = 3] = "Wednesday";
    Days[Days["Thursday"] = 4] = "Thursday";
    Days[Days["Friday"] = 5] = "Friday";
    Days[Days["Saturday"] = 6] = "Saturday";
})(Days || (Days = {}));
