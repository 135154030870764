import { ORIGIN, PROTOCOL } from '@Constants/http.constants';
export class SponsorsHttp {
    static #instance;
    constructor() { }
    static get instance() {
        if (!SponsorsHttp.#instance)
            SponsorsHttp.#instance = new SponsorsHttp();
        return SponsorsHttp.#instance;
    }
    async getMain() {
        try {
            const res = await fetch(`${PROTOCOL}${ORIGIN}/api/sponsors/getMain`, {
                method: 'GET'
            });
            if (!res.ok) {
                const parsedResponse = await res.json();
                throw new Error(parsedResponse.msg);
            }
            return res.json();
        }
        catch (err) {
            console.log('[HTTP] error during getMain', err);
            throw err;
        }
    }
}
