import css from './round-settings.component.scss';
import { TournamentBaseSettingsComp } from '../base/base-settings.component';
export class TournamentRoundSettingsComp extends TournamentBaseSettingsComp {
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkSettings());
    }
    mkSettings() {
        const cont = document.createElement('div');
        cont.classList.add(css.settings);
        cont.append(this.mkTimeoutSettings(), this.mkPeriodsSettings(), this.mkPenaltySettings(), this.mkTimerSettings(), this.mkMaxApplicationPlayers());
        return cont;
    }
}
