import css from './tournament-editor.component.scss';
export class TournamentEditorComp extends HTMLElement {
    tournament;
    tournamentStatsType;
    contentRef;
    set tournamentSetter(tournament) {
        this.tournament = tournament;
    }
    set tournamentStatsTypeSetter(tournamentType) {
        this.tournamentStatsType = tournamentType;
    }
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
        this.setHostFocus();
        this.escBtnListener();
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkHeader(), this.mkContent());
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkHeader() {
        const header = document.createElement('header');
        header.classList.add(css.header);
        header.append(this.mkTabs(), this.mkCloseBtn());
        return header;
    }
    mkTabs() {
        const tabs = document.createElement('app-tournament-editor-tabs');
        tabs.addEventListener('tabChange', ((e) => {
            this.updateView(e.detail);
        }));
        return tabs;
    }
    mkCloseBtn() {
        const btn = document.createElement('button');
        btn.classList.add(css.closeBtn);
        btn.append(this.mkCloseIcon());
        btn.addEventListener('click', () => this.closeEditor());
        return btn;
    }
    mkCloseIcon() {
        const icon = document.createElement('img');
        icon.classList.add(css.closeIcon);
        return icon;
    }
    closeEditor() {
        this.dispatchEvent(new CustomEvent('closed'));
        this.remove();
    }
    mkContent() {
        const content = document.createElement('div');
        this.contentRef = content;
        content.append(this.mkTournamentSettings());
        return content;
    }
    mkTournamentSettings() {
        const settings = document.createElement('app-tournament-settings');
        settings.tournamentTypeSetter = this.tournamentStatsType;
        settings.tournamentSetter = this.tournament;
        return settings;
    }
    setHostFocus() {
        this.tabIndex = 1;
        this.focus();
    }
    updateView(activeTab) {
        switch (activeTab) {
            case 'settings':
                this.contentRef.replaceChildren(this.mkTournamentSettings());
                break;
            case 'matches':
                this.contentRef.replaceChildren('matches comp'); // TODO: replace to matches settings
                break;
        }
    }
    escBtnListener() {
        this.addEventListener('keyup', (e) => {
            if (e.code === 'Escape')
                this.closeEditor();
        });
    }
}
