import { paths } from '../player-page-nav.conf';
import css from './player-matches.component.scss';
import { PlayersHttp } from '@Services/http/players.http';
import { selectedTeamId, selectedTeamIdChange } from '../player-page.state';
import { Subject } from 'rxjs';
import { removeChildren } from '@Helpers/dom-manipulation.helper';
export class PlayerMatchesComp extends HTMLElement {
    playersHttp = PlayersHttp.instance;
    subscriptions = [];
    matchesBySeasons = new Subject();
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
        this.reqPlayerMatchesByTeamAndSeasons().then(matches => {
            this.matchesBySeasons.next(matches);
        });
        this.listenTeamIdChange();
    }
    disconnectedCallback() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkPaddingContent());
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkPaddingContent() {
        const paddingContent = document.createElement('div');
        paddingContent.classList.add(css.paddingContent);
        paddingContent.append(this.mkSeasonsMatches());
        return paddingContent;
    }
    mkSeasonsMatches() {
        const cont = document.createElement('div');
        cont.classList.add(css.seasonsMatchesCont);
        const matchesChangeSubscription = this.matchesBySeasons.subscribe(matchesBySeasons => {
            removeChildren(cont);
            if (!matchesBySeasons.length) {
                cont.append(this.mkEmptyPlaceholder());
                return;
            }
            cont.append(...matchesBySeasons.map(matchesBySeason => this.mkMatchesBySeasonSection(matchesBySeason)));
        });
        this.subscriptions.push(matchesChangeSubscription);
        cont.append(this.mkLoader());
        return cont;
    }
    mkMatchesBySeasonSection(matchesBySeason) {
        const section = document.createElement('app-player-season-matches-section');
        section.seasonMatchesSetter = matchesBySeason;
        return section;
    }
    mkEmptyPlaceholder() {
        const placeholder = document.createElement('p');
        placeholder.classList.add(css.emptyPlaceholder);
        placeholder.textContent = 'Пусто';
        return placeholder;
    }
    mkLoader() {
        const loaderCont = document.createElement('div');
        loaderCont.classList.add(css.loaderCont);
        const loader = document.createElement('app-loader');
        loaderCont.append(loader);
        return loaderCont;
    }
    async reqPlayerMatchesByTeamAndSeasons(teamId = selectedTeamId) {
        return this.playersHttp.getPlayerStatsByTeamAndSeason(paths()[1], teamId);
    }
    listenTeamIdChange() {
        const teamIdChangeSubscription = selectedTeamIdChange.subscribe(teamId => {
            this.reqPlayerMatchesByTeamAndSeasons(teamId).then(matches => this.matchesBySeasons.next(matches));
        });
        this.subscriptions.push(teamIdChangeSubscription);
    }
}
