import css from './calendar-page.component.scss';
import { fromEvent, Subject } from 'rxjs';
import { MatchesHttp } from '@Services/http/matches.http';
import { DayTranslates, MonthTranslates } from '../shared/enums/date.enum';
import { TeamsHttp } from '@Services/http/teams.http';
export class CalendarComp extends HTMLElement {
    selectedMonth;
    matchesHttp = MatchesHttp.instance;
    teamsHttp = TeamsHttp.instance;
    currentMatches = new Subject();
    subscriptions = [];
    loading = new Subject();
    template = {};
    teams = new Subject();
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
        this.reqTeams();
    }
    disconnectedCallback() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkPaddingContent(), this.mkFooter());
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkWeeksCalendar() {
        const weeksCalendarCont = document.createElement('div');
        const weeksCalendar = document.createElement('app-weeks-calendar');
        weeksCalendar.weeksCalendarSetter = this.selectedMonth;
        weeksCalendarCont.append(weeksCalendar);
        const monthChangeSubscription = fromEvent(this.template.monthPicker, 'change').subscribe(e => {
            const weeksCalendar = document.createElement('app-weeks-calendar');
            weeksCalendar.weeksCalendarSetter = e.target.value;
            weeksCalendarCont.replaceChildren(weeksCalendar);
        });
        this.subscriptions.push(monthChangeSubscription);
        return weeksCalendarCont;
    }
    mkFooter() {
        const footer = document.createElement('app-footer');
        return footer;
    }
    mkPaddingContent() {
        const paddingContent = document.createElement('div');
        paddingContent.classList.add(css.paddingContent);
        paddingContent.append(this.mkFiltersForm(), this.mkDays());
        return paddingContent;
    }
    mkFiltersForm() {
        const filtersForm = document.createElement('form');
        this.template.filtersForm = filtersForm;
        filtersForm.classList.add(css.filtersForm);
        filtersForm.addEventListener('change', (e) => {
            if (e.target.type === 'month')
                return;
            if (e.target.type === 'text') {
                const teamPicker = e.target;
                if (!teamPicker.value) {
                    this.reqMatchesBySelectedDate();
                    this.template.teamPicker.dataset.valid = 'true';
                    return;
                }
                const selectedOption = teamPicker.children[0]
                    .querySelector(`[value="${teamPicker.value}"]`);
                if (!selectedOption) {
                    this.highlightWrongTeam();
                    this.template.teamPicker.dataset.valid = 'false';
                    return;
                }
                const { startDate, endDate } = JSON.parse(filtersForm.weeks.value);
                this.reqMatchesByDateAndTeam(startDate, endDate, selectedOption.dataset.teamId);
                this.template.teamPicker.dataset.valid = 'true';
                return;
            }
            const { startDate, endDate } = JSON.parse(e.target.value);
            this.reqMatchesByDateAndTeam(startDate, endDate);
        });
        filtersForm.addEventListener('submit', (e) => {
            e.preventDefault();
            if (this.template.teamPicker.dataset.valid === 'true')
                this.template.teamPicker.blur();
        });
        filtersForm.append(this.mkFilters(), this.mkWeeksCalendar());
        return filtersForm;
    }
    highlightWrongTeam() {
        this.template.teamPicker.classList.add(css.invalid);
        setTimeout(() => this.template.teamPicker.classList.remove(css.invalid), 2000);
    }
    reqMatchesBySelectedDate() {
        const { startDate, endDate } = JSON.parse(this.template.filtersForm.weeks.value);
        this.reqMatchesByDateAndTeam(startDate, endDate);
    }
    mkFilters() {
        const filters = document.createElement('section');
        filters.classList.add(css.filters);
        filters.append(this.mkMonthPicker(), this.mkTeamPicker());
        return filters;
    }
    getMonthPickerDefaultValue() {
        const date = new Date();
        return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
    }
    mkMonthPicker() {
        const monthPicker = document.createElement('input');
        const defaultValue = this.getMonthPickerDefaultValue();
        monthPicker.name = 'datePicker';
        monthPicker.classList.add(css.monthPicker);
        monthPicker.type = 'month';
        monthPicker.min = '2022-01';
        monthPicker.max = '2024-10';
        monthPicker.defaultValue = defaultValue;
        monthPicker.required = true;
        this.template.monthPicker = monthPicker;
        this.selectedMonth = defaultValue;
        return monthPicker;
    }
    mkTeamPicker() {
        const teamPicker = document.createElement('input');
        teamPicker.name = 'teamPicker';
        teamPicker.setAttribute('list', 'teams');
        teamPicker.placeholder = 'Загрузка команд...';
        teamPicker.classList.add(css.teamPicker);
        teamPicker.autocomplete = 'off';
        teamPicker.dataset.valid = 'true';
        this.template.teamPicker = teamPicker;
        teamPicker.append(this.mkTeamsDatalist());
        return teamPicker;
    }
    mkTeamsDatalist() {
        const datalist = document.createElement('datalist');
        datalist.id = 'teams';
        const teamsSubscription = this.teams.subscribe(teams => {
            datalist.append(this.mkAllTeamsDatalistOption());
            for (const team of teams) {
                datalist.append(this.mkDatalistOption(team));
            }
            this.template.teamPicker.placeholder = 'Найти команду...';
        });
        this.subscriptions.push(teamsSubscription);
        return datalist;
    }
    mkDatalistOption(team) {
        const option = document.createElement('option');
        option.value = team.name;
        option.dataset.teamId = team._id;
        return option;
    }
    mkAllTeamsDatalistOption() {
        const option = document.createElement('option');
        option.value = 'Все команды';
        return option;
    }
    reqMatchesByDateAndTeam(startDate, endDate, teamId) {
        this.loading.next(true);
        if (teamId) {
            this.matchesHttp.getMatchesByDateAndTeam(startDate, endDate, teamId)
                .then(matches => this.currentMatches.next(matches));
            return;
        }
        this.matchesHttp.getMatchesByDateAndTeam(startDate, endDate, teamId)
            .then(matches => this.currentMatches.next(matches));
    }
    mkDayMatchesSection(title, matches) {
        const section = document.createElement('app-day-matches-section');
        section.sectionTitleSetter = title;
        section.matchesSetter = matches;
        return section;
    }
    mkLoader() {
        const loaderCont = document.createElement('div');
        loaderCont.classList.add(css.loaderCont);
        const loader = document.createElement('app-loader');
        loaderCont.append(loader);
        return loaderCont;
    }
    mkEmptyPlaceholder() {
        const placeholder = document.createElement('div');
        placeholder.classList.add(css.placeholder);
        placeholder.textContent = 'Нет матчей';
        return placeholder;
    }
    mkDays() {
        const cont = document.createElement('div');
        cont.classList.add(css.days);
        const matchesSubscription = this.currentMatches.subscribe(matches => {
            const frag = new DocumentFragment();
            const matchesByDays = this.sortMatchesByDays(matches);
            if (matchesByDays.size) {
                for (const [dayNum, matchesPerDay] of matchesByDays) {
                    const helpDate = new Date(matchesPerDay[0].datetime);
                    const sectionTitle = `${DayTranslates[dayNum]}, ${helpDate.getDate()} ${MonthTranslates[helpDate.getMonth()]}`;
                    frag.append(this.mkDayMatchesSection(sectionTitle, matchesPerDay));
                }
            }
            else {
                frag.append(this.mkEmptyPlaceholder());
            }
            cont.replaceChildren(frag);
        });
        const loadingSubscription = this.loading.subscribe(isLoading => {
            if (isLoading)
                cont.replaceChildren(this.mkLoader());
        });
        this.subscriptions.push(matchesSubscription);
        this.subscriptions.push(loadingSubscription);
        return cont;
    }
    sortMatchesByDays(matches) {
        const matchesByDays = new Map();
        matches.forEach(match => {
            const matchDay = new Date(match.datetime).getDay();
            if (matchesByDays.has(matchDay)) {
                matchesByDays.set(matchDay, [...matchesByDays.get(matchDay), match]);
                return;
            }
            matchesByDays.set(matchDay, [match]);
        });
        return matchesByDays;
    }
    reqTeams() {
        this.teamsHttp.getCalendarTeams()
            .then(teams => this.teams.next(teams))
            .catch();
    }
}
