import { DefaultSection } from '@Components/section/section.component';
import css from './video-section.component.scss';
export class VideoSectionComp extends DefaultSection {
    videos;
    set dataSetter(videos) {
        this.videos = videos;
    }
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkTitle('Прямой эфир'), this.mkHeaderUnderline(), this.mkSlider());
    }
    mkSlider() {
        const slider = document.createElement('app-slider');
        slider.sliderSetter = this.videos.map(video => this.mkVideo(video));
        return slider;
    }
    mkVideo(video) {
        const videoEl = document.createElement('iframe');
        videoEl.loading = 'lazy';
        videoEl.allowFullscreen = true;
        videoEl.classList.add(css.videoEl);
        videoEl.src = video;
        return videoEl;
    }
}
