import { DefaultSection } from '@Components/section/section.component';
import css from './players-stats-section.component.scss';
import { Layout } from '@Services/layout.service';
import { createGrid } from '@ag-grid-community/core';
import { columnDefs, defaultColDef } from './players-stats-table.conf';
import { themeQuartz } from '@ag-grid-community/theming';
import { GridLoaderComp } from '@Components/grid-loader/grid-loader.component';
import { GridNoDataComp } from '@Components/grid-no-data/grid-no-data.component';
import { ReplaySubject } from 'rxjs';
export class PlayersStatsSectionComp extends DefaultSection {
    layoutService = Layout.instance;
    gridOptions = this.defineGridOptions();
    gridApi;
    playersStats = new ReplaySubject();
    subscriptions = [];
    set playersStatsSetter(stats) {
        this.playersStats.next(stats);
    }
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
    }
    disconnectedCallback() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkTitle('Общая статистика'), this.mkHeaderUnderline(), this.mkContentCont());
    }
    assignHostStyles() {
        super.assignHostStyles();
        this.classList.add(css.host);
    }
    mkContentCont() {
        const cont = document.createElement('div');
        cont.classList.add(css.contentCont);
        cont.append(this.mkTable());
        return cont;
    }
    mkTable() {
        const cont = document.createElement('div');
        cont.classList.add(css.table);
        this.gridApi = createGrid(cont, this.gridOptions);
        const playersStatsSubscription = this.playersStats.subscribe(stats => {
            if (stats === null) {
                this.gridApi.setGridOption('loading', true);
                return;
            }
            this.gridApi.setGridOption('rowData', stats);
            this.gridApi.setGridOption('loading', false);
            return cont;
        });
        this.subscriptions.push(playersStatsSubscription);
        return cont;
    }
    defineGridOptions() {
        return this.layoutService.isDesktop
            ? this.defineDesktopGridOptions()
            : this.defineMobileGridOptions();
    }
    defineDesktopGridOptions() {
        return {
            suppressCellFocus: true,
            defaultColDef,
            columnDefs,
            theme: themeQuartz,
            loadThemeGoogleFonts: false,
            loadingOverlayComponent: GridLoaderComp,
            noRowsOverlayComponent: GridNoDataComp,
            tooltipShowDelay: 0,
            onSortChanged: () => this.gridApi.refreshCells(),
            rowClassRules: {
                [css.firstPlaceRow]: params => params.node.rowIndex === 0,
                [css.secondPlaceRow]: params => params.node.rowIndex === 1,
                [css.thirdPlaceRow]: params => params.node.rowIndex === 2
            }
        };
    }
    defineMobileGridOptions() {
        return {
            suppressCellFocus: true,
            defaultColDef,
            columnDefs: columnDefs.map(col => {
                if (col.headerName === 'Игрок') {
                    return {
                        ...col,
                        pinned: 'left'
                    };
                }
                if (col.headerName === '') {
                    return {
                        ...col,
                        hide: true
                    };
                }
                if (col.headerName === 'Г') {
                    return {
                        ...col,
                        cellClass: css.columnLeftPadding,
                        headerClass: css.columnLeftPadding
                    };
                }
                return col;
            }),
            theme: themeQuartz,
            loadThemeGoogleFonts: false,
            loadingOverlayComponent: GridLoaderComp,
            noRowsOverlayComponent: GridNoDataComp,
            tooltipShowDelay: 0,
            autoSizeStrategy: {
                type: 'fitCellContents'
            },
            onSortChanged: () => this.gridApi.refreshCells(),
            rowClassRules: {
                [css.firstPlaceRow]: params => params.node.rowIndex === 0,
                [css.secondPlaceRow]: params => params.node.rowIndex === 1,
                [css.thirdPlaceRow]: params => params.node.rowIndex === 2
            }
        };
    }
}
