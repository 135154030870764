import { MatchLinkCellRendererComp } from '@Components/cell-renderers/match-link/match-link.component';
import { TeamConfrontationCellRendererComp } from '@Components/cell-renderers/team-confrontation/team-confrontation.component';
import { MATCH_LINK_COLUMN_WIDTH } from '@Constants/aggrid.constants';
import { arenaNameValueGetter, teamConfrontationValueGetter, timeFromISOValueFormatter, tournamentNameValueGetter } from '@Helpers/ag-grid.helper';
export const defaultColDef = {
    suppressMovable: true,
    resizable: false
};
export const columnDefs = [
    {
        headerName: 'Матч',
        valueGetter: teamConfrontationValueGetter,
        cellRenderer: TeamConfrontationCellRendererComp,
        sortable: false,
        flex: 4
    },
    {
        field: 'datetime',
        headerName: 'Время',
        valueFormatter: timeFromISOValueFormatter,
        flex: 1
    },
    {
        headerName: 'Каток',
        valueGetter: arenaNameValueGetter,
        flex: 3
    },
    {
        headerName: 'Турнир',
        valueGetter: tournamentNameValueGetter,
        flex: 3
    },
    {
        headerName: '',
        field: '_id',
        cellRenderer: MatchLinkCellRendererComp,
        sortable: false,
        width: MATCH_LINK_COLUMN_WIDTH
    },
];
