import { isCompRoute } from '@Helpers/router.helper';
export class Router {
    host;
    routes;
    static NOT_FOUND_ROUTE = {
        path: /not found/,
        view: 'app-404'
    };
    constructor(host, routes) {
        this.host = host;
        this.routes = routes;
    }
    generateOutlet() {
        if (this.host.hasChildNodes())
            this.clearOutlet();
        const routeMatch = this.findMatch();
        if (isCompRoute(routeMatch)) {
            this.host.append(document.createElement(routeMatch.view));
            return;
        }
    }
    clearOutlet() {
        this.host.removeChild(this.host.firstChild);
    }
    findMatch() {
        return this.routes.find(route => route.path.test(location.pathname.replace('/admin', '')))
            || Router.NOT_FOUND_ROUTE;
    }
}
export const ADMIN_ROUTES = [
    {
        path: /^\/players\/?$/,
        view: 'app-admin-players'
    },
    {
        path: /^\/documents\/?$/,
        view: 'app-admin-documents'
    },
    {
        path: /^\/news\/?$/,
        view: 'app-admin-news'
    },
    {
        path: /^\/tournaments\/?$/,
        view: 'app-admin-tournaments'
    }
];
