import css from './login-page.component.scss';
import { rootRouter } from '../routing/routing';
import { LoginHttp } from '@Services/http/login.http';
import { UserService } from '@Services/user.service';
export class LoginPageComp extends HTMLElement {
    template = {};
    loginHttp = LoginHttp.instance;
    userService = UserService.instance;
    constructor() {
        super();
    }
    connectedCallback() {
        this.validateJWT();
        this.render();
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkForm());
    }
    validateJWT() {
        if (this.userService.isAdmin)
            this.redirectToAdminPage();
    }
    mkWrongCredentials() {
        const wrongCredentials = document.createElement('p');
        wrongCredentials.classList.add(css.invalidText, css.hidden);
        wrongCredentials.textContent = 'Неверный логин или пароль';
        this.template.wrongCredentials = wrongCredentials;
        return wrongCredentials;
    }
    mkPasswordInput() {
        const passwordField = this.mkField('Пароль', 'password');
        const passwordInput = passwordField.querySelector('input');
        this.template.passwordInput = passwordInput;
        passwordInput.onfocus = () => {
            passwordInput.classList.remove(css.redBorders);
            this.template.loginInput.classList.remove(css.redBorders);
            this.template.wrongCredentials.classList.add(css.hidden);
        };
        return passwordField;
    }
    mkLoginInput() {
        const loginField = this.mkField('Имя пользователя', 'login');
        const loginInput = loginField.querySelector('input');
        this.template.loginInput = loginInput;
        loginInput.onfocus = () => {
            loginInput.classList.remove(css.redBorders);
            this.template.passwordInput.classList.remove(css.redBorders);
            this.template.wrongCredentials.classList.add(css.hidden);
        };
        return loginField;
    }
    mkForm() {
        const form = document.createElement('form');
        form.classList.add(css.form);
        this.template.form = form;
        form.onsubmit = async (e) => {
            e.preventDefault();
            this.loginHandle();
        };
        form.append(this.mkLoginInput(), this.mkPasswordInput(), this.mkFormFooter());
        return form;
    }
    loginHandle() {
        this.loginHttp.login(this.template.form)
            .then(user => {
            this.saveJWT(user);
            if (user.role === 'admin')
                this.redirectToAdminPage();
        })
            .catch(() => {
            this.template.loginInput.classList.add(css.redBorders);
            this.template.passwordInput.classList.add(css.redBorders);
            this.template.wrongCredentials.classList.remove(css.hidden);
        });
    }
    mkFormFooter() {
        const footer = document.createElement('div');
        footer.classList.add(css.formFooter);
        footer.append(this.mkWrongCredentials(), this.mkEnterBtn());
        return footer;
    }
    mkEnterBtn() {
        const btn = document.createElement('button');
        btn.textContent = 'Войти';
        btn.classList.add(css.enterBtn);
        return btn;
    }
    mkField(name, id) {
        const wrapper = document.createElement('div');
        wrapper.classList.add(css.fieldWrapper);
        const label = document.createElement('label');
        label.setAttribute('for', id);
        label.textContent = name;
        const field = document.createElement('input');
        field.setAttribute('id', id);
        field.name = id;
        field.classList.add(css.field);
        wrapper.appendChild(label);
        wrapper.appendChild(field);
        return wrapper;
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    saveJWT(user) {
        localStorage.setItem('user', JSON.stringify(user));
    }
    redirectToAdminPage() {
        history.replaceState(null, '', '/admin');
        rootRouter.generateOutlet();
    }
}
