import { AdminPageComp } from './admin-page.component';
import { AdminNavComp } from './admin-nav/admin-nav.component';
import { AdminPlayersComp } from './admin-players/admin-players.component';
import { AdminDocumentsComp } from './admin-documents/admin-documents.component';
import { DocumentComp } from '@Components/document/document.component';
import { AdminDocumentsSectionComp } from './admin-documents/admin-documents-section/admin-documents-section.component';
import { CreateDocumentComp } from './admin-documents/create-document/create-document.component';
import { PlayersTableComp } from './admin-players/players-table/players-table.component';
import { AdminNewsComp } from './admin-news/admin-news.component';
import { EditorJSComp } from '../shared/components/editorjs/editorjs.component';
import { NewsEditorComp } from './admin-news/news-editor/news-editor.component';
import NewsPreviewComp from '@Components/news-preview/news-preview.component';
import { NewsSectionComp } from './admin-news/news-section/news-section.component';
import { AdminTournamentsComp } from './admin-tournaments/admin-tournaments.component';
import { TournamentEditorComp } from './admin-tournaments/tournament-editor/tournament-editor.component';
import { TournamentGeneralSettingsComp } from './admin-tournaments/tournament-editor/settings/general/general-settings.component';
import { TournamentSettingsComp } from './admin-tournaments/tournament-editor/settings/tournament-settings.component';
import { TournamentEditorTabsComp } from './admin-tournaments/tournament-editor/tabs/tabs.component';
import { TournamentRoundSettingsComp } from './admin-tournaments/tournament-editor/settings/round/round-settings.component';
import { TournamentRegularSettingsComp } from './admin-tournaments/tournament-editor/settings/tournament-regular-settings/tournament-regular-settings.component';
import { TournamentStrongestBattleSettingsComp } from './admin-tournaments/tournament-editor/settings/tournament-strongest-battle-settings.component/tournament-strongest-battle-settings.component';
import { TournamentPlayoffSettingsComp } from './admin-tournaments/tournament-editor/settings/playoff/playoff-settings.component';
import { TournamentGroupSettingsComp } from './admin-tournaments/tournament-editor/settings/group/group-settings.component';
import { PhotoEditorComp } from '@Components/photo-editor/photo-editor.component';
import { TournamentCreatorComp } from './admin-tournaments/tournament-creator/tournament-creator.component';
export const CUSTOM_ELEMENTS_CONF = [
    { tag: 'app-admin-page', comp: AdminPageComp },
    { tag: 'app-admin-nav', comp: AdminNavComp },
    { tag: 'app-admin-players', comp: AdminPlayersComp },
    { tag: 'app-admin-players-table', comp: PlayersTableComp },
    { tag: 'app-admin-documents', comp: AdminDocumentsComp },
    { tag: 'app-document', comp: DocumentComp },
    { tag: 'app-admin-documents-section', comp: AdminDocumentsSectionComp },
    { tag: 'app-create-document', comp: CreateDocumentComp },
    { tag: 'app-admin-news', comp: AdminNewsComp },
    { tag: 'app-editorjs', comp: EditorJSComp },
    { tag: 'app-news-editor', comp: NewsEditorComp },
    { tag: 'app-news-preview', comp: NewsPreviewComp },
    { tag: 'app-news-section', comp: NewsSectionComp },
    { tag: 'app-photo-editor', comp: PhotoEditorComp },
    { tag: 'app-admin-tournaments', comp: AdminTournamentsComp },
    { tag: 'app-tournament-editor', comp: TournamentEditorComp },
    { tag: 'app-tournament-general-settings', comp: TournamentGeneralSettingsComp },
    { tag: 'app-tournament-round-settings', comp: TournamentRoundSettingsComp },
    { tag: 'app-tournament-playoff-settings', comp: TournamentPlayoffSettingsComp },
    { tag: 'app-tournament-group-settings', comp: TournamentGroupSettingsComp },
    { tag: 'app-tournament-strongest-battle-settings', comp: TournamentStrongestBattleSettingsComp },
    { tag: 'app-tournament-regular-settings', comp: TournamentRegularSettingsComp },
    { tag: 'app-tournament-settings', comp: TournamentSettingsComp },
    { tag: 'app-tournament-editor-tabs', comp: TournamentEditorTabsComp },
    { tag: 'app-tournament-creator', comp: TournamentCreatorComp }
];
