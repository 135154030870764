export const customDateFieldGuard = (customField) => {
    return customField.type === 'date';
};
export const customTextFieldGuard = (customField) => {
    return customField.type === 'string';
};
export const customNumberFieldGuard = (customField) => {
    return customField.type === 'number';
};
export const customNonNullableFieldGuard = (customField) => {
    return !!customField.value;
};
