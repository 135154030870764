import { AboutPageComp } from './about-page.component';
import { AboutDescComp } from './about-desc/about-desc.component';
import { ArenaSectionComp } from './arena-section/arena-section.component';
import { SliderComp } from '@Components/slider/slider.component';
import { FooterComp } from '@Components/footer/footer.component';
import { PhotoPreviewerComp } from '@Components/photo-previewer/photo-previewer-component';
export const CUSTOM_ELEMENTS_CONF = [
    { tag: 'app-about-page', comp: AboutPageComp },
    { tag: 'app-about-desc', comp: AboutDescComp },
    { tag: 'app-arena-section', comp: ArenaSectionComp },
    { tag: 'app-slider', comp: SliderComp },
    { tag: 'app-footer', comp: FooterComp },
    { tag: 'app-photo-previewer', comp: PhotoPreviewerComp }
];
