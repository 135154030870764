import { convertElementToRouterLink } from '@Helpers/router.helper';
import css from './match-link.component.scss';
export class MatchLinkCellRendererComp {
    gui;
    matchId;
    constructor() { }
    init(params) {
        if (!params.value)
            return;
        this.matchId = params.data._id;
        this.gui = this.mkDetailsLink();
    }
    getGui() {
        return this.gui;
    }
    refresh() {
        return false;
    }
    mkDetailsLink() {
        const link = document.createElement('a');
        link.classList.add(css.link);
        convertElementToRouterLink(link);
        link.href = `/match/${this.matchId}/general`;
        link.append(this.mkEyeIcon());
        return link;
    }
    mkEyeIcon() {
        const icon = document.createElement('img');
        icon.classList.add(css.icon);
        return icon;
    }
}
