/**
 * Получает n-й элемент из арифмитической прогрессии с шагом d
 * @param idx Индекс элемента начиная с 1
 * @param first Значение первого члена
 * @param d Шаг арифмитической прогрессии
 */
export const getAPEl = (idx, first, d) => {
    return first + ((idx - 1) * d);
};
/**
 * Формула характеристического св-ва n-го члена арифмитической прогрессии
 * @param first значение первого члена
 * @param second значение второго члена
*/
export const getAPNeighboursAvr = (first, second) => {
    return (first + second) / 2;
};
/**
 * Формула нахождения n-го члена геометрической прогрессии
 * @param idx Индекс элемента начиная с 1
 * @param first Значение первого члена
 * @param q Шаг геометрической прогрессии
 */
export const getGPEl = (idx, first, q) => {
    return first * q ** (idx - 1);
};
/**
 * Формула нахождения n-го члена числовой последовательности с произвольным шагом
 * @param idx Индекс элемента начиная с 1
 * @param first Значение первого члена
 * @param step Коллбек ф-ия шага
 */
export const getCustomSequenceEl = (idx, first, step) => {
    let result = first;
    for (let i = 2; i <= idx; i++) {
        result += step(result);
    }
    return result;
};
