import css from './team-indicator.component.scss';
import { INDICATOR_NAME_LOCALS } from './team-indicator.conf';
export class TeamIndicatorComp extends HTMLElement {
    indicatorName;
    indicatorValue;
    set nameSetter(name) {
        this.indicatorName = name;
    }
    set valueSetter(value) {
        this.indicatorValue = value;
    }
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkName(), this.mkValue());
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkName() {
        const name = document.createElement('p');
        name.classList.add(css.name);
        name.textContent = INDICATOR_NAME_LOCALS[this.indicatorName];
        return name;
    }
    mkValue() {
        const value = document.createElement('p');
        value.classList.add(css.value);
        value.textContent = String(this.indicatorValue);
        return value;
    }
}
