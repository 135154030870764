export class UserService {
    static #instance;
    constructor() { }
    static get instance() {
        if (!UserService.#instance)
            UserService.#instance = new UserService();
        return UserService.#instance;
    }
    get isAdmin() {
        return this.getCurrentUser?.role === 'admin';
    }
    get getCurrentUser() {
        const user = localStorage.getItem('user');
        return user ? JSON.parse(user) : null;
    }
    logout() {
        localStorage.removeItem('user');
    }
}
