import css from './admin-nav.component.scss';
import { ADMIN_NAV_LINKS } from './admin-nav.conf';
import { convertElementToRouterLink } from '@Helpers/router.helper';
import { UserService } from '@Services/user.service';
export class AdminNavComp extends HTMLElement {
    userService = UserService.instance;
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkNav(), this.mkControls());
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkNav() {
        const nav = document.createElement('nav');
        nav.classList.add(css.nav);
        nav.append(this.mkNavList());
        return nav;
    }
    mkNavList() {
        const list = document.createElement('ul');
        ADMIN_NAV_LINKS.forEach(link => list.append(this.mkNavListItem(link)));
        return list;
    }
    mkNavListItem(navLink) {
        const listItem = document.createElement('li');
        listItem.classList.add(css.navListItem);
        listItem.append(this.mkNavLink(navLink));
        return listItem;
    }
    mkNavLink(navLink) {
        const link = document.createElement('a');
        link.classList.add(css.navLink);
        link.textContent = navLink.name;
        link.href = navLink.path;
        convertElementToRouterLink(link);
        return link;
    }
    mkControls() {
        const controls = document.createElement('div');
        controls.classList.add(css.controls);
        controls.append(this.mkLogoutBtn(), this.mkBackLink());
        return controls;
    }
    mkLogoutBtn() {
        const btn = document.createElement('button');
        btn.classList.add(css.logout);
        btn.textContent = 'Разлогиниться';
        btn.addEventListener('click', () => this.logout());
        return btn;
    }
    mkBackLink() {
        const link = document.createElement('a');
        link.classList.add(css.navLink);
        link.textContent = 'Выйти из админки';
        link.href = '/';
        return link;
    }
    logout() {
        this.userService.logout();
        location.reload();
    }
}
