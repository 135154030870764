import { ORIGIN, PROTOCOL } from '@Constants/http.constants';
export class AboutHttp {
    static #instance;
    constructor() { }
    static get instance() {
        if (!AboutHttp.#instance)
            AboutHttp.#instance = new AboutHttp();
        return AboutHttp.#instance;
    }
    async getData() {
        try {
            const res = await fetch(`${PROTOCOL}${ORIGIN}/api/about/getAll`, {
                method: 'GET'
            });
            if (!res.ok) {
                const parsedResponse = await res.json();
                throw new Error(parsedResponse.msg);
            }
            return res.json();
        }
        catch (err) {
            console.log('[HTTP] error during getData', err);
            return null;
        }
    }
}
