import css from './player-header.component.scss';
import { customDateFieldGuard, customNonNullableFieldGuard } from '@Types/player.interface';
import { getDDMonthYYYY } from '@Helpers/date.helper';
import { Subject } from 'rxjs';
export class PlayerHeaderComp extends HTMLElement {
    subscriptions = [];
    player;
    update = new Subject();
    teamInfoValueRef;
    teamLogoImageRef;
    bgImageRef;
    set playerSetter(playerData) {
        this.player = playerData;
        this.update.next();
    }
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
    }
    disconnectedCallback() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkPreview(), this.mkInfo());
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkPreview() {
        const playerPreview = document.createElement('div');
        playerPreview.classList.add(css.preview);
        const updateSubscription = this.update.subscribe(() => {
            this.toggleTeamLogoImage();
            this.toggleBgImage();
        });
        this.subscriptions.push(updateSubscription);
        playerPreview.append(this.mkBg(), this.mkTeamLogo(), this.mkAvatar(), this.mkName());
        return playerPreview;
    }
    toggleTeamLogoImage() {
        this.bgImageRef.src = this.player.team?.logo
            ? `/teams-assets/${this.player.team.logo}`
            : '';
    }
    toggleBgImage() {
        this.teamLogoImageRef.src = this.player.team?.logo
            ? `/teams-assets/${this.player.team.logo}`
            : '';
    }
    mkTeamLogo() {
        const cont = document.createElement('div');
        cont.classList.add(css.teamLogoCont);
        const teamLogo = document.createElement('img');
        teamLogo.src = `/teams-assets/${this.player.team.logo}`;
        teamLogo.classList.add(css.teamLogo);
        this.teamLogoImageRef = teamLogo;
        cont.append(teamLogo);
        return cont;
    }
    mkAvatar() {
        const cont = document.createElement('div');
        cont.classList.add(css.avatarCont);
        if (this.player.avatar) {
            const avatar = document.createElement('img');
            avatar.src = `/players-assets/${this.player.avatar}`;
            avatar.classList.add(css.avatar);
            cont.append(avatar);
            avatar.addEventListener('error', () => avatar.src = '');
        }
        return cont;
    }
    mkName() {
        const cont = document.createElement('div');
        cont.classList.add(css.nameCont);
        const name = document.createElement('p');
        name.classList.add(css.name);
        name.textContent = this.player.surname + '\n' + this.player.name;
        cont.append(name);
        return cont;
    }
    mkBg() {
        const teamLogo = document.createElement('img');
        teamLogo.src = `/teams-assets/${this.player.team.logo}`;
        teamLogo.classList.add(css.bg);
        this.bgImageRef = teamLogo;
        return teamLogo;
    }
    mkInfo() {
        const cont = document.createElement('div');
        cont.classList.add(css.infoCont);
        const updateSubscription = this.update.subscribe(() => {
            this.teamInfoValueRef.textContent = this.player.team.name;
        });
        this.subscriptions.push(updateSubscription);
        cont.append(...this.mkInfoItems());
        return cont;
    }
    mkInfoItems() {
        const infoItems = [this.mkTeamInfo(this.player.team.name)];
        if (this.player?.position)
            infoItems.push(this.mkPositionInfo(this.player.position));
        if (this.player?.height)
            infoItems.push(this.mkHeightInfo(this.player.height));
        if (this.player?.weight)
            infoItems.push(this.mkWeightInfo(this.player.weight));
        if (this.player?.birthday)
            infoItems.push(this.mkBirthdayInfo(this.player.birthday));
        this.player.customFields.forEach(field => {
            if (customNonNullableFieldGuard(field))
                infoItems.push(this.mkCustomFieldInfo(field));
        });
        return infoItems;
    }
    mkCustomFieldInfo(customField) {
        const cont = document.createElement('div');
        cont.classList.add(css.info);
        const infoValue = document.createElement('p');
        infoValue.classList.add(css.infoValue);
        infoValue.textContent = customDateFieldGuard(customField)
            ? getDDMonthYYYY(customField.value)
            : String(customField.value);
        cont.append(this.mkTitle(`${customField.name}`), infoValue);
        return cont;
    }
    mkTeamInfo(value) {
        const cont = document.createElement('div');
        cont.classList.add(css.info);
        cont.append(this.mkTitle('Команда'), this.mkInfoValue(value));
        this.teamInfoValueRef = cont.querySelector(`.${css.infoValue}`);
        return cont;
    }
    mkPositionInfo(value) {
        const cont = document.createElement('div');
        cont.classList.add(css.info);
        const translate = value === 'defender'
            ? 'защитник'
            : value === 'goalkeeper'
                ? 'вратарь'
                : 'нападающий';
        cont.append(this.mkTitle('Позиция'), this.mkInfoValue(translate));
        return cont;
    }
    mkBirthdayInfo(value) {
        const cont = document.createElement('div');
        cont.classList.add(css.info);
        cont.append(this.mkTitle('Дата рождения'), this.mkInfoValue(getDDMonthYYYY(value)));
        return cont;
    }
    mkHeightInfo(value) {
        const cont = document.createElement('div');
        cont.classList.add(css.info);
        cont.append(this.mkTitle('Рост'), this.mkInfoValue(String(value)));
        return cont;
    }
    mkWeightInfo(value) {
        const cont = document.createElement('div');
        cont.classList.add(css.info);
        cont.append(this.mkTitle('Вес'), this.mkInfoValue(String(value)));
        return cont;
    }
    mkTitle(title) {
        const el = document.createElement('p');
        el.classList.add(css.infoTitle);
        el.textContent = title;
        return el;
    }
    mkInfoValue(value) {
        const infoValue = document.createElement('p');
        infoValue.classList.add(css.infoValue);
        infoValue.textContent = value;
        return infoValue;
    }
}
