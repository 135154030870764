import { DefaultSection } from '@Components/section/section.component';
import css from './leaderboard-section.component.scss';
export class LeaderboardSectionComp extends DefaultSection {
    leaderboards;
    set dataSetter(leaderboards) {
        this.leaderboards = leaderboards;
    }
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkTitle('Сезон 2024-2025'), //TODO изменить заглушку
        this.mkHeaderUnderline(), this.mkSlider());
    }
    mkSlider() {
        const slider = document.createElement('app-slider');
        slider.classList.add(css.leaderboardsSection);
        slider.sliderSetter = this.leaderboards.map(leaderboard => this.mkLeaderboardPreview(leaderboard));
        return slider;
    }
    mkLeaderboardPreview(data) {
        const leaderboard = document.createElement('app-leaderboard');
        leaderboard.classList.add(css.leaderboard);
        leaderboard.dataSetter = data;
        console.log(data.players);
        return leaderboard;
    }
}
