import { MonthTranslates } from '../enums/date.enum';
export const getDDMonthYYYY = (ISOString) => {
    const date = new Date(ISOString);
    return `${date.getDate()} ${MonthTranslates[date.getMonth()]} ${date.getFullYear()}`;
};
export const getDDMonthhhmm = (ISOString) => {
    const date = new Date(ISOString);
    return `${date.getDate()} ${MonthTranslates[date.getMonth()]} ${date.getHours()}:${String(date.getMinutes()).padStart(2, '0')}`;
};
export const getDDMMYYYY = (ISOString) => {
    const date = new Date(ISOString);
    return `${date.getDate()}.${date.getMonth()}.${date.getFullYear()}`;
};
