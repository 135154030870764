// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.match-general-component__host{display:block}.match-general-component__paddingContent{display:flex;padding:2rem 5vw;flex-direction:column;gap:1rem;min-height:95vh}`, "",{"version":3,"sources":["webpack://./match-page/match-general/match-general.component.scss"],"names":[],"mappings":"AAAA,+BACI,aAAA,CAGJ,yCACI,YAAA,CACA,gBAAA,CACA,qBAAA,CACA,QAAA,CACA,eAAA","sourcesContent":[".host {\n    display: block;\n}\n\n.paddingContent {\n    display: flex;\n    padding: 2rem 5vw;\n    flex-direction: column;\n    gap: 1rem;\n    min-height: 95vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"host": `match-general-component__host`,
	"paddingContent": `match-general-component__paddingContent`
};
module.exports = ___CSS_LOADER_EXPORT___;
