import css from './playoff-section.component.scss';
import { DefaultSection } from '@Components/section/section.component';
import { getAPEl, getCustomSequenceEl, getGPEl } from '@Helpers/number-sequences.helper';
export class PlayoffSectionComp extends DefaultSection {
    sectionTitle;
    stages;
    hasThirdPlaceStage = false;
    set sectionTitleSetter(title) {
        this.sectionTitle = title;
    }
    set stagesSetter(stages) {
        if (stages.length > 1)
            this.hasThirdPlaceStage = stages[stages.length - 1].length === stages[stages.length - 2].length;
        this.stages = stages;
    }
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkTitle(this.sectionTitle), this.mkHeaderUnderline(), this.mkGrid());
    }
    assignHostStyles() {
        super.assignHostStyles();
        this.classList.add(css.host);
    }
    mkGrid() {
        const grid = document.createElement('div');
        grid.classList.add(css.grid);
        grid.style.gridTemplateColumns = this.defineColumnsCount();
        grid.style.gridTemplateRows = this.defineRowsCount();
        this.stages.forEach((stage, idx) => {
            grid.append(...this.mkStageConfrontations(stage, idx + 1), ...this.mkConnectionLines(stage, idx + 1));
        });
        return grid;
    }
    defineColumnsCount() {
        return `repeat(${this.stages.length * 3 + this.stages.length - 1}, 70px)`;
    }
    defineRowsCount() {
        const firstStageMatchesCount = this.stages[0].length;
        return `repeat(${firstStageMatchesCount === 1
            ? this.hasThirdPlaceStage
                ? 7
                : firstStageMatchesCount * 3 + firstStageMatchesCount - 1
            : firstStageMatchesCount * 3 + firstStageMatchesCount - 1}, 40px)`;
    }
    mkStageConfrontations(stage, stageOrder) {
        const confrontations = [];
        for (let i = 0; i < stage.length; i++)
            confrontations.push(this.mkConfrontationCont(stage[i], stageOrder, i + 1));
        return confrontations;
    }
    mkConfrontationCont(confrontationData, stageOrder, confrontationOrder) {
        const cont = document.createElement('div');
        cont.classList.add(css.confrontation);
        cont.style.gridArea = stageOrder === this.stages.length
            ? this.hasThirdPlaceStage
                ? this.defineThirdPlaceConfrontationPosition(stageOrder - 1)
                : this.defineConfrontationPosition(stageOrder, confrontationOrder)
            : this.defineConfrontationPosition(stageOrder, confrontationOrder);
        cont.append(this.mkConfrontationStage(stageOrder), this.mkConfrontation(confrontationData));
        return cont;
    }
    mkConfrontationStage(stageOrder) {
        const order = document.createElement('p');
        order.classList.add(css.confrontationStage);
        order.textContent = this.getConfrontationStage(stageOrder);
        return order;
    }
    getConfrontationStage(stageOrder) {
        if (this.hasThirdPlaceStage && stageOrder === this.stages.length)
            return 'Матч за 3-е место';
        if (this.hasThirdPlaceStage && stageOrder === this.stages.length - 1)
            return 'Финал';
        if (stageOrder === this.stages.length)
            return 'Финал';
        return `1/${this.stages[stageOrder - 1].length}`;
    }
    mkConfrontation(confrontationData) {
        const confrontation = document.createElement('app-confrontation');
        confrontation.confrontationSetter = confrontationData;
        return confrontation;
    }
    defineThirdPlaceConfrontationPosition(stageOrder) {
        const rowStart = getAPEl(1, getCustomSequenceEl(stageOrder, 1, (a) => a + 1), getGPEl(stageOrder, 4, 2)) + 4;
        const rowEnd = rowStart + 3;
        const columnStart = stageOrder + ((stageOrder - 1) * 3);
        const columnEnd = columnStart + 3;
        return `${rowStart} / ${columnStart} / ${rowEnd} / ${columnEnd}`;
    }
    defineConfrontationPosition(stageOrder, confrontationOrder) {
        const rowStart = getAPEl(confrontationOrder, getCustomSequenceEl(stageOrder, 1, (a) => a + 1), getGPEl(stageOrder, 4, 2));
        const rowEnd = rowStart + 3;
        const columnStart = stageOrder + ((stageOrder - 1) * 3);
        const columnEnd = columnStart + 3;
        return `${rowStart} / ${columnStart} / ${rowEnd} / ${columnEnd}`;
    }
    mkConnectionLines(stage, stageOrder) {
        const lines = [];
        if (stage.length === 1)
            return [];
        for (let i = 0; i < stage.length / 2; i++)
            lines.push(this.mkConnectionLine(stageOrder, i + 1));
        return lines;
    }
    mkConnectionLine(stageOrder, confrontationOrder) {
        const lineCont = document.createElement('div');
        lineCont.classList.add(css.lineCont);
        lineCont.style.gridArea = this.defineConnectionLinePosition(stageOrder, confrontationOrder);
        lineCont.append(this.mkConnectionLineStart(), this.mkConnectionLineEnd());
        return lineCont;
    }
    mkConnectionLineStart() {
        const line = document.createElement('div');
        line.classList.add(css.connectionLineStart);
        return line;
    }
    mkConnectionLineEnd() {
        const line = document.createElement('div');
        line.classList.add(css.connectionLineEnd);
        return line;
    }
    defineConnectionLinePosition(stageOrder, confrontationOrder) {
        const rowStart = getAPEl(confrontationOrder, getGPEl(stageOrder, 2, 2), getGPEl(stageOrder, 8, 2));
        const rowEnd = rowStart + getGPEl(stageOrder, 4, 2) + 1;
        const columnStart = getAPEl(stageOrder, 4, 4);
        const columnEnd = columnStart + 1;
        return `${rowStart} / ${columnStart} / ${rowEnd} / ${columnEnd}`;
    }
}
