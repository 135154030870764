import css from './match-chronology.component.scss';
export class MatchChronologyComp extends HTMLElement {
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkPaddingContent());
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkPaddingContent() {
        const paddingContent = document.createElement('div');
        paddingContent.classList.add(css.paddingContent);
        paddingContent.append('MATCH CHRONOLOGY COMPONENT');
        return paddingContent;
    }
}
