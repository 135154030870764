// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-page-component__host{display:block}.main-page-component__paddingContent{display:flex;padding:2rem 5vw;flex-direction:column;gap:1rem;min-height:100vh}.main-page-component__newsFeedSection{width:80%}`, "",{"version":3,"sources":["webpack://./main-page/main-page.component.scss"],"names":[],"mappings":"AAAA,2BACI,aAAA,CAGJ,qCACI,YAAA,CACA,gBAAA,CACA,qBAAA,CACA,QAAA,CACA,gBAAA,CAGJ,sCACI,SAAA","sourcesContent":[".host {\n    display: block;\n}\n\n.paddingContent {\n    display: flex;\n    padding: 2rem 5vw;\n    flex-direction: column;\n    gap: 1rem;\n    min-height: 100vh;\n}\n\n.newsFeedSection {\n    width: 80%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"host": `main-page-component__host`,
	"paddingContent": `main-page-component__paddingContent`,
	"newsFeedSection": `main-page-component__newsFeedSection`
};
module.exports = ___CSS_LOADER_EXPORT___;
