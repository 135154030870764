// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avatar-editor-component__editor{background-color:#fff;box-shadow:0 0 10px #000;border-radius:8px;width:300px;padding:.75rem}.avatar-editor-component__avatarInput{margin-bottom:1rem}.avatar-editor-component__preview{width:100%;margin-bottom:1rem}.avatar-editor-component__submitBtn{padding:.25rem .75rem;outline:none}`, "",{"version":3,"sources":["webpack://./shared/components/avatar-editor/avatar-editor.component.scss"],"names":[],"mappings":"AAAA,iCACI,qBAAA,CACA,wBAAA,CACA,iBAAA,CACA,WAAA,CACA,cAAA,CAGJ,sCACI,kBAAA,CAGJ,kCACI,UAAA,CACA,kBAAA,CAGJ,oCACI,qBAAA,CACA,YAAA","sourcesContent":[".editor {\n    background-color: #fff;\n    box-shadow: 0 0 10px black;\n    border-radius: 8px;\n    width: 300px;\n    padding: 0.75rem;\n}\n\n.avatarInput {\n    margin-bottom: 1rem;\n}\n\n.preview {\n    width: 100%;\n    margin-bottom: 1rem;\n}\n\n.submitBtn {\n    padding: 0.25rem 0.75rem;\n    outline: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"editor": `avatar-editor-component__editor`,
	"avatarInput": `avatar-editor-component__avatarInput`,
	"preview": `avatar-editor-component__preview`,
	"submitBtn": `avatar-editor-component__submitBtn`
};
module.exports = ___CSS_LOADER_EXPORT___;
