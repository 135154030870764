import css from './main-sponsor.component.scss';
import { Subject } from 'rxjs';
import { SponsorsHttp } from '@Services/http/sponsors.http';
import shlDefaultWhiteLogo from '@Assets/logos/shl-default-logo-white.png';
export class MainSponsorComp extends HTMLElement {
    sponsor = new Subject();
    sponsorsHttp = SponsorsHttp.instance;
    subscriptions = [];
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
        this.reqSponsor();
    }
    disconnectedCallback() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkLoader());
        const sponsorSubscription = this.sponsor.subscribe(sponsor => {
            if (!sponsor) {
                this.replaceChildren(this.mkLogo(shlDefaultWhiteLogo));
                return;
            }
            this.replaceChildren(this.mkLinkLogo(sponsor));
        });
        this.subscriptions.push(sponsorSubscription);
    }
    mkLoader() {
        const loader = document.createElement('app-loader');
        loader.colorSetter = '#fff';
        return loader;
    }
    mkLinkLogo(sponsor) {
        const link = document.createElement('a');
        link.classList.add(css.link);
        link.href = sponsor.link;
        link.target = '_blank';
        link.append(this.mkLogo(`/sponsors-assets/${sponsor.logo}`));
        return link;
    }
    mkLogo(logoPath) {
        const logo = document.createElement('img');
        logo.classList.add(css.logo);
        logo.src = logoPath;
        return logo;
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    reqSponsor() {
        this.sponsorsHttp.getMain()
            .then(sponsor => this.sponsor.next(sponsor))
            .catch(() => this.sponsor.next(null));
    }
}
