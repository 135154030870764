import './styles.global.scss';
import { rootRouter } from './routing/routing';
import { Layout } from '@Services/layout.service';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import loadMainModule from './main-page/main-page.module';
import loadHeaderModule from './header/header.module';
import loadTournamentsPageModule from './tournaments-page/tournaments-page.module';
import loadCalendarPageModule from './calendar-page/calendar-page.module';
import loadStatisticsPageModule from './statistics-page/statistics-page.module';
import loadNewsPageModule from './news-page/news-page.module';
import loadDocumentsPageModule from './documents-page/documents-page.module';
import loadContactsPageModule from './contacts-page/contacts-page.module';
import loadAboutPageModule from './about-page/about-page.module';
import loadLoginPageModule from './login-page/login-page.module';
import loadPlayerPageModule from './player-page/player-page.module';
import loadAdminModule from './admin-page/admin-page.module';
import loadMatchModule from './match-page/match-page.module';
import loadNewsDetailsPageModule from './news-details-page/news-details-page.module';
import loadTeamPageModule from './team-page/team-page.module';
const initRouting = () => {
    document.addEventListener('DOMContentLoaded', () => {
        document.body.addEventListener('click', e => {
            const target = e.target;
            if (target.matches('[data-router-link]')) {
                e.preventDefault();
                history.pushState(null, '', e.target.href);
                rootRouter.generateOutlet();
                window.scrollTo(0, 0);
            }
        });
        rootRouter.generateOutlet();
    });
};
const listenHistoryChange = () => {
    window.addEventListener('popstate', () => {
        rootRouter.generateOutlet();
    });
};
const initLayout = () => {
    const layout = Layout.instance;
    layout.determineDeviceType();
};
const initGrid = () => {
    ModuleRegistry.registerModules([ClientSideRowModelModule]);
};
loadHeaderModule();
loadMainModule();
loadTournamentsPageModule();
loadCalendarPageModule();
loadStatisticsPageModule();
loadNewsPageModule();
loadDocumentsPageModule();
loadContactsPageModule();
loadAboutPageModule();
loadLoginPageModule();
loadAdminModule();
loadPlayerPageModule();
loadMatchModule();
loadNewsDetailsPageModule();
loadTeamPageModule();
initLayout();
initRouting();
initGrid();
listenHistoryChange();
