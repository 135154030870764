import css from './match-general.component.scss';
export class MatchGeneralComp extends HTMLElement {
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkPaddingContent());
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkPaddingContent() {
        const paddingContent = document.createElement('div');
        paddingContent.classList.add(css.paddingContent);
        paddingContent.append(this.mkMatchesComparisonSection());
        return paddingContent;
    }
    mkMatchesComparisonSection() {
        const section = document.createElement('app-matches-comparison-section');
        return section;
    }
}
