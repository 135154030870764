import css from './tournament-creator.component.scss';
export class TournamentCreatorComp extends HTMLElement {
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
        this.setHostFocus();
        this.escBtnListener();
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkCloseBtn(), this.mkTournamentSettings());
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkCloseBtn() {
        const btn = document.createElement('button');
        btn.classList.add(css.closeBtn);
        btn.append(this.mkCloseIcon());
        btn.addEventListener('click', () => this.closeCreator());
        return btn;
    }
    mkCloseIcon() {
        const icon = document.createElement('img');
        icon.classList.add(css.closeIcon);
        return icon;
    }
    closeCreator() {
        this.dispatchEvent(new CustomEvent('closed'));
        this.remove();
    }
    mkTournamentSettings() {
        const settings = document.createElement('app-tournament-settings');
        settings.tournamentTypeSetter = 'regular';
        return settings;
    }
    setHostFocus() {
        this.tabIndex = 0;
        this.focus();
    }
    escBtnListener() {
        this.addEventListener('keyup', (e) => {
            if (e.code === 'Escape')
                this.closeCreator();
        });
    }
}
