import css from './team-page-header.component.scss';
export class TeamPageHeaderComp extends HTMLElement {
    team;
    set teamSetter(team) {
        this.team = team;
    }
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkMainInfo(), this.mkBaseIndicators(), this.mkBgLogo());
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkMainInfo() {
        const cont = document.createElement('div');
        cont.classList.add(css.mainInfo);
        cont.append(this.mkTeamLogo(), this.mkTeamNameAndCity());
        return cont;
    }
    mkTeamLogo() {
        const logo = document.createElement('img');
        logo.classList.add(css.logo);
        logo.src = `/teams-assets/${this.team.logo}`;
        return logo;
    }
    mkTeamNameAndCity() {
        const cont = document.createElement('div');
        cont.classList.add(css.nameAndCityCont);
        cont.append(this.mkTeamName(), this.mkTeamCity());
        return cont;
    }
    mkTeamName() {
        const name = document.createElement('p');
        name.classList.add(css.name);
        name.textContent = this.team.name;
        return name;
    }
    mkTeamCity() {
        const city = document.createElement('p');
        city.classList.add(css.city);
        city.textContent = `${this.team.country} | ${this.team.city}`;
        return city;
    }
    mkBaseIndicators() {
        const indicators = document.createElement('div');
        indicators.classList.add(css.baseIndicators);
        indicators.append(this.mkBaseIndicator('матчей', this.team.matchesCount));
        return indicators;
    }
    mkBaseIndicator(title, value) {
        const cont = document.createElement('div');
        cont.classList.add(css.baseIndicator);
        cont.append(this.mkBaseIndicatorTitle(title), this.mkBaseIndicatorValue(value));
        return cont;
    }
    mkBaseIndicatorTitle(text) {
        const title = document.createElement('p');
        title.classList.add(css.baseIndicatorTitle);
        title.textContent = text;
        return title;
    }
    mkBaseIndicatorValue(v) {
        const value = document.createElement('p');
        value.classList.add(css.baseIndicatorValue);
        value.textContent = String(v);
        return value;
    }
    mkSeparator() {
        const separator = document.createElement('div');
        separator.classList.add(css.separator);
        return separator;
    }
    mkBgLogo() {
        const logo = document.createElement('img');
        logo.classList.add(css.bgLogo);
        logo.src = `/teams-assets/${this.team.logo}`;
        return logo;
    }
}
