import css from './login-link.component.scss';
import { convertElementToRouterLink } from '@Helpers/router.helper';
import profileIcon from '@Assets/icons/profile-icon.svg';
import { UserService } from '@Services/user.service';
export class LoginLinkComp extends HTMLElement {
    userService = UserService.instance;
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkLoginLink());
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkLoginLink() {
        const loginLink = document.createElement('a');
        loginLink.classList.add(css.loginLink);
        convertElementToRouterLink(loginLink);
        loginLink.href = '/login';
        loginLink.addEventListener('click', () => this.dispatchEvent(new CustomEvent('navLinkClicked')));
        loginLink.append(this.mkProfileIcon(), this.getLinkText());
        return loginLink;
    }
    mkProfileIcon() {
        const icon = document.createElement('img');
        icon.src = profileIcon;
        icon.alt = 'Иконка профиля';
        return icon;
    }
    getLinkText() {
        return this.userService.getCurrentUser?.login ?? 'Вход';
    }
}
