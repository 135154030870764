import { DefaultSection } from '@Components/section/section.component';
import css from './news-details-section.component.scss';
import { getDDMonthhhmm } from '@Helpers/date.helper';
export class NewsDetailsSectionComp extends DefaultSection {
    news;
    set newsSetter(news) {
        this.news = news;
    }
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
    }
    assignHostStyles() {
        super.assignHostStyles();
        this.classList.add(css.host);
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkTitle(this.news.title), this.mkLeadAndPostedDate(), this.mkHeaderUnderline(), this.mkPhoto(), this.mkContent());
    }
    mkLeadAndPostedDate() {
        const cont = document.createElement('div');
        cont.classList.add(css.leadAndPostedDateCont);
        cont.append(this.mkLead(), this.mkPostedDate());
        return cont;
    }
    mkLead() {
        const lead = document.createElement('p');
        lead.classList.add(css.lead);
        lead.textContent = this.news.lead;
        return lead;
    }
    mkPostedDate() {
        const postedDate = document.createElement('p');
        postedDate.classList.add(css.postedDate);
        postedDate.textContent = getDDMonthhhmm(this.news.postedDate);
        return postedDate;
    }
    mkPhoto() {
        const photo = document.createElement('img');
        photo.classList.add(css.photo);
        photo.src = `/news-assets/${this.news.img}`;
        return photo;
    }
    mkContent() {
        const content = document.createElement('app-editorjs');
        content.dataSetter = JSON.parse(this.news.content);
        content.readonlyModeSetter = true;
        return content;
    }
}
