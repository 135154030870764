// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contacts-page-component__host{display:block}.contacts-page-component__paddingContent{display:flex;padding:2rem 5vw;flex-direction:column;gap:1rem;min-height:95vh}.contacts-page-component__contactsSections{display:flex;flex-direction:column;gap:1rem}`, "",{"version":3,"sources":["webpack://./contacts-page/contacts-page.component.scss"],"names":[],"mappings":"AAAA,+BACI,aAAA,CAGJ,yCACI,YAAA,CACA,gBAAA,CACA,qBAAA,CACA,QAAA,CACA,eAAA,CAGJ,2CACI,YAAA,CACA,qBAAA,CACA,QAAA","sourcesContent":[".host {\n    display: block;\n}\n\n.paddingContent {\n    display: flex;\n    padding: 2rem 5vw;\n    flex-direction: column;\n    gap: 1rem;\n    min-height: 95vh;\n}\n\n.contactsSections {\n    display: flex;\n    flex-direction: column;\n    gap: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"host": `contacts-page-component__host`,
	"paddingContent": `contacts-page-component__paddingContent`,
	"contactsSections": `contacts-page-component__contactsSections`
};
module.exports = ___CSS_LOADER_EXPORT___;
