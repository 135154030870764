import css from './team.component.scss';
import { convertElementToRouterLink } from '@Helpers/router.helper';
export class TeamCellRendererComp {
    gui;
    constructor() { }
    init(params) {
        if (!params.value)
            return;
        this.gui = this.mkTeam(params.value.teamName, params.value.teamId);
    }
    getGui() {
        return this.gui;
    }
    refresh(_) {
        return false;
    }
    mkTeam(teamName, teamId) {
        const link = document.createElement('a');
        link.classList.add(css.teamLink);
        link.href = `/team/${teamId}/stats`;
        link.textContent = teamName;
        convertElementToRouterLink(link);
        return link;
    }
}
