import css from './news-details-page.component.scss';
import { NewsHttp } from '@Services/http/news.http';
import { Subject } from 'rxjs';
export class NewsDetailsPageComp extends HTMLElement {
    news = new Subject();
    newsHttp = NewsHttp.instance;
    subscriptions = [];
    get getIdFromLocation() {
        return location.pathname.split('/').at(-1);
    }
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
        this.reqNews();
    }
    disconnectedCallback() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkPaddingContent(), this.mkFooter());
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkPaddingContent() {
        const paddingContent = document.createElement('div');
        paddingContent.classList.add(css.paddingContent);
        paddingContent.append(this.mkLoader());
        const newsSubscription = this.news.subscribe(news => {
            paddingContent.replaceChildren(this.mkNewsDetailsSection(news), this.mkMainSponsor());
        });
        this.subscriptions.push(newsSubscription);
        return paddingContent;
    }
    mkLoader() {
        const cont = document.createElement('div');
        cont.classList.add(css.loaderCont);
        const loader = document.createElement('app-loader');
        cont.append(loader);
        return cont;
    }
    mkFooter() {
        const footer = document.createElement('app-footer');
        return footer;
    }
    reqNews() {
        this.newsHttp.getById(this.getIdFromLocation)
            .then(news => {
            this.news.next(news);
        })
            .catch();
    }
    mkNewsDetailsSection(news) {
        const section = document.createElement('app-news-details-section');
        section.classList.add(css.newsDetailsSection);
        section.newsSetter = news;
        return section;
    }
    mkMainSponsor() {
        const sponsor = document.createElement('app-main-sponsor');
        sponsor.classList.add(css.sponsor);
        return sponsor;
    }
}
