// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.players-table-component__host{display:block;height:100%}.players-table-component__table{height:100%}`, "",{"version":3,"sources":["webpack://./admin-page/admin-players/players-table/players-table.component.scss"],"names":[],"mappings":"AAAA,+BACI,aAAA,CACA,WAAA,CAGJ,gCACI,WAAA","sourcesContent":[".host {\n    display: block;\n    height: 100%;\n}\n\n.table {\n    height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"host": `players-table-component__host`,
	"table": `players-table-component__table`
};
module.exports = ___CSS_LOADER_EXPORT___;
