import { TeamsHttp } from '@Services/http/teams.http';
import css from './team-page-lineup.component.scss';
import { Subject } from 'rxjs';
export class TeamPageLineupComp extends HTMLElement {
    teamsHttp = TeamsHttp.instance;
    lineup = new Subject();
    subscriptions = [];
    get teamIdParam() {
        return location.pathname.replace('team', '').split('/').filter(path => !!path)[0];
    }
    constructor() {
        super();
    }
    connectedCallback() {
        this.render();
        this.reqTeamInfo();
    }
    disconnectedCallback() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
    render() {
        this.assignHostStyles();
        this.append(this.mkPaddingContent(), this.mkFooter());
    }
    assignHostStyles() {
        this.classList.add(css.host);
    }
    mkPaddingContent() {
        const paddingContent = document.createElement('div');
        paddingContent.classList.add(css.paddingContent);
        paddingContent.append(this.mkLoader());
        const lineupSubscription = this.lineup.subscribe(players => {
            paddingContent.replaceChildren(this.mkLineup(players));
        });
        this.subscriptions.push(lineupSubscription);
        return paddingContent;
    }
    mkLineup(players) {
        const lineup = document.createElement('div');
        lineup.classList.add(css.lineup);
        players.forEach(player => lineup.append(this.mkPlayerCard(player)));
        return lineup;
    }
    mkPlayerCard(player) {
        const card = document.createElement('app-player-card');
        card.playerSetter = player;
        return card;
    }
    mkLoader() {
        const cont = document.createElement('div');
        cont.classList.add(css.loaderCont);
        const loader = document.createElement('app-loader');
        cont.append(loader);
        return cont;
    }
    reqTeamInfo() {
        this.teamsHttp.getTeamById(this.teamIdParam)
            .then(team => this.lineup.next(team.players))
            .catch();
    }
    mkFooter() {
        const footer = document.createElement('app-footer');
        return footer;
    }
}
