import css from './avatar-input.component.scss';
import { getMiniAvatarPath } from '@Helpers/images.helper';
export class AvatarCellRendererComp {
    gui;
    constructor() { }
    init(params) {
        if (!params.value)
            return;
        const cont = this.mkCont();
        cont.append(this.mkAvatar(params.value));
        this.gui = cont;
    }
    getGui() {
        return this.gui;
    }
    refresh(_) {
        return false;
    }
    mkCont() {
        const cont = document.createElement('span');
        cont.classList.add(css.avatarCont);
        return cont;
    }
    mkAvatar(avatarPath) {
        const avatar = document.createElement('img');
        avatar.classList.add(css.avatar);
        avatar.src = getMiniAvatarPath(avatarPath) || '';
        avatar.loading = 'lazy';
        return avatar;
    }
}
